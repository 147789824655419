import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {Base} from "../../../../../../../common/interfaces/base.interfaces";

import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {DragulaService} from "ng2-dragula";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {CourierTransactionFormComponent} from "../../../courier-transaction";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Warehouse} from "../../../../../../../common/interfaces/warehouse.interface";
import {takeUntil} from "rxjs/operators";
import {Order} from "../../../../../../../common/interfaces/order.interface";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {AlertComponent} from "../../../../../../../common/components/alert/alert.component";

@Component({
    selector: "section-warehouse-order-wizard-shipment-courier-transactions",
    templateUrl: "shipment-courier-transactions.component.html",
    styleUrls: [
        "shipment-courier-transactions.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseOrderWizardCourierTransactionsToShipmentComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    public order: Order.IOrderData;

    public shipment: Warehouse.IShipment;

    public freeTracks: any[] = [];

    public warehouseOrderId: number;

    public isViewOnly: boolean = false;

    /**
     * Randomize bag name to avoid duplication when we have
     * two ore more component instances
     */
    public dragulaBagName: string;

    @ViewChild(AlertComponent, {static: true})
    public alertRef: AlertComponent;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private apiService: ApiService,
        private toastService: ToastService,
        private dragulaService: DragulaService,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);

        this.dragulaBagName = "warehouse-parcels-bag-" + HelpersService.randomString();
        if (!dragulaService.find(this.dragulaBagName)) {
            dragulaService.createGroup(this.dragulaBagName, {
                copy: true,
                copyItem: (item: any): any => ({...item})
            });
        }
        dragulaService.drop(this.dragulaBagName).pipe(takeUntil(this.destroy$))
            .subscribe((value: any): void => {
                this.onDrop(value);
            });
    }

    /**
     * Handle drop event
     * @returns {Promise<any>}
     */
    private async onDrop(value: any): Promise<any> {
        const {name, el, target}: any = value;

        if (name !== this.dragulaBagName) {
            return;
        }

        let transactionId: string;
        if (el) {
            el.classList.forEach((className: string): void => {
                if (/id-[0-9]+/.test(className)) {
                    transactionId = className.replace("id-", "");
                }
            });
        }

        let targetId: number = null;
        if (target) {
            target.classList.forEach((className: string): void => {
                if (/id-[0-9]+/.test(className)) {
                    targetId = Number(className.replace("id-", ""));
                }
            });
        }

        if (target !== null && transactionId) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["courier", "transactions", transactionId],
                {shipment_id: targetId});

            this.getShipment();
            this.getFreeCourierTransactions();

            this.changeDetectorRef.markForCheck();

            this.spinnerService.hide();
            this.toastService.show(response.message, response.type as string);
        }
    }

    private async getOrder(id: number): Promise<any> {
        if (!id) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", "" + id], null, {
                warehouse_order_id: this.warehouseOrderId,
                relations: [
                    "CourierService",
                    "ServiceLevel.courierService",
                ],
            });
        if (data) {
            this.order = data;
        }
        this.spinnerService.hide();
    }

    /**
     * Get courier transactions in shipment
     * @returns {Promise<any>}
     */
    private async getShipment(id: number = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + (id || this.shipment.id)], {}, {
                relations: [
                    "SiblingShipments.ToAddress",
                    "FromAddress.contacts.customer",
                    "FromAddress.customer",
                    "ToAddress.contacts.customer",
                    "ToAddress.customer",
                    "CourierTransactions.CourierTransactionCheckpoints",
                    "CourierTransactions.Courier",
                    "CourierTransactions.WarehouseTransactions.PartMaster",
                    "CourierTransactions.WarehouseTransactions.InventoryConversion",
                    "Warehouse",
                    "Partner",
                    "Order.ServiceLevel"
                ]
            });

        if (data) {
            this.shipment = data;

            this.result.next({
                action: "result",
                value: {shipmentId: this.shipment.id}
            });
            this.checkShipment();
        } else {
            this.shipment = null;
        }
        this.changeDetectorRef.markForCheck();
        this.spinnerService.hide();
    }

    private async getFreeCourierTransactions(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["courier", "transactions"], {}, {
                hide_checkpoints: true,
                with_warehouse_transactions: false,
                shipment_id: "null",
                order_id: this.order.id
            });
        if (data) {
            this.freeTracks = data;
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }


    private checkShipment(): void {
        if (this.shipment && this.shipment.courier_transactions && this.shipment.courier_transactions.length > 0) {
            this.result.next({
                action: "result",
                value: {shipmentId: this.shipment.id}
            });
        }
    }

    /**
     * Initialize details step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.isViewOnly = data.isViewOnly;
        this.warehouseOrderId = Number(data.warehouseOrderId);

        if (data.orderData) {
            this.order = data.orderData;
        } else {
            await this.getOrder(data.order_id);
        }

        if (data.shipment) {
            this.getShipment(data.shipment.id);
        } else {
            this.getShipment(data.shipmentId);
        }

        this.checkShipment();
        this.getFreeCourierTransactions();

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Create new courier transaction
     * @returns {Promise<any>}
     */
    public async addCourierTransaction(shipmentId?: number): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CourierTransactionFormComponent, {
            type: this.shipment.type,
            action: "add",
            state: this.state,
            shipmentId: shipmentId,
            orderRef: this.order.ref,
            show_link_warehouse_transactions: false,
            link_warehouse_transactions: false,
            partnerSlug: this.order.partner.slug,
            orderData: this.order,
            shipment: this.shipment,
            has_return: this.shipment.type === "outbound"
                && this.order.custom_fields["return_label"]?.toLowerCase() === "yes"
        });

        if (response) {
            if (response.name === "add_return" && await this.alertRef.show(
                "Press continue for return label creation",
                "Outbound label created", "Continue")) {
                this.addReturnCourierTransaction();
            } else {
                this.getShipment();
                this.getFreeCourierTransactions();
            }

        }
    }

    public async addReturnCourierTransaction(): Promise<any> {
        const inbound_shipment = this.shipment.sibling_shipments
            .find((s: Warehouse.IShipment) => {
                return s.type === "inbound";
            });

        if (!inbound_shipment) {
            return;
        }


        const response: Modal.IResponse = await this.modalService.open(CourierTransactionFormComponent, {
            type: inbound_shipment.type,
            action: "add",
            state: this.state,
            shipmentId: inbound_shipment.id,
            orderRef: this.order.ref,
            show_link_warehouse_transactions: false,
            link_warehouse_transactions: false,
            partnerSlug: this.order.partner.slug,
            orderData: this.order,
            shipment: inbound_shipment,
        });

        if (response) {
            this.getShipment();
            this.getFreeCourierTransactions();
        }
    }


    /**
     * Edit courier transaction
     * @param transaction
     * @returns {Promise<any>}
     */
    public async editCourierTransaction(transaction: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(CourierTransactionFormComponent, {
            type: this.shipment.type,
            action: "edit",
            state: this.state,
            shipmentId: transaction.shipment_id,
            orderRef: this.order.ref,
            data: transaction,
            partnerSlug: this.order.partner.slug,
            orderData: this.order,
            shipment: this.shipment
        });

        if (response) {
            this.getShipment();
            this.getFreeCourierTransactions();
        }

    }

    public openLabel(link: string): void {
        window.open(link, "_blank");
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
