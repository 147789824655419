import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Api} from "../../../../../../common/services/api.service";
import {Modal} from "../../../../../services/modal.service";
import {UntypedFormBuilder, FormGroup, Validators, FormControl} from "@angular/forms";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../common/services/api3.service";

@Component({
    selector: "section-location-type-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LocationTypeViewComponent implements OnInit {

    public modal: Modal.IModal;

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        is_active: new FormControl(true),
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private formBuilder: UntypedFormBuilder,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    public async submitForm(): Promise<any> {
        this.spinnerService.show();

        let res: Api.IResponse;

        if (this.modal.params.data) {
            res = await this.api3Service.put(
                `${this.modal.params.state.section}/locations-types/${this.modal.params.data.id}`,
                this.formGroup.value
            );
        } else {
            res = await this.api3Service.post(
                `${this.modal.params.state.section}/locations-types/`,
                this.formGroup.value
            );
        }

        this.spinnerService.hide();

        if (res.code === 200) {
            this.toastService.show(res.message, "success");
            this.close();
        }
    }

    public close(): void {
        this.modal.response.next({
            value: true,
            name: "res"
        });
    }

    public ngOnInit(): void {
        if (this.modal.params.data) {
            this.formGroup.patchValue(this.modal.params.data);
        }
    }
}
