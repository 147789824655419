<h1 class="container-heading">Location type</h1>

<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup">
            <div class="flex group">
                <mat-form-field class="full">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>

                <div class="full">
                    <mat-checkbox formControlName="is_active">Is active</mat-checkbox>
                </div>
            </div>

            <div class="actions">
                <button mat-raised-button (click)="submitForm()" color="primary"
                        [disabled]="!formGroup.valid">
                    Save
                </button>
                &nbsp;
                <button mat-raised-button (click)="close()">
                    Close
                </button>
            </div>

        </form>
    </mat-card-content>
</mat-card>
