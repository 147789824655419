import {NgModule, Type} from "@angular/core";
import {CommonModule} from "../../../common/common.module";
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {ErrorComponent} from "./components/error/error.component";
import {TeamFormComponent, TeamListComponent, TeamViewComponent} from "./components/team";
import {ActivitiesListComponent, ActivityViewComponent} from "./components/activities";
import {AnalyticsComponent} from "./components/analytics/analytics.component";
import {
    ItRequestsListComponent,
    RequestFormComponent,
    RequestKindFormComponent, RequestsKindsListComponent,
    RequestsListAllComponent,
    RequestsListComponent,
    RequestsListMyComponent,
    RequestsViewComponent,
    RequestTasksComponent,
    RequestTaskEditFormComponent,
} from "./components/requests";
import {
    AnnouncementFormComponent,
    AnnouncementsListAllComponent,
    AnnouncementsListComponent,
    AnnouncementsListMyComponent,
    AnnouncementsViewComponent
} from "./components/announcements";
import {AttachmentsListComponent} from "./components/attachments";
import {
    CsvImportWizardComponent,
    CsvImportWizardMapDataComponent,
    CsvImportWizardUploadFileComponent
} from "./components/csv-import";
import {CustomLabelsListComponent, CustomLabelViewComponent} from "./components/custom-labels";
import {
    FollowupFormComponent,
    FollowupsListAllComponent,
    FollowupsListComponent,
    FollowupsListMyComponent, FollowupsTasksListComponent,
    FollowupsTilesComponent,
    FollowupsViewComponent
} from "./components/followup";
import {InventoryViewComponent} from "./components/inventory/view/view.component";
import {ItemConfigurationFormComponent, ItemConfigurationsListComponent} from "./components/inventory/configurations";
import {
    KnowledgeCenterFormComponent,
    KnowledgeCenterListAllComponent,
    KnowledgeCenterListComponent,
    KnowledgeCenterViewComponent
} from "./components/knowledge-center";
import {RecentJobsListComponent} from "./components/recent-jobs";
import {
    RemarkComponent,
    RemarksClassificationsFormComponent,
    RemarksClassificationsListComponent,
    RemarksComponent
} from "./components/remarks";
import {ReportsListComponent, ReportViewComponent, TransferHistoryListComponent} from "./components/reports";
import {ServiceRequestListComponent, ServiceRequestViewComponent} from "./components/service-request";
import {SlaTrackingListComponent} from "./components/sla";
import {
    StockDetailedComponent,
    StockFormComponent,
    StockInventoryAllListComponent,
    StockSummarizedComponent,
    StockTransactionAllListComponent
} from "./components/stock";
import {TokensComponent} from "./components/tokens/tokens.component";
import {OAuthClientFormComponent} from "./components/tokens/client-form/client-form.component";
import {OAuthTokenFormComponent} from "./components/tokens/token-form/token-form.component";
import {OAuthTokenComponent} from "./components/tokens/token-form/token.component";
import {
    TrackingChangeComponent,
    TrackingListComponent,
    TrackingMapComponent,
    TrackingMapSidebarComponent,
    TrackingShippedAtComponent,
    TrackingStatusComponent
} from "./components/tracking";
import {TrackingSidebarComponent} from "./components/tracking/sidebar/sidebar.component";
import {
    OrderItemSelectModalComponent,
    SectionTransactionSplitModalComponent,
    TransactionReplenishmentStatusModalComponent,
    WarehouseTransactionListComponent,
    WarehouseTransactionsListAllComponent,
    WarehouseTransactionsListDeletedComponent,
    WarehouseTransactionsListInboundComponent,
    WarehouseTransactionsListInvalidComponent,
    WarehouseTransactionsListOutboundComponent,
    WarehouseTransactionsListReplenishmentComponent
} from "./components/transactions";
import {
    DashboardCountWidgetComponent,
    DashboardDefaultComponent,
    DashboardDefaultFulfillmentTabComponent,
    DashboardDefaultPersonalTabComponent,
    DashboardDefaultShipmentsTabComponent,
    DashboardThreeplComponent,
    DashboardwarehouseComponent,
} from "./components/dashboard";
import {UrlEntryComponent} from "./components/requests/url-entry/url-entry.component";
import {
    CommonPartMasterFilesListComponent,
    CommonPartMasterViewComponent,
    PartMasterMinQuantityFormComponent,
} from "./components/part-master";
import {ItRequestFormComponent} from "./components/requests/form/it/form.component";
import {OperationsRequestFormComponent} from "./components/requests/form/operations/form.component";
import {InviteComponent, ProfileComponent, UserViewNotificationsComponent} from "./components/user";
import {UserRemarkComponent} from "./components/user/remark/remark.component";
import {UserViewSettingsComponent} from "./components/user/settings/settings.component";
import {PartnerFormStep1Component} from "../admin/components/partner/form/step1/step1.component";
import {PartnerFormStep2Component} from "../admin/components/partner/form/step2/step2.component";
import {PartnerFormStep3Component} from "../admin/components/partner/form/step3/step3.component";
import {PartnerFormStep4Component} from "../admin/components/partner/form/step4/step4.component";
import {
    InspectionBuilderComponent,
    InspectionChooseComponent,
    InspectionEditComponent,
    InspectionFileUploadComponent,
    InspectionFormComponent,
    InspectionListComponent,
    InspectionResultsComponent,
    InspectionViewComponent
} from "./components/inspection";
import {
    OrderLabelWizardComponent,
    OrderListAllComponent,
    OrderListByServiceLevelComponent,
    OrderListCanceledComponent,
    OrderListClosedComponent,
    OrderListComponent,
    OrderListExceptionComponent,
    OrderListModalComponent,
    OrderListOpenComponent,
    OrderListTypeAssemblyComponent,
    OrderListTypeInformativeComponent,
    OrderListTypeReplenishmentComponent,
    OrderListTypeReturnComponent,
    OrderListTypeRmaComponent,
    OrderListTypeSalesComponent,
    OrderListTypeTransferComponent,
    OrderListOnHoldComponent,
    RemarksSidebarComponent, ShippyProPickupWizardStep2Component, ShippyProPickupWizardStep1Component
} from "./components/order";
import {PartnerFormStep5Component} from "../admin/components/partner/form/step5/step5.component";
import {ShippyProPickupWizardComponent} from "./components/order/shippy-pickup-wizard/wizard.component";
import {ShippyProPickupWizardStep3Component} from "./components/order/shippy-pickup-wizard/step3/step3.component";
import {PickupRequestsListComponent} from "./components/pickup-requests/list/list.component";
import {PickupRequestInfoModalComponent} from "./components/pickup-requests/info/info-modal.component";
import {PickupRequestsListAllComponent} from "./components/pickup-requests/list/all.component";
import {
    SettingsAddressFormComponent, SettingsContactFormComponent,
    SettingsCustomerFormComponent,
    SettingsOrderConfirmationFormComponent
} from "./components/settings";
import {CustomFieldsEditModalComponent} from "./components/custom-fields/edit-modal/edit-modal.component";
import {CourierServicesConversionsComponent} from "./components/couriers-services-conversions/list.component";
import {NotesFormComponent} from "./components/notes/notes.component";
import {PackageTypeAllComponent, PackageTypeFormComponent, PackageTypeListComponent} from "./components/package-type";
import {
    LocationTypeAllComponent,
    LocationTypeListComponent,
    LocationTypeViewComponent
} from "./components/location-type";


export const commonRoutingComponents: Type<any>[] = [
    DashboardComponent,
    TeamListComponent,
    TeamFormComponent,
    TeamViewComponent,
    ActivitiesListComponent,
    ActivityViewComponent,
    AnalyticsComponent,
    RequestFormComponent,
    RequestsViewComponent,
    FollowupsTasksListComponent,
    ItRequestFormComponent,
    ItRequestsListComponent,
    OperationsRequestFormComponent,
    RequestsListMyComponent,
    RequestsListAllComponent,
    AnnouncementsViewComponent,
    AnnouncementsListMyComponent,
    AnnouncementsListAllComponent,
    AnnouncementFormComponent,
    CsvImportWizardComponent,
    CustomLabelsListComponent,
    CustomLabelViewComponent,
    FollowupsListAllComponent,
    FollowupsListMyComponent,
    FollowupsViewComponent,
    InventoryViewComponent,
    KnowledgeCenterViewComponent,
    KnowledgeCenterListAllComponent,
    KnowledgeCenterFormComponent,
    RecentJobsListComponent,
    RemarksComponent,
    RemarksClassificationsListComponent,
    ReportsListComponent,
    ServiceRequestListComponent,
    ServiceRequestViewComponent,
    SlaTrackingListComponent,
    StockDetailedComponent,
    StockSummarizedComponent,
    StockTransactionAllListComponent,
    StockInventoryAllListComponent,
    TrackingListComponent,
    TrackingMapComponent,
    WarehouseTransactionsListAllComponent,
    WarehouseTransactionsListInboundComponent,
    WarehouseTransactionsListOutboundComponent,
    WarehouseTransactionsListInvalidComponent,
    WarehouseTransactionsListReplenishmentComponent,
    WarehouseTransactionsListDeletedComponent,
    ReportViewComponent,
    TransferHistoryListComponent,
    ProfileComponent,
    InspectionListComponent,
    InspectionResultsComponent,
    InspectionViewComponent,
    InspectionBuilderComponent,
    InspectionChooseComponent,
    InspectionEditComponent,
    InspectionFormComponent,
    OrderLabelWizardComponent,
    InviteComponent,
    ShippyProPickupWizardComponent,
    PickupRequestsListAllComponent,
    RequestsKindsListComponent,
    OrderListAllComponent,
    OrderListByServiceLevelComponent,
    OrderListCanceledComponent,
    OrderListClosedComponent,
    OrderListComponent,
    OrderListExceptionComponent,
    OrderListModalComponent,
    OrderListOpenComponent,
    OrderListTypeAssemblyComponent,
    OrderListTypeInformativeComponent,
    OrderListTypeReplenishmentComponent,
    OrderListTypeReturnComponent,
    OrderListTypeRmaComponent,
    OrderListTypeSalesComponent,
    OrderListTypeTransferComponent,
    OrderListOnHoldComponent,
    CourierServicesConversionsComponent,
    NotesFormComponent,
    PartMasterMinQuantityFormComponent,
    PackageTypeAllComponent,
    LocationTypeAllComponent
];


@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ...commonRoutingComponents,
        ErrorComponent,
        AttachmentsListComponent,
        AnnouncementsListComponent,
        ItemConfigurationsListComponent,
        ItemConfigurationFormComponent,
        TokensComponent,
        OAuthClientFormComponent,
        OAuthTokenFormComponent,
        OAuthTokenComponent,
        TrackingChangeComponent,
        TrackingMapSidebarComponent,
        TrackingShippedAtComponent,
        TrackingSidebarComponent,
        TrackingStatusComponent,
        WarehouseTransactionListComponent,
        OrderItemSelectModalComponent,
        TransactionReplenishmentStatusModalComponent,
        SectionTransactionSplitModalComponent,
        FollowupFormComponent,
        RemarksSidebarComponent,
        CsvImportWizardUploadFileComponent,
        CsvImportWizardMapDataComponent,
        DashboardCountWidgetComponent,
        DashboardDefaultComponent,
        DashboardThreeplComponent,
        DashboardwarehouseComponent,
        FollowupsListComponent,
        FollowupsTilesComponent,
        KnowledgeCenterViewComponent,
        KnowledgeCenterListAllComponent,
        KnowledgeCenterFormComponent,
        KnowledgeCenterListComponent,
        RequestKindFormComponent,
        RequestsListComponent,
        RequestTasksComponent,
        RequestTaskEditFormComponent,
        UrlEntryComponent,
        StockFormComponent,
        CommonPartMasterFilesListComponent,
        DashboardDefaultPersonalTabComponent,
        DashboardDefaultShipmentsTabComponent,
        DashboardDefaultFulfillmentTabComponent,
        UserViewSettingsComponent,
        UserViewNotificationsComponent,
        UserRemarkComponent,
        RemarksClassificationsFormComponent,
        PartnerFormStep1Component,
        PartnerFormStep2Component,
        PartnerFormStep3Component,
        PartnerFormStep4Component,
        PartnerFormStep5Component,
        CommonPartMasterViewComponent,
        RemarkComponent,
        InspectionFileUploadComponent,
        PickupRequestInfoModalComponent,
        PickupRequestsListComponent,
        SettingsAddressFormComponent,
        SettingsCustomerFormComponent,
        SettingsContactFormComponent,
        SettingsOrderConfirmationFormComponent,
        OrderListComponent,
        OrderListModalComponent,
        ShippyProPickupWizardStep1Component,
        ShippyProPickupWizardStep2Component,
        ShippyProPickupWizardStep3Component,
        CustomFieldsEditModalComponent,
        PackageTypeListComponent,
        PackageTypeFormComponent,
        LocationTypeViewComponent,
        LocationTypeListComponent
    ],
    providers: [],
    exports: [
        RemarksSidebarComponent,
        ActivityViewComponent,
        UserViewSettingsComponent,
        UserViewNotificationsComponent,
        UserRemarkComponent,
        AttachmentsListComponent,
        TokensComponent,
        CommonPartMasterViewComponent,
        CommonPartMasterFilesListComponent,
        PartnerFormStep1Component,
        PartnerFormStep2Component,
        PartnerFormStep3Component,
        PartnerFormStep4Component,
        PartnerFormStep5Component,
        RemarkComponent,
        SettingsAddressFormComponent,
        SettingsCustomerFormComponent,
        SettingsContactFormComponent,
        SettingsOrderConfirmationFormComponent,
        OrderListComponent,
        OrderListModalComponent,
        CustomFieldsEditModalComponent,
        NotesFormComponent,
        PartMasterMinQuantityFormComponent,

    ]
})
export class CommonSectionModule {
}
