import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {Router} from "@angular/router";
import {ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {ModalService} from "../../../../../services/modal.service";
import {BoxItemsListComponent} from "../../procedures";
import {BoxSticker2Component, BoxStickerComponent} from "..";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";

@Component({
    selector: "section-warehouse-boxes-list",
    template: `

        <h1 class="container-heading">Boxes</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseBoxesListComponent implements Base.IComponent, OnInit {

    public readonly state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    /* Table options */

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        this.listTable = {
            actions: [
                {
                    name: "list",
                    title: "Items",
                    click: (row: any): void => {
                        this.modalService.open(BoxItemsListComponent, {
                            box: row,
                            modalWidth: 1000,
                            itemsType: "warehouse-transactions"
                        });
                    }
                },
                {
                    name: "print",
                    title: "Print sticker",
                    click: (row: any): void => {
                        this.modalService.open(BoxStickerComponent, {
                            box: row
                        });
                    }
                },
                {
                    name: "print",
                    title: "Print sticker",
                    click: (row: any): void => {
                        this.modalService.open(BoxSticker2Component, {
                            box: row,
                            warehouseName: row.warehouse_location.warehouse.name,
                            locationName: row.warehouse_location.location
                        });
                    }
                }
            ],
            api: {
                url: [this.state.section, "boxes"],
                query: {
                    relations: [
                        "WarehouseLocation:id,location",
                        "WarehousePackage:id,name",
                        "Inventory:id,box_id,inventory_conversion_id",
                        "Inventory.InventoryConversion:id,customers_sub_inventory,customers_inventory_name",
                    ],
                },
                version: 3
            },
            columns: [
                {
                    data: "ref",
                    title: "Ref"
                },
                {
                    data: "inventory.inventory_conversion.customers_sub_inventory",
                    name: "Inventory.InventoryConversion.customers_sub_inventory",
                    title: "Hub (from Inventory)",
                    render: (row: any) => {
                        if (row.inventory?.inventory_conversion) {
                            return `
                            ${row.inventory.inventory_conversion.customers_inventory_name}
                            -
                            ${row.inventory.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return "";
                        }
                    },
                    sortable: false
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Location"
                },
                {
                    data: "warehouse_package.name",
                    name: "WarehousePackage.name",
                    title: "Package"
                },
                {
                    data: "inventories_count",
                    title: "Items",
                    searchable: false
                },
                {
                    data: "full",
                    title: "Full",
                    searchable: false,
                    render: (data: number): string => {
                        return data ? "Yes" : "No";
                    }
                },
            ],
            infinity_scroll: true
        };

        this.changeDetectorRef.markForCheck();
    }


    public ngOnInit(): void {
        this.spinnerService.show();
        this.prepareList();
        this.spinnerService.hide();
    }


    public ngConfig(): Base.IConfig {
        return {
            name: "warehouse-boxes",
            actions: {
                "browse": ["browse_boxes"]
            }
        };
    }
}
