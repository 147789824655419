<h1 class="container-heading">Package</h1>
<mat-card>
    <mat-card-content>
        <form [formGroup]="formGroup" (submit)="submit()" lang="en-US">
            <div class="group flex">
                <mat-form-field class="third">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>
                <mat-form-field class="third">
                    <mat-label>Type</mat-label>
                    <mat-select formControlName="warehouse_package_type" required>
                        <mat-option *ngFor="let pt of packageTypes | async"
                                    [value]="JSON.stringify({id:pt.id, weight:pt.weight})">
                            {{pt.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="third">
                    <mat-label>Package weight, kg</mat-label>
                    <input matInput type="number" formControlName="package_weight" required noscroll>
                </mat-form-field>
            </div>

            <div class="group flex">
                <mat-form-field class="quarter">
                    <mat-label>Length</mat-label>
                    <input matInput type="number" formControlName="length" noscroll step="0.1" [min]="minDistanceValue"
                           [required]="modal.params.require_dimensions">
                    <mat-error *ngIf="formGroup.get('length').hasError('min')">
                        Min value is {{minDistanceValue}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="quarter">
                    <mat-label>Width</mat-label>
                    <input matInput formControlName="width" type="number" noscroll step="0.1" [min]="minDistanceValue"
                           [required]="modal.params.require_dimensions">
                    <mat-error *ngIf="formGroup.get('width').hasError('min')">
                        Min value is {{minDistanceValue}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="quarter">
                    <mat-label>Height</mat-label>
                    <input matInput formControlName="height" type="number" noscroll step="0.1" [min]="minDistanceValue"
                           [required]="modal.params.require_dimensions">
                    <mat-error *ngIf="formGroup.get('height').hasError('min')">
                        Min value is {{minDistanceValue}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="quarter">
                    <mat-label>Distance unit</mat-label>
                    <mat-select formControlName="distance_unit" [required]="modal.params.require_dimensions">
                        <mat-option *ngFor="let unit of distanceUnits" [value]="unit">
                            {{unit}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="group flex">
                <mat-form-field class="third">
                    <mat-label>Weight</mat-label>
                    <input matInput formControlName="weight" type="number" noscroll step="0.1" min="0.1"
                           [required]="modal.params.require_dimensions">
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Gross weight</mat-label>
                    <input matInput formControlName="gross_weight" type="number" noscroll step="0.1" min="0.1"
                           [required]="modal.params.require_dimensions">
                </mat-form-field>

                <mat-form-field class="third">
                    <mat-label>Mass unit</mat-label>
                    <mat-select formControlName="mass_unit" [required]="modal.params.require_dimensions">
                        <mat-option *ngFor="let unit of massUnits" [value]="unit">
                            {{unit}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="actions" *ngIf="state.section_type === 'partner'">
                <button type="button" mat-raised-button color="accent" (click)="getPartDimensions()">
                    Get dimensions from order part
                </button>

                <button *ngIf="modal.params.shipment?.type==='inbound'"
                        type="button" mat-raised-button color="prymary" (click)="getOutboundPackages()">
                    Copy from outbound
                </button>
            </div>

            <div class="actions">
                <button type="submit" mat-raised-button color="primary" [disabled]="!formGroup.valid">
                    Submit
                </button>

                <button type="button" mat-raised-button *ngIf="modal?.params?.package?.id" (click)="clone()">
                    Clone
                </button>

                <button type="button" mat-raised-button (click)="modal.response.emit(null)">Cancel</button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
