<common-confirm></common-confirm>

<form [formGroup]="minQuantityForm">
  <mat-card formArrayName="minQuantityArray">
    <div class="group flex" *ngFor="let control of minQuantityArray.controls; let i=index" [formGroupName]="i">
      <mat-card-content>

        <common-form-hub-select class="country-filter" required multiple
          label="Hub"
          [value]="control.value.inventory_conversion_ids"
          (onClose)="control.get('inventory_conversion_ids').setValue($event)">
        </common-form-hub-select>

        <mat-form-field class="full">
          <mat-label>Minimum quantity for inform</mat-label>
          <input matInput type="number" min="0" step="1" formControlName="quantity">
        </mat-form-field>

      <button class="pull-right" mat-raised-button color="warn" type="button" (click)="handleRemoveHubMinQuantity(i)" [disable]="control.invalid">
        Remove
      </button>

    </mat-card-content>
    <mat-divider></mat-divider>
    </div>
  </mat-card>

  <div class="actions">
    <button mat-raised-button color="accent" type="button" (click)="addHubMinQuantity()">Add</button>
    <button mat-raised-button color="accent" type="button" (click)="handleSaveHubMinQuantity()" [disabled]="minQuantityArray.invalid">
      Save
    </button>
  </div>

</form>