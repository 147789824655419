import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal} from "../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";

@Component({
    selector: "section-request-form-kind",
    templateUrl: "kind.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RequestKindFormComponent implements OnInit {

    /**
     * Form group / group with controls
     * @type {FormGroup}
     */
    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        category: new FormControl(null, [Validators.required]),
    });

    public modal: Modal.IModal;
    public isEdit: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Submit form
     * @returns {Promise<any>}
     */
    public async submit(): Promise<any> {
        this.spinnerService.show();
        if (this.isEdit) {
            const { data }: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["request", "kind", this.modal.params.id], this.formGroup.value);
            if (data && data.id) {
                this.modal.response.emit({
                    name: "response",
                    value: data.id
                });
            }
        } else {
            const { data }: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["request", "kind"], this.formGroup.value);
            if (data && data.id) {
                this.modal.response.emit({
                    name: "response",
                    value: data.id
                });
            }
        }
        this.spinnerService.hide();
    }

    public async getKind(id: any): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["request", "kind", id]);
        if (data && data.id) {
            this.formGroup.get("name").setValue(data.name);
            this.formGroup.get("category").setValue(data.category);
        }
        this.spinnerService.hide();
    }

    /**
     * Close modal
     */
    public close(): void {
        this.modal.response.emit();
    }

    public ngOnInit(): void {
        if (this.modal.params.id) {
            this.isEdit = true;
            this.getKind(this.modal.params.id);
        }
        if (this.modal.params.category) {
            this.formGroup.get("category").setValue(this.modal.params.category);
        }
    }
}
