import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit} from "@angular/core";
import {ReplaySubject} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ModalTable2Component} from "../../../../../../common/components/table2";
import {AppStateService} from "../../../../../../common/services/app-state.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api3Service} from "../../../../../../common/services/api3.service";


@Component({
    selector: "section-package-form",
    templateUrl: "package.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackageFormComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public packageTypes: ReplaySubject<any> = new ReplaySubject(1);

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        warehouse_package_type_id: new FormControl(null),
        warehouse_package_type: new FormControl(null, [Validators.required]),
        package_weight: new FormControl(null),
        length: new FormControl(null),
        width: new FormControl(null),
        height: new FormControl(null),
        distance_unit: new FormControl("cm"),
        weight: new FormControl(null),
        gross_weight: new FormControl(null),
        mass_unit: new FormControl("kg"),
        warehouse_id: new FormControl(null),
        shipment_id: new FormControl(null),
    });

    public minDistanceValue = 1;

    public distanceUnits: string [] = [
        "cm",
        "inch",
        "mm"
    ];

    public massUnits: string[] = [
        "kg",
        "lbs"
    ];


    public modal: Modal.IModal;

    public state: Base.IState;

    public constructor(
        private apiService: ApiService,
        private api3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private modalService: ModalService
    ) {
    }

    /**
     * Get package types
     */
    private async getPackageTypes(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/warehouse-package-types`, {
            shipment_id: this.modal.params.shipmentId
        });
        if (data) {
            this.packageTypes.next(data);
            data.forEach((type: { id: number, weight: number }): void => {
                if (type.id === this.formGroup.get("warehouse_package_type_id").value) {

                    this.formGroup.get("warehouse_package_type").setValue(JSON.stringify({
                            id: type.id,
                            weight: type.weight
                        }),
                        {emitEvent: false});

                    if (!this.formGroup.get("package_weight").value) {
                        this.formGroup.get("package_weight").setValue(type.weight);
                    }
                }
            });
        }
        this.spinnerService.hide();
    }

    private async getWarehouseProperties(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}`);
        this.spinnerService.hide();
        if (data) {
            this.formGroup.get("mass_unit").setValue(data.properties.default_mass_unit);
            this.formGroup.get("distance_unit").setValue(data.properties.default_distance_unit);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Calculate package weight if it is not specified
     */
    private async calculateWeight(): Promise<any> {
        this.spinnerService.show();
        if (this.modal.params.warehouse_slug) {
            this.apiService.setHeaders({warehouse: this.modal.params.warehouse_slug});
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["transaction", "order", "" + this.modal.params.shipment.order_id], {}, {
                type: "outbound",
                warehouse_package_id: this.modal.params.package.id
            });
        if (data) {
            let weight: number = 0;
            data.forEach((transaction: { part_master: { weight: number, mass_unit: string } }): void => {
                weight += Number(transaction.part_master.weight);
            });
            this.formGroup.get("weight").setValue(Math.ceil(weight));
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private calculateNettWeight(): void {
        let multiply = 1;
        if (this.formGroup.value.mass_unit === "lbs") {
            multiply = 2.20462;
        }

        const gross_weight: number = Number(this.formGroup.value.gross_weight);
        const package_weight: number = Number(this.formGroup.value.package_weight) * multiply;

        this.formGroup.get("weight")
            .setValue("" + (Math.round((gross_weight - package_weight) * 100)) / 100, {emitEvent: false});

        this.changeDetectorRef.markForCheck();

    }

    private calculateGrossWeight(): void {
        let multiply = 1;
        if (this.formGroup.value.mass_unit === "lbs") {
            multiply = 2.20462;
        }

        const weight: number = Number(this.formGroup.value.weight);
        const package_weight: number = Number(this.formGroup.value.package_weight) * multiply;

        this.formGroup.get("gross_weight")
            .setValue("" + (Math.round((package_weight + weight) * 100)) / 100, {emitEvent: false});

        this.changeDetectorRef.markForCheck();

    }

    /**
     * Prepare body object
     */
    private get body(): any {
        const body: any = {...this.formGroup.value};
        // body.warehouse_package_type_id = this.formGroup.value.warehouse_package_type.id;
        body.weight = "" + body.weight;
        body.height = "" + body.height;
        body.length = "" + body.length;
        body.width = "" + body.width;
        delete body.warehouse_package_type;
        return body;
    }

    /**
     * New package
     */
    private async submitNew(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
            ["warehouse_package"], this.body);
        this.spinnerService.hide();
        if (data) {
            this.modal.response.emit({
                name: "value"
            });
        }
    }

    /**
     * Update package
     */
    private async submitUpdate(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["warehouse_package", "" + this.modal.params.package.id], this.body);

        this.spinnerService.hide();
        if (data) {
            this.modal.response.emit({
                name: "value"
            });
        }
    }

    private setSizeValidators(): void {
        if (!this.modal.params.require_dimensions) {
            return;
        }

        switch (this.formGroup.get("distance_unit").value) {
            case "cm":
                this.minDistanceValue = 3;
                break;
            case "mm":
                this.minDistanceValue = 30;
                break;
            default:
                this.minDistanceValue = 1;
                break;
        }

        for (const name of ["length", "width", "height"]) {
            this.formGroup.get(name).setValidators([Validators.required, Validators.min(this.minDistanceValue)]);
            this.formGroup.get(name).updateValueAndValidity();
        }
    }

    private setListeners(): void {
        this.formGroup.get("weight").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                this.calculateGrossWeight();
            });

        this.formGroup.get("gross_weight").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                this.calculateNettWeight();
            });

        this.formGroup.get("distance_unit").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                this.setSizeValidators();
            });

        this.formGroup.get("warehouse_package_type").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: any): void => {
                value = JSON.parse(value);

                this.formGroup.get("warehouse_package_type_id").setValue(value.id);
                this.formGroup.get("package_weight").setValue(Number(value.weight));
            });

        this.formGroup.get("package_weight").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                this.calculateGrossWeight();
            });

        this.formGroup.get("mass_unit").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: string): void => {
                this.calculateGrossWeight();
            });
    }

    private async init(): Promise<any> {
        await this.getPackageTypes();

        if (this.modal.params.warehouse) {
            this.formGroup.get("warehouse_id").setValue(this.modal.params.warehouse.id);
        }
        if (this.modal.params.shipmentId) {
            this.formGroup.get("shipment_id").setValue(this.modal.params.shipmentId);
        }

        if (this.modal.params.warehouse
            && this.modal.params.warehouse.properties
            && this.modal.params.warehouse.properties.default_mass_unit) {
            this.formGroup.get("mass_unit").setValue(this.modal.params.warehouse.properties.default_mass_unit);
        }


        if (this.modal.params.package) {
            this.formGroup.patchValue(this.modal.params.package);

            if (!this.formGroup.value.weight) {
                this.calculateWeight();
            }

            if (this.modal.params.package.warehouse_package_type) {

                this.formGroup.get("warehouse_package_type_id")
                    .setValue(this.modal.params.package.warehouse_package_type.id);

                this.formGroup.get("warehouse_package_type")
                    .setValue(JSON.stringify({
                        id: this.modal.params.package.warehouse_package_type.id,
                        weight: this.modal.params.package.warehouse_package_type.weight
                    }));

                if (!this.formGroup.get("package_weight").value) {
                    this.formGroup.get("package_weight")
                        .setValue("" + this.modal.params.package.warehouse_package_type.weight);
                }
            }

        } else if (this.state.section_type === "warehouse") {
            this.getWarehouseProperties();
        }

        this.setSizeValidators();

        if (this.modal.params.require_dimensions) {
            for (const name of ["weight", "gross_weight"]) {
                this.formGroup.get(name).setValidators([Validators.required, Validators.min(0.1)]);
                this.formGroup.get(name).updateValueAndValidity();
            }
        }

        this.setListeners();
    }

    /**
     * Submit form
     */
    public submit(): void {
        if (this.modal.params.package && this.modal.params.package.id) {
            this.submitUpdate();
        } else {
            this.submitNew();
        }
    }

    public clone(): void {
        this.modal.params.package = null;
        this.formGroup.get("name").setValue(HelpersService.randomString());
        this.changeDetectorRef.markForCheck();
    }

    public async getPartDimensions(): Promise<any> {
        await this.modalService.open(ModalTable2Component,
            {
                title: "Order items",
                search: true,
                settings: {
                    columns: [
                        {
                            data: "item",
                            title: "Item"
                        },
                        {
                            data: "part_master.width",
                            title: "With"
                        },
                        {
                            data: "part_master.length",
                            title: "Length"
                        },
                        {
                            data: "part_master.height",
                            title: "Height"
                        },
                        {
                            data: "part_master.weight",
                            title: "Weight"
                        },
                    ],
                    actions: [
                        {
                            name: "wizard",
                            title: "Select",
                            click: (row: any): void => {
                                this.formGroup.get("length").setValue(row.part_master.length);
                                this.formGroup.get("width").setValue(row.part_master.width);
                                this.formGroup.get("height").setValue(row.part_master.height);
                                this.formGroup.get("weight").setValue(row.part_master.weight);
                                this.formGroup.get("mass_unit").setValue(row.part_master.mass_unit);
                                this.changeDetectorRef.markForCheck();

                                const keys: any[] = Object.keys(ModalService.modals);
                                const last_modal_key: string = keys[keys.length - 1];

                                ModalService.modals[last_modal_key].detach();
                                delete ModalService.modals[last_modal_key];
                            }
                        }
                    ],
                    api: {
                        url: [
                            AppStateService.getState().section,
                            "shipments",
                            this.modal.params.shipmentId,
                            "order-items"
                        ],
                        query: {
                            relations: ["PartMaster"],
                            group_by: "part_master_id"
                        },
                        version: 3
                    }
                }
            });
    }

    public async getOutboundPackages(): Promise<any> {
        await this.modalService.open(ModalTable2Component,
            {
                title: "Outbound packages",
                search: true,
                settings: {
                    columns: [
                        {
                            data: "name",
                            title: "Name"
                        },
                        {
                            data: "warehouse_package_type.name",
                            name: "WarehousePackageName.name",
                            title: "Type",
                            sortable: false
                        },
                        {
                            data: "width",
                            title: "With"
                        },
                        {
                            data: "length",
                            title: "Length"
                        },
                        {
                            data: "height",
                            title: "Height"
                        },
                        {
                            data: "weight",
                            title: "Weight"
                        },
                        {
                            data: "mass_unit",
                            title: "Mass unit"
                        },
                    ],
                    actions: [
                        {
                            name: "wizard",
                            title: "Select",
                            click: (row: any): void => {
                                this.formGroup.get("name").setValue(row.name + " - return");

                                this.formGroup.get("warehouse_package_type_id")
                                    .setValue(row.warehouse_package_type_id);

                                this.formGroup.get("warehouse_package_type")
                                    .setValue(JSON.stringify({
                                        id: row.warehouse_package_type.id,
                                        weight: row.warehouse_package_type.weight
                                    }));
                                this.formGroup.get("package_weight")
                                    .setValue("" + row.warehouse_package_type.weight);

                                this.formGroup.get("length").setValue(row.length);
                                this.formGroup.get("width").setValue(row.width);
                                this.formGroup.get("height").setValue(row.height);
                                this.formGroup.get("weight").setValue(row.weight);
                                this.formGroup.get("mass_unit").setValue(row.mass_unit);

                                this.changeDetectorRef.markForCheck();

                                const keys: any[] = Object.keys(ModalService.modals);
                                const last_modal_key: string = keys[keys.length - 1];

                                ModalService.modals[last_modal_key].detach();
                                delete ModalService.modals[last_modal_key];
                            }
                        }
                    ],
                    api: {
                        url: [
                            AppStateService.getState().section,
                            "orders",
                            this.modal.params.shipment.order_id,
                            "packages",
                            "outbound"
                        ],
                        query: {
                            relations: [
                                "WarehousePackageType"
                            ]
                        },
                        version: 3
                    }
                }
            });
    }

    public ngOnInit(): void {

        this.state = AppStateService.getState();

        this.init();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    protected readonly JSON = JSON;
}
