import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Api} from "../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {CommonFormComponent} from "../../../../../../common/components/form";
import {PartnerService} from "../../../../../../common/services/partner.service";
import {UserService} from "../../../../../../common/services/user.service";
import {RequestKindFormComponent} from "./kind.component";
import {Api3Service} from "src/modules/common/services/api3.service";
import {SpinnerService} from "src/modules/common/services/spinner.service";


@Component({
    selector: "section-requests-kinds-list",
    template: `
        <h1 class="container-heading">Request kinds</h1>
        <mat-card>
            <mat-card-content>
                <common-table2 #table *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <button type="button" mat-raised-button color="primary"
                        [disabled]='!canAdd'
                        (click)="add()">
                            Add
                        </button>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RequestsKindsListComponent implements Base.IComponent, OnInit {


    public readonly state: Base.IState;


    @ViewChild("table", {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public kinds: any[] = [];
    public canAdd: boolean = false;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private modalService: ModalService,
        private userService: UserService,
        private api3Service: Api3Service,
        private spinnerService: SpinnerService,
    ) {
    }

    private prepareList(): void {

        const columns: Table.ICol[] = [
            {
                data: "name",
                title: "Name",
            },
            {
                data: "category",
                title: "Category",
            },
            {
                data: "created_at",
                title: "Created at",
                searchable: false
            },
        ];

        if (!PartnerService?.partner?.id) {
            columns.push({
                data: "partner.display_name",
                title: "Partner",
                sortable: false
            });
        }

        this.listTable = {
            table_id: "5gcR8vJds67",
            api: {
                url: [this.state.section, "request-kinds"],
                query: {
                    relations: ["Partner:id,display_name"]
                },
                version: 3
            },
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any): void => {
                        this.edit(row);
                    },
                    disabledFn: (row: any): boolean => {
                        if (!this.userService.validatePermissions("edit_request_kinds")) {
                            return true;
                        }
                        if (this.state.section_type === "admin") {
                            return false;
                        }
                        return row.partner_id != PartnerService.partner?.id;
                    },
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.delete(row);
                    },
                    disabledFn: (row: any): boolean => {
                        if (!this.userService.validatePermissions("delete_request_kinds")) {
                            return true;
                        }
                        if (this.state.section_type === "admin") {
                            return false;
                        }
                        return row.partner_id != PartnerService.partner?.id;
                    },
                },

            ],
            columns
        };
        this.changeDetectorRef.markForCheck();
    }

    public async delete(kind: any): Promise<any> {
        const result = await this.modalService.open(CommonFormComponent, {
            formConfig: {
                id: 0,
                name: "Delete kind",
                description: "You must reassign all requests to a different kind",
                fields: [
                    {
                        label: "Select substitute request kind",
                        name: "substitute_request_kind_id",
                        size: "full",
                        type: "select",
                        required: true,
                        url: "/request/kind?category=" + kind.category + "&exclude_id=" + kind.id
                    },
                ]
            },
        });

        if (result?.value) {
            this.spinnerService.show();
            await this.api3Service.delete(
                `${this.state.section}/request-kinds/${kind.id}`,
                result.value
            );
            this.spinnerService.hide();

            this.tableRef.reload();
        }
    }

    public async edit(kind: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(RequestKindFormComponent, {
            id: kind.id,
            name: kind.name
        });

        this.tableRef.reload();
    }

    public async add(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(RequestKindFormComponent, {
            category: "operations"
        });

        this.tableRef.reload();
    }

    public ngOnInit(): void {
        this.prepareList();
        this.canAdd = this.userService.validatePermissions("add_request_kinds");
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "support-requests-kinds",
            actions: {
                "browse": ["browse_requests"]
            }
        };
    }
}
