import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../common/components/confirm/confirm.component";
import {LoginService} from "../../../common/services/login.service";
import {UserService} from "../../../common/services/user.service";
import {User} from "../../../common/interfaces/user.interface";
import {FormControl} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import {Warehouse} from "../../../common/interfaces/warehouse.interface";
import {WarehouseService} from "../../../common/services/warehouse.service";
import {PartnerService} from "../../../common/services/partner.service";

@Component({
    selector: "app-entrance",
    templateUrl: "entrance.component.html",
    styleUrls: [
        "entrance.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class EntranceComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    /**
     * If current user is admin
     * @type {boolean}
     */
    public admin: boolean = false;

    /**
     * User data
     * @type {User.IData}
     */
    public data: User.IData;

    /**
     * If progress bar is visible
     * @type {boolean}
     */
    public progress: boolean = false;


    public filteredPartners: User.IPartner[];

    public filteredThreepls: User.IThreepl[];

    public filteredWarehouses: Warehouse.IWarehouse[];

    public showCount: number = 3;

    public showHistory: FormControl = new FormControl(false);

    public gridCols: number = 4;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private router: Router,
        private loginService: LoginService,
        private userService: UserService
    ) {
    }

    /**
     * Prepare sections list or redirect to one of them
     * @returns {Promise<any>}
     */
    private async prepareSection(): Promise<any> {
        this.data = this.userService.data;

        if (this.loginService.token) {
            this.progress = true;
            // await this.userService.getUser();
            this.admin = this.userService.validatePermissions("browse_admin");
            this.data = this.userService.data;
            this.progress = false;
            this.changeDetectorRef.markForCheck();

            if (this.data && this.data.partners && this.data.threepls && this.data.warehouses) {
                if (this.admin && this.data.partners.length === 0 && this.data.threepls.length === 0
                    && this.data.warehouses.length === 0) {
                    this.router.navigate(["/", "admin"]);
                }

                if (!this.admin && this.data.partners.length === 1 && this.data.threepls.length === 0
                    && this.data.warehouses.length === 0) {
                    this.router.navigate([
                        "/partner", this.data.partners[0].slug,
                        this.data.settings.default_route || ""
                    ]);
                }

                if (!this.admin && this.data.threepls.length === 1 && this.data.partners.length === 0
                    && this.data.warehouses.length === 0) {
                    this.router.navigate(["/threepl", this.data.threepls[0].slug]);
                }

                if (!this.admin && this.data.threepls.length === 0 && this.data.partners.length === 0
                    && this.data.warehouses.length === 1) {
                    this.router.navigate(["/warehouse", this.data.warehouses[0].slug]);
                }

                this.filterPartners();
                this.filterThreepls();
                this.filterWarehouses();

                this.showHistory.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((): void => {
                    this.filterPartners();
                });
            }
        }
    }

    public goToPartner(partner: User.IPartner): void {
        PartnerService.partner = partner;
        this.router.navigate(["/partner/", partner.slug, this.data.settings.default_route || ""]);
    }

    public goToThreepl(threepl: User.IThreepl): void {
        this.router.navigate(["/threepl/", threepl.slug, this.data.settings.threepl_default_route || ""]);
    }

    public goToWarehouse(warehouse: Warehouse.IWarehouse): void {
        WarehouseService.warehouse = warehouse;
        this.router.navigate(["/warehouse/", warehouse.slug, "warehouse-orders-open"]);
    }


    public filterPartners(search?: string): void {
        if (search) {
            this.filteredPartners = this.data.partners.filter((partner: User.IPartner): boolean => {
                return partner.display_name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            });
        } else {
            this.filteredPartners = this.data.partners;
        }

        if (!this.showHistory.value) {
            this.filteredPartners = this.filteredPartners.filter((partner: User.IPartner): boolean => {
                return partner.display_name.toLocaleLowerCase().indexOf("history") === -1;
            });
        }
    }

    public filterThreepls(search?: string): void {
        if (search) {
            this.filteredThreepls = this.data.threepls.filter((threepl: User.IThreepl): boolean => {
                return threepl.display_name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            });
        } else {
            this.filteredThreepls = this.data.threepls;
        }
    }

    public filterWarehouses(search?: string): void {
        if (search) {
            this.filteredWarehouses = this.data.warehouses.filter((warehouse: Warehouse.IWarehouse): boolean => {
                return warehouse.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            });
        } else {
            this.filteredWarehouses = this.data.warehouses;
        }
    }


    /**
     * Logout
     * @returns {Promise<any>}
     */
    public async logout(): Promise<any> {
        if (await this.confirmRef.confirm("Do you want to log out?")) {
            this.loginService.logout();
        }
    }

    public handleSize(width: number): void {

        switch (true) {
            case width <= 700:
                this.gridCols = 1;
                break;
            case width <= 900:
                this.gridCols = 2;
                break;
            default:
                this.gridCols = 4;
        }
    }

    public ngOnInit(): any {
        this.title.setTitle("Logivice / Entrance");
        this.prepareSection();
        this.userService.clearSection();
        this.userService.getUser();

        this.handleSize(window.innerWidth);
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
