import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation
} from "@angular/core";
import {Table} from "../../interfaces/table.interface";
import {IPagination} from "../pagination/pagination.component";
import {Api, ApiService} from "../../services/api.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import {SpinnerService} from "../../services/spinner.service";
import {takeUntil} from "rxjs/operators";
import {PaginationService} from "../../services/pagination.service";
import {UserService} from "../../services/user.service";
import {ToastService} from "../../services/toast.service";
import {Api3Service} from "../../services/api3.service";
import {AmplitudeService} from "../../services/amplitude.service";
import {HelpersService} from "../../services/helpers.service";
import {AppStateService} from "../../services/app-state.service";

@Component({
    selector: "common-table2",
    templateUrl: "table.component.html",
    styleUrls: [
        "table.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class Table2Component implements OnInit, OnDestroy, OnChanges {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    private selectedRows: any[] = [];

    private noMoreData: boolean = false;

    public use_external_export: boolean = false;

    public requestId: string;

    @Input()
    public settings: Table.ISettings;

    @Input()
    public search: boolean = true;

    @Input()
    public scrollHeightValue: number = 255;

    public searchIn: { name: string, value: string }[] = [];

    public searchForm: FormGroup = new FormGroup({
        search_by: new FormControl(null),
        search_in: new FormControl([], [Validators.required]),
        search_like: new FormControl(true),
    });

    public titleSearchMode: "Loose" | "Strict" = "Loose";

    public tableActions: Table.IAction[] = [];

    public tableColumns: Table.ICol[] = [];

    public tableColumnsFiltered: Table.ICol[] = [];

    public tablePagination: IPagination<Table.ICol>;

    public tableData: any[] = [];

    public sort: Table.ISort = {data: null, dir: "asc", name: null};

    public rowSelection: FormGroup = new FormGroup({
        rows: new FormArray([]),
        selectAll: new FormControl(false)
    });

    public loading: boolean = false;

    public currentPage: number = 1;

    @Output()
    public onRowsSelected: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Output()
    public gotData: EventEmitter<{ hasData: boolean, page: number, total: number }> =
        new EventEmitter<{ hasData: boolean, page: number, total: number }>();

    @Output()
    public onReset: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onSearchChange: EventEmitter<any> = new EventEmitter<any>();

    public table_has_footer: boolean = false;

    public constructor(
        private apiService: ApiService,
        private api3Service: Api3Service,
        private changeDetectorRef: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private paginationService: PaginationService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private toastService: ToastService,
    ) {
    }

    private init(): void {
        const current_page: number = this.settings.table_id
            ? this.paginationService.getPageById(this.settings.table_id) : 1;

        const per_page: number = this.settings.table_id
            ? this.paginationService.getPerPageById(this.settings.table_id)
            : this.userService.data.settings.default_per_page;

        if (this.settings.sort_default) {
            this.sort = this.settings.sort_default;
        }

        if (this.settings.api) {
            if (this.settings.per_page) {
                this.getData(current_page, per_page ? per_page : this.settings.per_page);
            } else {
                this.getData(current_page);
            }
        } else if (this.settings.table_data) {
            this.tableData = this.settings.table_data;
            this.search = false;
        }

        this.tableActions = this.settings.actions || [];
        this.tableColumns = this.settings.columns;
        this.prepareSearch();
        this.prepareColumns();
        this.changeDetectorRef.markForCheck();

        if (this.settings.multi_select) {
            this.prepareSelectListeners();
        }
    }

    private prepareColumns(): void {
        this.tableColumnsFiltered = this.tableColumns.filter((col: Table.ICol): boolean => !col.hidden);

        this.table_has_footer = !!this.tableColumnsFiltered.find((col: Table.ICol) => {
            return col.footer;
        });

        this.changeDetectorRef.markForCheck();
    }

    private prepareSearch(): void {
        if (this.settings.search_in) {
            for (const key of Object.keys(this.settings.search_in)) {
                this.searchIn.push({name: key, value: this.settings.search_in[key]});
            }
        } else {
            this.tableColumns.forEach((col: Table.ICol): void => {
                if (col.searchable !== false && col.title !== "") {
                    this.searchIn.push({name: col.title, value: col.name || col.data});
                }
            });
        }

        if (this.searchIn.length) {
            this.searchForm.get("search_in")
                .setValue(this.settings.search_default ? this.settings.search_default : [this.searchIn[0].value]);
        }
        this.changeDetectorRef.markForCheck();

        this.searchForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            this.onSearchChange.emit(value);
        });
    }

    private prepareSelects(rows: any[]): void {
        // (this.rowSelection.get("rows") as FormArray).clear();
        for (const row of rows) {
            (this.rowSelection.get("rows") as FormArray).push(new FormControl(false));
        }
    }

    private prepareSelectListeners(): void {
        this.rowSelection.get("selectAll").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: boolean): void => {
                for (const control of (this.rowSelection.get("rows") as FormArray).controls) {
                    control.setValue(val, {emitEvent: false});
                }
                if (val) {
                    this.onRowsSelected.emit(this.tableData);
                    this.selectedRows = this.tableData;
                } else {
                    this.onRowsSelected.emit([]);
                }
            });
        this.rowSelection.get("rows").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((val: boolean[]): void => {
                this.selectedRows = [];
                for (const i in val) {
                    if (val[i]) {
                        this.selectedRows.push(this.tableData[i]);
                    }
                }

                this.onRowsSelected.emit(this.selectedRows);

            });

    }

    public async getData(page: number = 1, per_page: number = null): Promise<any> {

        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
            this.paginationService.setPerPageById(this.settings.table_id, per_page);
        }

        this.currentPage = page;

        if (page === 1) {
            this.tableData = [];
            this.noMoreData = false;
        } else if (this.noMoreData) {
            return;
        }

        this.loading = true;
        this.changeDetectorRef.markForCheck();

        const service: any = this.settings.api.version === 3 ? this.api3Service : this.apiService;

        this.requestId = HelpersService.randomString();
        this.apiService.setHeaders({"Request-Id": this.requestId});

        if (!this.searchForm.value.search_in?.length && this.searchIn?.length) {
            this.searchForm.get("search_in")
                .setValue(this.settings.search_default ? this.settings.search_default : [this.searchIn[0].value]);
        }

        const response: Api.IResponse = await service.request(Api.EMethod.Get, this.settings.api.url, {}, {
            ...this.searchForm.value,
            ...this.settings.api.query,
            data_structure: this.settings.infinity_scroll
                ? "infinity"
                : this.settings.simple_pagination ? "simple_paginated" : "paginated",
            page,
            per_page,
            sort: this.sort,
        });

        if (response.data) {

            const data = response.data;
            if (response.id && this.requestId !== response.id) {
                return;
            }

            if (this.settings.infinity_scroll) {
                if (data.length < 25) {
                    this.noMoreData = true;
                }
                this.tableData = this.tableData.concat(data);
            } else {
                this.tablePagination = data;
                this.tableData = this.tablePagination.data;
            }

            this.gotData.emit({hasData: this.tableData?.length > 0, page, total: this.tablePagination?.total});

            if (this.settings.multi_select) {
                this.prepareSelects(this.settings.infinity_scroll ? data : data.data);
            }

            this.loading = false;
            this.currentPage++;
            this.changeDetectorRef.markForCheck();


        }
    }

    public async export(type: string): Promise<any> {
        this.spinnerService.show();

        const columns: any = (this.settings.export && this.settings.export.columns
            ? this.settings.export.columns : this.settings.columns)
            .filter((col: Table.ICol) => col.exportable !== false)
            .map((col: Table.ICol): string[] => {
                return [
                    col.name ? col.name : col.data,
                    col.title
                ];
            });

        const service: any = this.settings.api.version === 3 ? this.api3Service : this.apiService;

        const {data, message}: Api.IResponse = await service.request(Api.EMethod.Get, this.settings.api.url,
            {}, {
                export: type,
                ...this.searchForm.value,
                sort: this.sort,
                ...this.settings.api.query,
                file_name:
                    this.settings.export && this.settings.export.file_name ? this.settings.export.file_name : null,
                columns
            });

        this.spinnerService.hide();
        if (data) {
            this.toastService.show(message, "success");
            if (data[0]) {
                window.open(environment.apiUrl + "/export_file/" + data[0], "_blank");
            }
        }
    }

    /**
     * Reverse compatibility method for dataTables
     */
    public reload(api?: Table.ITableApi): void {
        if (api) {
            this.settings.api = api;
        }

        const per_page: number = this.settings.table_id
            ? this.paginationService.getPerPageById(this.settings.table_id) : 10;

        (this.rowSelection.get("rows") as FormArray).clear();
        this.getData(1, per_page);
    }

    public reset(): void {
        this.onReset.emit();
        this.searchForm.reset({
            search_by: null,
            search_in: this.settings.search_in,
            search_like: false
        });
        (this.rowSelection.get("rows") as FormArray).clear();
        this.getData(1);
    }

    public resetSelection(): void {
        this.selectedRows = [];
        this.rowSelection.get("selectAll").setValue(false);
        this.changeDetectorRef.markForCheck();
    }

    public changeSearchMode(strictLike): void {
        this.searchForm.get("search_like").setValue(strictLike);
        this.titleSearchMode = strictLike ? "Loose" : "Strict";
        AmplitudeService.eventClick(strictLike ? "Search loose" : "Search strict");
    }

    public performSearch(search_by: string): void {
        this.searchForm.patchValue({
            "search_by": search_by
        });
        this.getData();
    }

    public changeSort(col: Table.ICol): void {
        if (this.sort.dir === "asc") {
            this.sort.dir = "desc";
        } else {
            this.sort.dir = "asc";
        }
        this.sort.data = col.data;
        this.sort.name = col.name || null;

        if (this.settings.api) {
            this.getData();
        }
    }

    public trustHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    public renderAction(action: Table.IAction, row: any): SafeHtml {
        let cssClass: string = "";
        let icon: string = "";
        let badge: string = "";
        let disabled: boolean = false;

        switch (action.name) {
            case "edit":
                icon = "border_color";
                cssClass = "mat-primary";
                break;
            case "update":
                icon = "update";
                cssClass = "mat-accent";
                break;
            case "history":
                icon = "history";
                cssClass = "mat-accent";
                break;
            case "view":
                icon = "visibility";
                cssClass = "mat-primary";
                break;
            case "select":
            case "child":
                icon = "input";
                cssClass = "mat-primary";
                break;
            case "add":
                icon = "add";
                cssClass = "mat-accent";
                break;
            case "delete":
                icon = "delete";
                cssClass = "mat-warn";
                break;
            case "restore":
                icon = "restore_from_trash";
                cssClass = "mat-warn";
                break;
            case "wizard":
                icon = "arrow_forward";
                cssClass = "mat-primary";
                break;
            case "remarks":
                icon = "speaker_notes";
                cssClass = "mat-primary";
                break;
            case "star":
                icon = "star";
                cssClass = "mat-accent";
                break;
            case "allocate":
                icon = "published_with_changes";
                cssClass = "mat-accent";
                break;
            case "list":
                icon = "list";
                cssClass = "mat-primary";
                break;
            case "print":
                icon = "print";
                cssClass = "mat-accent";
                break;
            case "alarm":
                icon = "alarm";
                cssClass = "mat-accent";
                break;
            case "unlink":
                icon = "link_off";
                cssClass = "mat-warn";
                break;

            case "settings":
                icon = "settings";
                cssClass = "mat-accent";
                break;
            default:
                icon = action.name;
                cssClass = "mat-primary";
                break;
        }

        if (action.icon) {
            icon = action.icon;
        }
        if (action.iconFn) {
            icon = action.iconFn(row);
        }

        if (action.cssClass) {
            cssClass = action.cssClass;
        }
        if (action.cssClassFn) {
            cssClass = action.cssClassFn(row);
        }

        if (action.badgeFn) {
            badge = action.badgeFn(row);
        }

        if (action.disabled) {
            disabled = action.disabled;
        }
        if (action.disabledFn) {
            disabled = action.disabledFn(row);
        }

        if (!action.badgeFn || !badge) {
            return this.trustHtml(`<button type="button"
                    class="mat-mdc-mini-fab mdc-fab ${cssClass} ${disabled ? "disabled" : ""}" title="${action.title}">
                        <mat-icon class="mat-icon material-icons">${icon}</mat-icon></button>`);
        } else {
            return this.trustHtml(`<button type="button"
                    class="mat-mdc-mini-fab mdc-fab ${cssClass} ${disabled ? "disabled" : ""}
                    mat-badge mat-badge-warn mat-badge-overlap mat-badge-above
                    mat-badge-after mat-badge-medium" title="${action.title}">
                        <mat-icon class="mat-icon material-icons">${icon}</mat-icon>
                        <span class="mat-badge-content">
                        ${badge}</span>
            </button>`);
        }
    }

    public getColumnRowCss(col: Table.ICol, row: any): string {
        let cssClass: string = "";

        if (col.cssClass) {
            cssClass = col.cssClass;
        }
        if (col.cssClassFn) {
            cssClass = col.cssClassFn(row);
        }

        return cssClass;
    }

    public checkAction(action: Table.IAction, row: any): boolean {
        if (!action.onOff) {
            return true;
        }
        return action.onOff(row);
    }

    public detectChanges(): void {
        this.changeDetectorRef.detectChanges();
    }

    public renderFooter(column: Table.ICol): SafeHtml {
        if (typeof column.footer === "string") {
            return this.trustHtml(column.footer);
        }
        return this.trustHtml(column.footer());
    }

    public amplitudeClick(message: string, properties: any = {}): void {
        AmplitudeService.eventClick(message + " " + AppStateService.getState().component, properties);
    }

    public multiselect(row: any): boolean {
        if (this.settings.multi_select !== undefined) {
            if (typeof this.settings.multi_select === "boolean") {
                return this.settings.multi_select;
            } else {
                return this.settings.multi_select(row);
            }
        }
        return false;
    }

    public ngOnInit(): void {
        this.init();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.settings.firstChange === false) {
            if (changes.settings.currentValue) {
                this.settings = changes.settings.currentValue;
                this.init();
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();

    }

    public actionClick(row: any, col: Table.IAction): void {
        if (col.disabled || (col.disabledFn && col.disabledFn(row))) {
            return;
        }
        col.click(row);
    }


}
