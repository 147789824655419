import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {PusherService} from "../../../../../../../../common/services/pusher.service";
import {takeUntil} from "rxjs";
import {Router} from "@angular/router";
import {AvailableLocationsModalComponent} from "../../common/available-locations/list.component";
import {ModalComponent} from "../../../../../../../../common/components/modal/modal.component";

@Component({
    selector: "section-warehouse-procedures-wizard-free-inbound-scan",
    templateUrl: "scan.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "scan.component.scss"
    ]
})
export class WarehouseProceduresWizardFreeInboundScanComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private timeout: any;

    private warehouse_slug: string;

    private prevKey: string = null;

    public lastScanValue: string = null;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    public scannerKey: string;

    public partner: User.IPartner;

    public warehouse: Warehouse.IWarehouse;

    public searchLocation: FormControl = new FormControl(null);

    public searchInput: FormControl = new FormControl(null);

    public location: {
        name: string,
        id: number,
        image?: string,
        type_id?: number,
        warehouse_transactions_count?: number;
        hub?: Warehouse.IHub;
    };

    public usedLocations: any[] = [];

    public listTable: Table.ISettings;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirm: ConfirmComponent;

    public scannerOnly: boolean = false;

    public scanFeed: any[] = [];

    public checkedFeedItems: string[] = [];

    public orders: any[] = [];

    public totalScanned: any = {
        pallets: 0,
        boxes: 0,
        items: 0
    };

    public inboundItemsSearch: FormControl = new FormControl(null);

    public showMatchedInList: FormControl = new FormControl(false);

    public showOnlyFailedScans: FormControl = new FormControl(false);

    public selectAll: FormControl = new FormControl(false);

    public scanFeedGrouped: any[] = [];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private pusherService: PusherService,
        private router: Router
    ) {
        super(changeDetectorRef);
    }

    private connectToSockets(): void {

        this.pusherService.echo.private("scan-feed-free-inbound-" + this.warehouse.id)
            .listen(".App\\Events\\WarehouseBufferScanEvent", (data: any): void => {
                console.warn("WarehouseBufferScanEvent", data);

                let update = false;
                for (const index in this.scanFeed) {
                    if (data.feed_id === this.scanFeed[index].feed_id) {
                        this.scanFeed[index] = data.data;
                        update = true;
                        break;
                    }
                }

                if (!update) {
                    this.addScan(data.data);
                }

                this.changeDetectorRef.markForCheck();

            });
    }

    private async getWarehouse(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "findBySlug", this.warehouse_slug], {}, {
                relations: [
                    "address",
                    "contact",
                    "contact.customer",
                    "Threepl",
                    "UlockitLockers.EmptyCells.WarehouseLocation"
                ]
            });
        if (data) {
            this.warehouse = data;
            this.showMatchedInList.setValue(
                this.warehouse.properties.default_show_fully_scanned_items ?? false
            );

            this.connectToSockets();
        }
        this.spinnerService.hide();
    }

    private async getScanBuffer(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/scan/inbound`);

        if (data) {
            this.scanFeed = data;
            this.changeDetectorRef.markForCheck();

            this.countScansTotal();

            this.setFocus();

            this.getOrders().then(() => {
                this.groupFeedData();
            });
        }
    }

    private groupFeedData(): void {

        const grouped: any = {};

        for (let scan of this.scanFeed) {
            if (scan.status !== "success") {
                continue;
            }

            for (const order of this.orders) {
                if (scan.order_id === order.id) {
                    scan.order = order.ref;
                }
            }

            if (!grouped[scan.location_id]) {
                grouped[scan.location_id] = {
                    location: scan.location,
                    pallets: [],
                    pallets_quantity: 0,
                    boxes: [],
                    boxes_quantity: 0,
                    items: [],
                    items_quantity: 0
                };
            }


            switch (scan.type) {
                case "pallet":
                    grouped[scan.location_id].pallets.push(scan);
                    grouped[scan.location_id].pallets_quantity += scan.quantity;
                    break;
                case "box":
                    grouped[scan.location_id].boxes.push(scan);
                    grouped[scan.location_id].boxes_quantity += scan.quantity;
                    break;
                case "serial":
                case "item":
                    grouped[scan.location_id].items.push(scan);
                    grouped[scan.location_id].items_quantity += scan.quantity;
                    break;
            }

        }

        this.scanFeedGrouped = Object.values(grouped);
    }

    private async getOrders(): Promise<any> {
        const ids: number[] = this.scanFeed.map((row: any) => row.order_id);

        if (!ids.length) {
            this.orders = [];
            this.changeDetectorRef.markForCheck();
            return;
        }

        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/scan/inbound/orders`,
            {
                orders: ids,
                relations: [
                    "Partner:id,display_name,icon_path"
                ],
            });

        if (data) {
            this.orders = data;
            for (const order of this.orders) {
                order.scanned = 0;
                order.inbound_order_item_sum_quantity = Number(order.inbound_order_item_sum_quantity);
            }


            for (const scan of this.scanFeed) {
                if (scan.status !== "success") {
                    continue;
                }
                for (const order of this.orders) {
                    if (scan.order_id === order.id) {
                        order.scanned += scan.quantity;
                        order.warehouse_order_id = scan.warehouse_order_id;
                    }
                }
            }


            this.result.emit({
                action: "result",
                value: {
                    orders: this.orders
                },
            });

            this.changeDetectorRef.markForCheck();
        }
    }

    private countScansTotal(): void {

        this.totalScanned = {
            pallets: 0,
            boxes: 0,
            items: 0
        };

        for (const scan of this.scanFeed) {
            if (scan.status !== "success") {
                continue;
            }

            if (scan.type === "pallet") {
                this.totalScanned.pallets++;
            }

            if (scan.type === "box") {
                this.totalScanned.boxes++;
            }

            if (scan.type === "serial") {
                this.totalScanned.items++;
            }
        }


        this.changeDetectorRef.markForCheck();

    }

    /**
     * Find location or box by its ref
     */
    private async findItem(): Promise<any> {

        this.spinnerService.show();

        const feed_id: string = "opn-" + HelpersService.randomString();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Put,
            `/${this.state.section}/scan/inbound`, {
                value: this.searchInput.value,
                location_id: this.location.id,
                feed_id,

            }, {});

        this.spinnerService.hide();

        if (data) {

            switch (data.status) {
                case "error":
                    this.toastService.show(data.message, "error");
                    this.soundsService.textToSpeech(data.message);
                    break;

                case "pallet_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        pallet: this.searchInput.value,
                        status: "pending",
                        quantity: 0,
                        type: "pallet"
                    });
                    break;

                case "box_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        box: this.searchInput.value,
                        status: "pending",
                        location: this.location.name,
                        quantity: 0,
                        type: "box"
                    });
                    break;

                case "serial_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        serial: this.searchInput.value,
                        status: "pending",
                        location: this.location.name,
                        quantity: 1,
                        type: "serial"
                    });
                    break;

                default:
                    break;
            }

        }
        this.searchInput.reset();
        this.setFocus();
        this.changeDetectorRef.markForCheck();

    }

    private addScan(data: any): void {

        data.deletable = true;

        var newArray = this.scanFeed.slice();
        newArray.unshift(data);

        this.scanFeed = newArray;

        this.changeDetectorRef.markForCheck();
    }

    private async getLocationHub(): Promise<any> {
        if (this.location.hub) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "" + this.location.id, "hub"]);
        if (data && data.customers_sub_inventory) {
            this.location.hub = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private setFocus(): void {
        setTimeout(() => {
            if (this.searchInputRef) {
                this.searchInputRef.nativeElement.focus();
            }
        }, 500);
    }

    public async locationList(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(AvailableLocationsModalComponent, {
            modalWidth: 1200,
            state: this.state,
        });

        if (response) {
            if (response.name === "set") {
                response.value.hub = response.value.inventory?.inventory_conversion;
                this.setLocationFromList(response.value);
            } else if (response.name === "add") {
                this.addLocation("");
            }
        }
    }

    /**
     * Find location if none is specified
     */
    public async findLocation(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/${this.state.section}/locations`, {}, {
                search_by: this.searchLocation.value,
                search_in: ["location"],
                search_like: false,
                relations: [
                    "WarehouseLocationType"
                ],
                counts: [
                    "WarehouseTransactionsByInventory"
                ]
            });

        this.spinnerService.hide();

        if (data && data[0]) {
            this.location = {
                name: data[0].location,
                id: data[0].id,
                image: data[0].warehouse_location_type?.icon,
                type_id: data[0].warehouse_location_type_id,
                warehouse_transactions_count: data[0].warehouse_transactions_by_inventory_count
            };

            this.getLocationHub();

            this.soundsService.textToSpeech("Location set");
            this.toastService.show("Location set", "success");

        } else {
            this.soundsService.textToSpeech("Location not found");
            this.toastService.show("Location not found", "warning");
        }

        this.searchLocation.reset();
        this.changeDetectorRef.markForCheck();
        this.setFocus();
    }

    /**
     * Find location or box
     */
    public async find(): Promise<any> {
        if (!this.searchInput.value) {
            this.lastScanValue = null;
            return;
        }

        if (!this.location) {
            this.findLocation();
        } else {
            this.findItem();
        }
    }

    /**
     * Listen scanner activation key event
     * @param event
     */
    public onKeydown(event: any): void {
        if (this.prevKey !== "Control" && this.prevKey !== "Shift") {
            if (event.key === "Enter" || event.key === "Tab") {
                this.find();
            }
        }

        if ((this.prevKey === "Control" || this.prevKey === "Shift" || this.prevKey === "Alt")
            && event.key === "Enter") {
            this.searchInput.setValue(this.searchInput.value + ",");
        }

        this.prevKey = event.key;
    }

    /**
     * Set location from used list
     * @param data
     */
    public setLocationFromList(data: any): void {
        this.location = {
            name: data.location,
            id: data.id,
            image: data.warehouse_location_type?.icon,
            type_id: data.warehouse_location_type_id,
            warehouse_transactions_count: data.warehouse_transactions_by_inventory_count,
            hub: data.hub ? data.hub : null
        };
        this.getLocationHub();

        this.soundsService.textToSpeech("Location set");
        this.toastService.show("Location set", "success");
        this.searchInput.reset();
        this.changeDetectorRef.markForCheck();

        this.setFocus();
    }

    public showScansFromLocation(scans: any[]): void {
        let template: string = ``;
        for (const scan of scans) {
            template += `<div class="margin-bottom-5">`;
            template += `<b>Order:</b> ${scan.order}. `;
            switch (scan.type) {
                case "pallet":
                    template += `<b>Pallet:</b> ${scan.pallet}. <b>Qty:</b> ${scan.quantity}.`;
                    break;
                case "box":
                    template += `<b>Box:</b> ${scan.box}. <b>Qty:</b> ${scan.quantity}.`;
                    break;
                default:
                    template += `<b>Item:</b> ${scan.item}. <b>Qty:</b> ${scan.quantity}.`;
                    if (scan.serial) {
                        template += ` <b>Serial:</b> ${scan.serial}.`;
                    }
            }
            template += `</div>`;
        }

        this.modalService.open(ModalComponent, {
            title: "Scans from location",
            template,
        });
    }

    /**
     * Add new location and select it
     */
    public async addLocation(value: string): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value,
            state: this.state
        });
        if (response && response.value) {
            this.location = {
                name: response.value.location,
                id: response.value.id,
                image: response.value.warehouse_location_type.icon,
                type_id: response.value.warehouse_location_type_id,
                warehouse_transactions_count: response.value.warehouse_transactions_by_inventory_count
            };

            this.soundsService.textToSpeech("Location set");
            this.toastService.show("Location set", "success");

            this.searchInput.reset();
            this.setFocus();
            this.changeDetectorRef.markForCheck();
        }
    }

    public async deleteScans(ids: string[]): Promise<any> {
        if (!await this.confirm.confirm("Delete scanned item(s)?")) {
            return;
        }

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Delete,
            `${this.state.section}/scan/inbound`, {ids});
        this.spinnerService.hide();

        this.checkedFeedItems = [];
        this.getScanBuffer();
    }

    public selectScan(id: string): void {
        if (this.checkedFeedItems.includes(id)) {
            return;
        }
        this.checkedFeedItems.push(id);
    }

    public deSelectScan(id: string): void {
        const index: number = this.checkedFeedItems.indexOf(id);
        this.checkedFeedItems.splice(index, 1);
        this.selectAll.setValue(false, {emitEvent: false});
    }

    public async completeScanning(): Promise<any> {
        const has_invalid = this.scanFeed.find((scan) => scan.status === "error");

        if (has_invalid) {
            if (!await this.confirm.confirm("Make transactions? All invalid scans will be ignored")) {
                return;
            }
        }

        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.state.section}/scan/inbound/convert`, {}, {});
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.result.emit({
                action: "result",
                value: {
                    processScans: true,
                    orders: this.orders
                },
                goToNextStep: true,
            });
        }
    }

    public openOrder(order: any): void {
        this.router.navigate([
            this.state.section,
            "procedures-inbound",
            "wizard",
            "order",
            order.warehouse_order_id,
            "order_ref",
            order.ref,
            "order_id",
            order.id
        ]);
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.scannerKey = data.scannerKey;
        this.location = null;
        this.warehouse_slug = this.state.section_slug;

        this.changeDetectorRef.markForCheck();

        this.getScanBuffer();
        this.getWarehouse();

        this.timeout = setInterval(() => {
            this.getScanBuffer();
        }, 30000);


        this.result.emit({
            action: "result",
            value: true
        });


        this.soundsService.textToSpeech("Please scan location");

        this.selectAll.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean) => {
            for (const feed_item of this.scanFeed) {
                if (value) {
                    if (this.showOnlyFailedScans.value && feed_item.status !== "error") {
                        continue;
                    }
                    this.selectScan(feed_item.feed_id);
                } else {
                    this.deSelectScan(feed_item.feed_id);
                }
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.pusherService.echo.leave("scan-feed-free-inbound-" + this.warehouse_slug);
        clearInterval(this.timeout);
    }

}
