import {Component, ChangeDetectionStrategy, OnInit, ViewChild, ChangeDetectorRef} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {Table2Component} from "src/modules/common/components/table2";
import {Base} from "src/modules/common/interfaces/base.interfaces";
import {Table} from "src/modules/common/interfaces/table.interface";
import {Api} from "src/modules/common/services/api.service";
import {Api3Service} from "src/modules/common/services/api3.service";
import {PartnerService} from "src/modules/common/services/partner.service";
import {Modal, ModalService} from "src/modules/section/services/modal.service";
import {PackagePalletEditComponent} from "../form/form.component";
import {ToastService} from "../../../../../../../common/services/toast.service";

@Component({
    selector: "partner-packages-pallets-list",
    template: `
        <common-confirm></common-confirm>
        <h1 class="container-heading">Pallets List</h1>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div data-actions>
                        <a mat-raised-button color="primary" (click)="addPallet()">
                            Add New
                        </a>
                        <a mat-raised-button color="accent" (click)="upload()">
                            Upload CSV
                        </a>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackagesPalletsListComponent implements OnInit, Base.IComponent {
    public state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public constructor(
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiV3Service: Api3Service,
        private toastService: ToastService,
    ) {

    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        this.listTable = {
            table_id: "partner_packages_pallets_list",
            actions: [
                {
                    name: "delete",
                    title: "",
                    icon: "delete",
                    click: (row: any) => {
                        this.delete(row.id);
                    },
                    disabledFn: (row: any) => {
                        if (!row.boxes.length) {
                            return false;
                        }
                        return row.boxes.findIndex(box =>
                            Number(box.warehouse_transactions_count) > 0
                        ) != -1;
                    },
                },
                {
                    name: "recalculate",
                    title: "Recalculate quantity",
                    icon: "sync",
                    click: (row: any) => {
                        this.apiV3Service.post(`${this.state.section}/pallets/${row.id}/recalculate-inventory`)
                            .then(({code, message}: Api.IResponse): void => {
                                if (code === 200) {
                                    this.toastService.show(message, "success");
                                }
                            });
                    },
                }
            ],
            columns: [
                {
                    "data": "ref",
                    "title": "Ref",
                },
                {
                    "data": "inventory.inventory_conversion.customers_inventory_name",
                    "name": "Inventory.InventoryConversion.customers_inventory_name",
                    "title": "Hub (from Inventory)",
                    "render": (row: any) => {
                        if (row.inventory?.inventory_conversion) {
                            return `
                            ${row.inventory.inventory_conversion.customers_inventory_name}
                            -
                            ${row.inventory.inventory_conversion.customers_sub_inventory}`;
                        } else {
                            return "";
                        }
                    }
                },
                {
                    "data": "warehouse_location.location",
                    "name": "WarehouseLocation.location",
                    "title": "Warehouse Location"
                },
                {
                    "data": "boxes_count",
                    "name": "boxes_count",
                    "title": "Boxes"
                }
            ],
            api: {
                url: ["partner", PartnerService.partner.slug, "pallets"],
                query: {
                    relations: ["WarehouseLocation", "Boxes", "Inventory.InventoryConversion"],
                    counts: [
                        "Boxes",
                    ],
                },
                version: 3
            },
            export: {
                file_name: "Partner-Packages-Pallets-List",
            }
        };

        this.changeDetectorRef.markForCheck();
    }

    public async addPallet(): Promise<void> {
        const response: Modal.IResponse = await this.modalService.open(PackagePalletEditComponent, {
            add: true
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    public upload(): void {
        this.router.navigate([
            this.state.section,
            "csv-import",
            "wizard",
            "type",
            "pallet",
            "back_to",
            btoa(this.router.url)
        ]);
    }

    public async delete(id: number): Promise<void> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this Pallet?")) {
            return;
        }
        const response = await this.apiV3Service.request(Api.EMethod.Delete,
            `partner/${PartnerService.partner.slug}/pallets/${id}`
        );
        if (response) {
            this.tableRef.reload();
        }
    }

    ngOnInit(): void {
        this.prepareList();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "packages-pallets-list",
            actions: {
                "browse": ["browse_part_masters"]
            }
        };
    }
}