import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import { SpinnerService } from "src/modules/common/services/spinner.service";
import { PartMasterService } from "src/modules/common/services/part-master.service";

@Component({
    selector: "part-master-min-quantity-form",
    templateUrl: "min-quantity.component.html",
    styleUrls: [
        "min-quantity.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartMasterMinQuantityFormComponent implements OnDestroy, OnInit {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public minQuantityForm: FormGroup = new FormGroup({
        minQuantityArray: new FormArray([])
    });

    @Input()
    public item: any;

    public data: any[] = [];

    @Output()
    public onSave: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onDelete: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public onCancel: EventEmitter<void> = new EventEmitter<void>();

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private partMasterService: PartMasterService,
    ) {
    }

    private async prepareForm(): Promise<void> {
        this.minQuantityArray.clear();
        if (this.data?.length) {
            const grp = this.data.reduce((acc, item) => {
                if (!acc[item.quantity]) {
                  acc[item.quantity] = [];
                }
                acc[item.quantity].push(item);
                return acc;
              }, []);
            grp.forEach(minQtyData => {
                const hubsIds = minQtyData.map(item => {
                    return item.inventory_conversion_id;
                });
                const hubMinQuantityFormGroup = new FormGroup({
                    quantity: new FormControl(minQtyData[0].quantity ?? null, [Validators.required]),
                    inventory_conversion_ids: new FormControl(hubsIds, [Validators.required]),
                });
                this.minQuantityArray.push(hubMinQuantityFormGroup);
            });
        } else {
            this.addHubMinQuantity();
        }
        this.changeDetectorRef.markForCheck();
    }

    private async getData(): Promise<void> {
        this.spinnerService.show();
        const response = await this.partMasterService.getPartMasterMinQuantity(this.item.id);
        if (response?.data) {
            this.data = response.data;
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    public get minQuantityArray(): FormArray {
        return this.minQuantityForm.get("minQuantityArray") as FormArray;
    }    
    
    public addHubMinQuantity(): void {
        const hubMinQuantityFormGroup = new FormGroup({
            quantity: new FormControl(null, [Validators.required]),
            inventory_conversion_ids: new FormControl([], [Validators.required]),
        });
        this.minQuantityArray.push(hubMinQuantityFormGroup);
    }

    public async handleSaveHubMinQuantity(): Promise<void> {
        if (this.minQuantityArray.invalid) {
            return;
        }
        this.spinnerService.show();
        await this.partMasterService.createPartMasterMinQty(
            this.item.id, this.minQuantityArray.value
        );
        this.spinnerService.hide();
        this.minQuantityArray.markAsPristine();
        this.changeDetectorRef.markForCheck();
    }

    public async handleRemoveHubMinQuantity(index: number): Promise<void> {
        if (this.minQuantityArray.invalid) {
            return;
        }
        this.minQuantityArray.removeAt(index);

        if (!this.minQuantityArray.length) {
            if (await this.confirmRef.confirm(
                "You remove latest data. Do you want save empty quantity data for part master?"
            )) {
                await this.handleSaveHubMinQuantity();
            }
            this.addHubMinQuantity();
        }

        this.changeDetectorRef.markForCheck();
    }

    public async ngOnInit(): Promise<void> {
        await this.getData();
        this.prepareForm();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
