import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {UserService} from "../../../../../../common/services/user.service";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {LocationTypeViewComponent} from "../view/view.component";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {FormControl} from "@angular/forms";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: "section-location-type-list",
    template: `
        <common-confirm></common-confirm>

        <mat-card>
            <mat-card-content>
                <common-table2 *ngIf="listTable" [settings]="listTable">
                    <div row1>
                        <a mat-raised-button color="primary" (click)="openForm()">
                            Add New
                        </a>
                        <mat-checkbox [formControl]="only_active">Only active</mat-checkbox>
                    </div>
                </common-table2>
            </mat-card-content>
        </mat-card>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationTypeListComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public state: Base.IState;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public listTable: Table.ISettings;

    public only_active: FormControl = new FormControl(true);

    /* Table options */

    public constructor(
        private router: Router,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private userService: UserService,
        private modalService: ModalService,
        private spinnerService: SpinnerService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
    }

    private getUrl(): Table.ITableApi {
        return {
            url: [
                this.state.section,
                "locations-types"
            ],
            query: {
                only_active: this.only_active.value,
                relations: [
                    "Warehouse:id,name"
                ]
            },
            version: 3
        };
    }

    private prepareList(): void {
        this.listTable = {
            actions: [
                {
                    name: "edit",
                    title: "Edit",
                    click: (row: any): void => {
                        this.openForm(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return !row.warehouse_id && this.state.section !== "admin";
                    }
                },
                {
                    name: "delete",
                    title: "Delete",
                    click: (row: any): void => {
                        this.deleteType(row);
                    },
                    disabledFn: (row: any): boolean => {
                        return !row.warehouse_id && this.state.section !== "admin";
                    }
                },
            ],
            columns: [
                {
                    data: "name",
                    title: "Name"
                },
                {
                    data: "warehouse.name",
                    name: "Warehouse.name",
                    title: "Warehouse",
                    hidden: this.state.section !== "admin",
                    exportable: this.state.section === "admin"
                },
                {
                    data: "is_active",
                    title: "Active",
                    render: (row: any): string => {
                        return row.is_active ? "Yes" : "No";
                    }
                },
                {
                    data: "created_at",
                    title: "Created"
                },
                {
                    data: "updated_at",
                    title: "Updated"
                }
            ],
            api: this.getUrl()

        };
        this.changeDetectorRef.markForCheck();
    }

    private async deleteType(package_type: { id: number }): Promise<any> {
        if (!await this.confirmRef.confirm("Delete location type?")) {
            return;
        }
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.api3Service.delete(
            `${this.state.section}/locations-types/${package_type.id}`
        );
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.tableRef.reload(this.getUrl());
        }
    }

    public async openForm(data: any = null): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(LocationTypeViewComponent, {
            data,
            state: this.state
        });

        if (response) {
            this.tableRef.reload(this.getUrl());
        }
    }

    public ngOnInit(): void {
        this.prepareList();

        this.only_active.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.tableRef.reload(this.getUrl());
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
