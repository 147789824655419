import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../common/interfaces/wizard.interface";
import {Api, ApiService} from "../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../common/services/helpers.service";
import {Modal, ModalService} from "../../../../../../services/modal.service";
import {SpinnerService} from "../../../../../../../common/services/spinner.service";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Api3Service} from "../../../../../../../common/services/api3.service";
import {Form} from "../../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {Base} from "../../../../../../../common/interfaces/base.interfaces";
import {AppStateService} from "../../../../../../../common/services/app-state.service";
import {WarehouseLocationFormComponent} from "src/modules/section/components/threepl/warehouse/locations";
import { Warehouse } from "src/modules/common/interfaces/warehouse.interface";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "section-warehouse-procedures-wizard-transfer-update-pallet",
    templateUrl: "update-pallet.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PartnerProceduresWizardTransferUpdatePalletComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    @ViewChild("newLocationName", {static: false})
    public newLocationNameInput: ElementRef;

    @ViewChild(MatAutocompleteTrigger, {static: false})
    public autocompleteTrigger: MatAutocompleteTrigger;

    public locations: ISelectOption[] = [];

    public subs: ISelectOption[] = [];

    public pallets: any[] = [];
    public palletsSelect: ISelectOption[] = [];

    public showLocations: boolean = false;

    public locationSearchInput: FormControl = new FormControl(null, [Validators.required]);

    public transferForm: FormGroup = new FormGroup({
        pallet_id: new FormControl(null, [Validators.required]),
    });

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    private async getWarehousesLocations(palletId: number): Promise<any> {
        this.spinnerService.show();

        const pallet = this.pallets.find(p => {
            return p.id === palletId;
        });
        const warehouse_id = pallet.warehouse_location.warehouse_id;

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/warehouses/${warehouse_id}/locations`, {}, {
                type: "pallet",
                only_empty: true,
                data_structure: "select"
            });
        this.spinnerService.hide();

        if (data) {
            this.locations = data;

            if (this.locations.length === 1) {
                this.transferForm.get("warehouse_location_id").setValue(this.locations[0].value);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getSubWarehouses(palletId: number): Promise<any> {
        this.spinnerService.show();

        const pallet = this.pallets.find(p => {
            return p.id === palletId;
        });
        const warehouse_slug = pallet.warehouse_location.warehouse.slug;

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
                `${this.state.section}/hubs`, {}, {
                    warehouse_slug,
                    relations: ["SubWarehouse"]
                });
        if (data) {
            this.subs = data.map((sw: any) => {
                return {
                    value: sw.sub_warehouse.id,
                    name: sw.customers_sub_inventory
                };
            });
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async getPallets(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/pallets`, {}, {
                relations: ["WarehouseLocation.Warehouse"],
                available_only: true
            });

        this.spinnerService.hide();

        if (data) {
            this.pallets = data;
            this.palletsSelect = this.pallets.map(p => {
                return {
                    name: p.ref,
                    value: p.id
                };
            });
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Put,
            `${this.state.section}/pallets/${this.transferForm.value.pallet_id}`, this.transferForm.value);
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.transferForm.reset();
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Add new location and select it
     */
    public async addLocation(value: string): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value
        });
        if (response) {
            await this.getWarehousesLocations(this.transferForm.get("pallet_id").value);
            this.locationSearchInput.setValue(response.value);
            this.transferForm.get("warehouse_location_id")?.setValue(response.value.id);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = AppStateService.getState();

        if (data.scanType === "pallet-hub") {
            this.transferForm.addControl("sub_inventory_id",
                new FormControl(null, [Validators.required]));
        } else if (data.scanType === "pallet-location") {
            this.transferForm.addControl("warehouse_location_id",
                new FormControl(null, [Validators.required]));
            this.showLocations = true;
        }
        this.transferForm.updateValueAndValidity();

        this.getPallets();

        this.transferForm.get("pallet_id").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((palletId: number): void => {
                if (!palletId) {
                    return;
                }

                if (data.scanType === "pallet-hub") {
                    this.getSubWarehouses(palletId);
                } else if (data.scanType === "pallet-location") {  
                    this.getWarehousesLocations(palletId);
                }
            });

        this.result.emit({
            action: "result",
            value: true
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
