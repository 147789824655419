<form *ngIf="formGroup" [formGroup]="formGroup" (submit)="submit()">

    <div class="group flex">

        <mat-form-field class="full">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" required>
        </mat-form-field>

        <div class="full" *ngIf="formGroup.get('description')">
            <common-form-editor label="Description"
                                [value]="editorInitialValue"
                                (valueChange)="formGroup.get('description').setValue($event)"
            ></common-form-editor>
        </div>

        <div class="full">
            <button type="button" mat-raised-button class="full margin-bottom-10" (click)="uploadFile()">
                Upload a file
            </button>
        </div>

        <div class="full">
            <ng-template ngFor let-file let-index="index" [ngForOf]="uploadedFile">
                <div class="attachment"
                     [matTooltip]="file.name"
                     [ngStyle]="{ 'background-image': (file.url.includes('.jpeg') ? 'url('+file.url+')' : '/assets/images/logo-semi-transparent.png') }">
                    <mat-icon class="delete" matTooltip="Delete" (click)="deleteFile(index)" color="warn">
                        delete
                    </mat-icon>
                    <div class="title">
                        {{file.name}}
                    </div>
                </div>
            </ng-template>
        </div>


        <mat-form-field class="half">
            <mat-label>Assignee</mat-label>
<mat-select formControlName="assignee_id"  required>
                <mat-option>
                    <ngx-mat-select-search [formControl]="selectUserSearch" [placeholderLabel]="'Search'"
                                           [clearSearchInput]="true"
                                           [noEntriesFoundLabel]="'No items found'">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let user of usersFiltered | async" [value]="user.value">
                    {{user.name}}
                </mat-option>
            </mat-select>
            <span matPrefix><mat-icon>person</mat-icon>&nbsp;</span>
        </mat-form-field>

        <mat-form-field class="half">
            <mat-label>Who can see</mat-label>
                <mat-select formControlName="order_remark_type_id"
                        required>
                <mat-option *ngFor="let option of remarkTypes" [value]="option.id">
                    {{option.name}}
                </mat-option>
            </mat-select>
            <span matPrefix><mat-icon>remove_red_eyes</mat-icon>&nbsp;</span>
        </mat-form-field>

        <div class="full">
            <small><a class="pointer" (click)="assignToMe()">+ Assign to me</a></small>
            <br>
            <br>
        </div>

        <mat-form-field class="half">
            <mat-label>Participants</mat-label>
            <mat-select formControlName="participants" [multiple]="true">
                <mat-option>
                    <ngx-mat-select-search [formControl]="selectParticipantSearch" [placeholderLabel]="'Search'"
                                           [clearSearchInput]="true"
                                           [noEntriesFoundLabel]="'No items found'">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option [disabled]="true" [selected]="true" [value]="currentUser.id">
                    {{ currentUser.name }} - {{ currentUser.email }}
                </mat-option>
                <mat-option *ngFor="let user of participantsFiltered" [value]="user.value">
                    {{user.name}}
                </mat-option>
            </mat-select>
            <span matPrefix><mat-icon>person</mat-icon>&nbsp;</span>
        </mat-form-field>

        <div class="half"></div>

        <div class="third" *ngIf="teams.length">
            <common-form-select
                    label="Assigned team"
                    [options]="teams"
                    [value]="formGroup.value.team_id"
                    [search]="true"
                    [required]="false"
                    (valueChange)="formGroup.get('team_id').setValue($event)"></common-form-select>
        </div>

        <mat-form-field class="third">
            <mat-label>Kind</mat-label>
<mat-select formControlName="request_kind_id"  required>
                <mat-option *ngFor="let kind of kinds" [value]="kind.value">
                    {{kind.name}}
                </mat-option>
            </mat-select>
            <span matPrefix><mat-icon>ballot</mat-icon>&nbsp;</span>
        </mat-form-field>

        <mat-form-field class="third">
            <mat-label>Priority</mat-label>
<mat-select formControlName="priority"  required>
                <mat-option value="Low">Low</mat-option>
                <mat-option value="Normal">Normal</mat-option>
                <mat-option value="High">High</mat-option>
                <mat-option value="Critical">Critical</mat-option>
            </mat-select>
            <span matPrefix><mat-icon>low_priority</mat-icon>&nbsp;</span>
        </mat-form-field>

        <ng-template [ngIf]="formType === 'request'">
            <div class="third" *ngIf="teams.length">
                <mat-checkbox formControlName="inform_team">Inform team</mat-checkbox>
                <br>
                <br>
            </div>
            <div class="half" *ngIf="showOrderLink">
                <mat-checkbox [checked]="true" [disabled]="true">Link to this order</mat-checkbox>
                <br>
                <br>
            </div>
            <div class="full"></div>

            <div class="half">
                <common-form-date
                        label="Timeline from"
                        [value]="formGroup.value.timeline_from"
                        [min_date]="minFromDate"
                        (valueChangeMoment)="formGroup.get('timeline_from').setValue($event)">
                </common-form-date>
            </div>

            <div class="half">
                <common-form-date
                        label="Timeline to"
                        [value]="formGroup.value.timeline_to"
                        [min_date]="minTODate"
                        (valueChangeMoment)="formGroup.get('timeline_to').setValue($event)">
                </common-form-date>
            </div>

            <mat-form-field class="half" *ngIf="showRelatedPartners">
                <mat-label>Related partner</mat-label>
                <mat-select formControlName="related_partner"  required>
                    <mat-option *ngFor="let partner of partners" [value]="partner.value">
                        {{partner.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-template>

        <mat-form-field class="half" *ngIf="showRelatedPartners">
            <mat-label>Related partner</mat-label>
            <mat-select formControlName="related_partner"  required>
                <mat-option *ngFor="let partner of partners" [value]="partner.value">
                    {{partner.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>


    <div class="actions flex row space-between">
        <button mat-raised-button color="primary" type="submit" class="main" [disabled]="!formGroup.valid">
            Submit
        </button>
        &nbsp;
        &nbsp;
        <div>
            <button mat-button type="button" (click)="close()">Cancel</button>
            <button mat-button type="button" (click)="minimize()">Minimize</button>
        </div>

    </div>

</form>