import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {PackageFormComponent, PackageStickerComponent} from "../../../../package";
import {ConfirmComponent} from "src/modules/common/components/confirm/confirm.component";
import {ViewChild} from "@angular/core";


@Component({
    selector: "section-warehouse-order-wizard-check-packages",
    templateUrl: "check-packages.component.html",
    styleUrls: [
        "check-packages.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})

export class WarehouseProceduresWizardCheckPackagesComponent extends AbstractWizardStepComponent implements OnDestroy {

    private orderRef: string;

    private transactions_count: number = 0;

    public state: Base.IState;

    public shipment: Warehouse.IShipment;

    public packages: any[] = [];

    public isViewOnly = false;

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private modalService: ModalService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get package list
     */
    private async getPackages(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_package", "shipment", "" + this.shipment.id]);
        if (data) {
            this.packages = data;
            this.changeDetectorRef.markForCheck();
            this.checkPackages();
        }
        this.spinnerService.hide();
    }

    private async getTransactions(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order", "id", "" + this.shipment.order_id, "transactions", "outbound"], {}, {
                data_structure: "count"
            });
        this.spinnerService.hide();
        if (data && data[0]) {
            this.transactions_count = data[0];
            this.checkPackages();
        }
    }

    private checkPackages(): void {
        let good: boolean = true;
        if (!this.packages || !this.packages.length || !this.transactions_count) {
            good = false;
        } else {
            for (const pack of this.packages) {
                if (!pack.length || !pack.width || !pack.height || !pack.weight) {
                    good = false;
                }
            }
        }

        this.result.emit({
            action: "result",
            value: good ? this.shipment : null
        });
    }

    /**
     * Edit selected package
     * @param pack
     */
    public async edit(pack: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent, {
            package: pack,
            shipment: this.shipment,
            require_dimensions: true
        });

        if (response) {
            this.getPackages();
        }
    }


    /**
     * Delete selected package
     * @param pack
     */
    public async delete(pack: any): Promise<any> {
        if (!await this.confirmRef.confirm("Are you sure want to delete this package?")) {
            return;
        }
        this.spinnerService.show();
        const {code, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Delete,
            ["warehouse_package", "" + pack.id]);
        this.spinnerService.hide();
        if (code === 200) {
            this.toastService.show(message, "success");
            this.getPackages();
        }
    }


    /**
     * Print box sticker
     * @param pack
     */
    public async printSticker(pack: any = null): Promise<any> {
        await this.modalService.open(PackageStickerComponent, {
            type: this.state.params.type,
            pack,
            shipment: this.shipment,
            orderRef: this.orderRef,
            packages: this.packages
        });
    }

    /**
     * Initialize details step
     * @returns {Promise<any>}
     * @param data
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.shipment = data.shipment;
        this.orderRef = data.orderRef;
        this.isViewOnly = data.isViewOnly;
        this.getPackages();
        this.getTransactions();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
