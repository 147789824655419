import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {FormControl, FormGroup} from "@angular/forms";
import {takeUntil} from "rxjs/operators";
import * as moment from "moment-timezone";
import {Moment} from "moment-timezone";
import {StorageService} from "../../../../../../common/services/storage.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../common/components/table2";
import {UserService} from "../../../../../../common/services/user.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {ToastService} from "../../../../../../common/services/toast.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import ISelectOption = Form.ISelectOption;


@Component({
    selector: "section-followups-list",
    templateUrl: "list.component.html",
    styleUrls: ["list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class FollowupsListComponent implements OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @Input()
    public only_my: boolean = false;

    public tiles: boolean = false;

    @Input()
    public favorite: boolean = false;

    @Input()
    public showFilters: boolean = true;

    @Input()
    public state: Base.IState;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    public showStatusSelectAll: boolean = false;
    public showKindSelectAll: boolean = false;
    public showPartnerSelectAll: boolean = false;


    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.IOptions}
     */
    public listTable: Table.ISettings;

    public showExportButton: boolean = false;

    public filters: FormGroup = new FormGroup({
        status: new FormControl([]),
        kind: new FormControl([]),
        sort_by: new FormControl(this.storageService.get("requests_sortby", "timeline_to")),
        only_open: new FormControl(this.storageService.get("requests_only_open", true)),
        partner_id: new FormControl([]),
    });

    public kinds: any[] = [];

    public partners: ISelectOption[] = [];

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private storageService: StorageService,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private toastService: ToastService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    private getUrl(): any {
        return {
            url: ["request"],
            query: {
                type: "followup",
                only_my: this.only_my,
                only_favorite: this.favorite,
                order_id: this.state.params.order || null,
                with_count: [
                    "OrderRemarks"
                ],
                ...this.filters.value
            }
        };
    }

    private getColumns(): Table.ICol[] {
        return [
            {
                data: "order.ref",
                name: "Order.ref",
                title: "Order",
                render: (row: any): string => {
                    if (row.order) {
                        return `<span class="title pointer">
                                ${row.order.ref}
                            </span>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.order_id && row.partner) {
                        window.open("/partner/" + row.partner.slug + "/orders/view/id/" + row.order_id, "_blank");
                    }
                },
                sortable: false
            },
            {
                data: "order.status.name",
                name: "Order.Status.name",
                title: "Order status",
                sortable: false
            },
            {
                data: "order.ref",
                title: "",
                render: (row: any): string => {
                    if (row.id) {
                        return `<mat-icon class="mat-icon material-icons pointer" title="Open in new tab">
                            open_in_new
                            </mat-icon>`;
                    }
                    return "";
                },
                click: (row: any): void => {
                    if (row.id) {
                        window.open("/" + this.state.section + "/followups/view/id/" + row.id, "_blank");
                    }
                },
                cssClass: "action",
                sortable: false,
                searchable: false,
                exportable: false
            },
            {
                data: "urgent_request_task.text",
                name: "UrgentRequestTask.text",
                title: "Current task",
                render: (row: any): string => {
                    if (!row.urgent_request_task) {
                        return "";
                    }
                    return `<div class="multicol"><div title="${row.urgent_request_task.text}"
                            class=" title currentTaskColumn">${row.urgent_request_task.text}</div></div>`;
                },
                sortable: false,
            },
            {
                data: "order.order_items.inventory_conversion.warehouse.three_pl.display_name",
                name: "Order.OrderItems.InventoryConversion.Warehouse.ThreePl.display_name",
                title: "ThreePl",
                sortable: false,
                render: (row: any): string => {
                    if (!row.order.order_items || row.order.order_items.length == 0) {
                        return "";
                    }

                    return row.order.order_items.map((item: any) => {
                        return item.inventory_conversion.warehouse.three_pl.display_name;
                    })
                        .filter((value, index, array) => {
                            return array.indexOf(value) === index;
                        })
                        .join(", ");
                },
            },
            {
                data: "title",
                title: "Title",
                render: (row: any): string => {
                    let unreads: string = "";
                    if (Number(row.unread_activities)) {
                        unreads = `
                            <span class="unreads" title="Unread activities">
                                 <mat-icon class="mat-icon material-icons">
                                        notification_important
                                 </mat-icon>
                                 ${row.unread_activitie.length}
                            </span>`;
                    }

                    return `
                        <div class="multicol">
                            <div class="pointer">
                                ${unreads}
                                ${row.title}
                            </div>
                            <small class="text-disabled">
                                ${row.request_latest_message
                        ? row.request_latest_message.message
                            .replace(/<[^>]*>?/gm, " ").trim().substring(0, 90) : ""}
                            </small>
                            <div>
                                <small class="text-disabled">
                                    <mat-icon class="mat-icon material-icons vertical-middle">
                                        low_priority
                                    </mat-icon>
                                   <b class="${["High", "Critical"].indexOf(row.priority) > -1
                        ? "mat-warn" : ""}">
                                    ${row.priority}
                                    </b>
                                   &emsp;
                                    <mat-icon class="mat-icon material-icons vertical-middle">
                                        message
                                    </mat-icon>
                                    ${row.request_messages_count}
                                    &nbsp;
                                    <mat-icon class="mat-icon material-icons vertical-middle">
                                        priority_high
                                    </mat-icon>
                                    ${row.request_tasks_incomplete_count}/${row.request_tasks_count}
                                </small>
                            </div>
                        </div>`;
                },
                click: (row: any): void => {
                    this.router.navigate([
                        this.state.section,
                        "followups",
                        "view",
                        "id",
                        row.id
                    ]);
                }
            },
            {
                data: "timeline_to",
                title: "Due date",
                render: (row: any): string => {
                    return this.buildTimeline(row);
                },
                sortable: false,
                searchable: false
            },
            {
                data: "status",
                title: "Status",
                render: (row: any): string => {
                    return "<div class='status " + row.status + "'>" + row.status + "</div>";
                }
            },
            {
                data: "request_kind.name",
                name: "RequestKind.name",
                title: "Kind",
                sortable: true
            },
            {
                data: "order.main_address.country",
                name: "Order.MainAddress.country",
                title: "Country",
                render: (row: any): string => {
                    if (row.order && row.order.main_address && row.order.main_address.country_iso_2) {
                        return "<img class='flag' src='assets/images/flags/"
                            + row.order.main_address.country_iso_2.toLowerCase().trim() + ".svg' "
                            + "alt='" + row.order.main_address.country + "'>";
                    } else {
                        return "";
                    }
                },
                sortable: false
            },
            {
                data: "order.order_item.inventory_conversion.customers_inventory_name",
                name: "Order.OrderItem.InventoryConversion.customers_inventory_name",
                title: "Warehouse",
                sortable: false,
                render: (row: any): string => {
                    if (!row.order.order_items || row.order.order_items.length == 0) {
                        return "";
                    }

                    return row.order.order_items.map((item: any) => {
                        return item.inventory_conversion.customers_inventory_name;
                    })
                        .filter((value, index, array) => {
                            return array.indexOf(value) === index;
                        })
                        .join(", ");
                },
            },
            {
                data: "order.courier_service.service_name",
                name: "Order.CourierService.service_name",
                title: "Courier service",
                sortable: false
            },
            {
                data: "order.outbound_shipment.estimated_delivery_date",
                name: "Order.OutboundShipment.estimated_delivery_date",
                title: "ETA",
                sortable: false
            },
            {
                data: "request_last_activity.user.name",
                name: "RequestLastActivity.User.name",
                title: "Updated by",
                render: (row: any): string => {
                    if (row.request_last_activity && row.request_last_activity.user) {
                        return `<div class='avatar'
                    style='background-image: url(${row.request_last_activity.user.avatar
                            ? row.request_last_activity.user.avatar
                            : "/assets/images/default-avatar.png"}'></div>
                        ${row.request_last_activity.user.name}`;
                    } else {
                        return "";
                    }
                },
                sortable: false
            },
            {
                data: "request_last_activity.created_at",
                name: "RequestLastActivity.created_at",
                title: "Update date",
                render: (row: any): string => {
                    if (row.request_last_activity && row.request_last_activity.created_at) {
                        return row.request_last_activity.created_at;
                    } else {
                        return "";
                    }
                },
            },
            {
                data: "created_at",
                name: "created_at",
                title: "Creation date",
                render: (row: any): string => {
                    return row.created_at.split(" ")[0];
                },
            },
            {
                data: "creator.name",
                name: "Creator.name",
                title: "Created by",
                render: (row: any): string => {
                    if (row.creator) {
                        return `<div class='avatar' style='background-image: url(${row.creator.avatar
                            ? row.creator.avatar : "/assets/images/default-avatar.png"}'></div> ${row.creator.name}`;
                    } else {
                        return "";
                    }

                },
                sortable: false
            },
        ];
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {

        const columns: Table.ICol[] = this.getColumns();

        if (this.state.section === "admin") {
            columns.splice(1, 0, {
                data: "partner.icon_path",
                title: "Partner",
                render: (row: any): string => {
                    return row.partner.icon_path ? "<img src='" + row.partner.icon_path + "' alt=''>" : "";
                },
                exportable: false
            });
            columns.splice(1, 0, {
                data: "partner.full_name",
                title: "Partner",
                hidden: true
            });
        }

        this.listTable = {
            table_id: "3R5ox9tN",
            api: this.getUrl(),
            actions: [
                {
                    name: "wizard",
                    title: "View",
                    click: (row: any): void => {
                        this.viewFollowup(row);
                    }
                },
                {
                    name: "star",
                    title: "Add to favorites",
                    click: async (row: any): Promise<any> => {
                        this.spinnerService.show();
                        const {message, type}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                            ["request", "" + row.id, "favorite"]);
                        this.spinnerService.hide();

                        if (type as string === "success") {
                            this.toastService.show(message, "success");
                            this.tableRef.reload();
                        }
                        AmplitudeService.eventClick("Favorite button");
                    },
                    cssClassFn: (row: any): string => {
                        return row.fav_user ? "mat-accent" : "";
                    }
                },
                {
                    name: "remarks",
                    title: "Remarks",
                    click: async (row: any): Promise<any> => {
                        this.viewFollowup(row);
                    },
                    cssClassFn: (row: any): string => {
                        return row.order_remarks_count ? "mat-accent" : "";
                    },
                    badgeFn: (row: any): string => {
                        return row.order_remarks_count > 0 ? row.order_remarks_count : null;
                    }
                }
            ],
            per_page: 50,
            columns,
            export: {
                file_name: "followups list"
            }

        };
        this.changeDetectorRef.markForCheck();
    }

    private async loadKinds(): Promise<any> {
        const response: any = await this.apiService.request(Api.EMethod.Get,
            this.apiService.getUrl(["request", "kind"]), {}, {
                category: "followup"
            });
        response.data.forEach((value: any): void => {
            this.kinds.push(value.name);
        });
    }

    private async getPartners(): Promise<any> {
        this.partners = this.userService.data.partners.map((partner: User.IPartner): any => {
            return {value: partner.id, name: partner.display_name};
        });
    }

    private prepareListeners(): void {
        this.filters.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: any): void => {
            if (!this.tiles) {
                this.tableRef.reload(this.getUrl());
            }
            this.storageService.set("followups_filters", value);
        });

        this.filters.get("sort_by").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string): void => {
            this.storageService.set("requests_sort_by", value);
        });

        this.filters.get("only_open").valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean): void => {
            this.storageService.set("requests_only_open", value);
        });
    }

    public buildTimeline(data: any): string {
        if (data.timeline_to) {
            const from: Moment = moment.tz(data.created_at, "YYYY-MM-DD HH-mm-ss", "Asia/Jerusalem")
                .startOf("day");
            const to: Moment = moment.tz(data.timeline_to, "YYYY-MM-DD HH-mm-ss", "Asia/Jerusalem");
            const now: Moment = moment.tz("Asia/Jerusalem");
            const diff100: number = to.diff(from, "hours");
            const diffNow: number = now.diff(from, "hours");
            let progress: number = diffNow / (diff100 / 100);
            progress = progress > 0 ? Math.ceil(progress) : 0;
            progress = progress < 100 ? progress : 100;

            const left: number = to.diff(now, "hours");
            const leftMinutes: number = to.diff(now, "minutes");
            let daysLeft: number = 0;
            let hoursLeft: number = 0;
            let minutesLeft: number = 0;
            let cssClass: string = "red";

            if (leftMinutes > 0) {
                daysLeft = Math.floor(left / 24);
                hoursLeft = left - daysLeft * 24;
                minutesLeft = Math.floor(leftMinutes - hoursLeft * 60 - daysLeft * 60 * 24);
                if (leftMinutes > 60) {
                    cssClass = "green";
                } else if (leftMinutes > 30) {
                    cssClass = "yellow";
                }
            }

            return `
                    <div class="timeline ${cssClass}">
                        <div class="progress" style="width:${progress}%"></div>
                        <div class="dates">
                          ${to.format("MMM D HH:mm")}
                        </div>
                        <div class="distance">
                            ${daysLeft}d ${hoursLeft}h ${minutesLeft}m left
                        </div>
                    </div>
                `;
        } else {
            return `
                     <div class="timeline">
                     <div class="dates">
                            -
                        </div>
                        <div class="distance">
                            -
                        </div>
                    </div>
                `;
        }
    }

    public selectAll(form: string): void {
        if (form === "showStatus") {
            if (!this.showStatusSelectAll) {
                this.filters.get("status").setValue(
                    ["New", "Open", "Pending", "On-hold", "Solved", "Closed", "In-progress"]);
            } else {
                this.filters.get("status").setValue([]);
            }
            this.showStatusSelectAll = !this.showStatusSelectAll;
        } else if (form === "showKind") {
            if (!this.showKindSelectAll) {
                this.filters.get("kind").setValue(this.kinds);
            } else {
                this.filters.get("kind").setValue([]);
            }
            this.showKindSelectAll = !this.showKindSelectAll;
        } else if (form === "showPartner") {
            if (!this.showPartnerSelectAll) {
                this.filters.get("partner_id").setValue(this.partners.map(item => item.value));
            } else {
                this.filters.get("partner_id").setValue([]);
            }
            this.showPartnerSelectAll = !this.showPartnerSelectAll;
        }
    }

    public viewFollowup(data: any): void {
        this.router.navigate([
            this.state.section,
            "followups",
            "view",
            "id",
            data.id
        ]);
    }

    public switchTemplate(tiles: boolean): void {
        this.tiles = tiles;
        this.storageService.set("followups_template_tiles", tiles);
        this.changeDetectorRef.markForCheck();
        if (!tiles && !this.listTable) {
            this.prepareList();
        }
    }

    public goToActivities(): void {
        this.router.navigate([
            this.state.section,
            "activities"
        ]);
        AmplitudeService.eventClick(this.only_my ?
            "Recent activities (my followups)" :
            "Recent activities (all followups)");
    }

    public async export(type: string = "csv"): Promise<any> {
        this.spinnerService.show();

        const columns: any = this.getColumns().map((col: Table.ICol): string[] => {
            return [
                col.data,
                col.title
            ];
        });

        const {data, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["request"],
            {}, {
                export: type,
                columns,
                ...this.filters.value,
                type: "followup",
                only_my: this.only_my,
                only_favorite: this.favorite,
                order_id: this.state.params.order || null,
            });

        this.spinnerService.hide();
        if (data) {
            this.toastService.show(message, "success");
        }
    }

    public ngOnInit(): void {

        this.loadKinds();

        this.tiles = this.storageService.get("followups_template_tiles", this.tiles);
        const preSavedFilters: any = this.storageService.get("followups_filters", null);
        if (preSavedFilters) {
            this.filters.patchValue(preSavedFilters);
        }
        this.changeDetectorRef.markForCheck();
        if (!this.tiles) {
            this.prepareList();
        }

        if (this.state.section === "admin") {
            this.getPartners();
        }
        this.prepareListeners();

    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
