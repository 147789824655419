import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {COMMA, ENTER, SEMICOLON, TAB} from "@angular/cdk/keycodes";
import {ReplaySubject} from "rxjs";
import {MatChipInputEvent} from "@angular/material/chips";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";

@Component({
    selector: "section-warehouse-procedures-wizard-transfer-update-box",
    templateUrl: "update-box.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardTransferUpdateBoxComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    @ViewChild("locationCountInput", {static: false})
    public locationCountInput: ElementRef;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    @ViewChild("newLocationName", {static: false})
    public newLocationNameInput: ElementRef;

    @ViewChild(MatAutocompleteTrigger, {static: false})
    public autocompleteTrigger: MatAutocompleteTrigger;

    public warehouse: Warehouse.IWarehouse;

    public separatorKeysCodes: any[] = [ENTER, COMMA, SEMICOLON, TAB];

    public subs: any[] = [];

    public locations: any[] = [];
    public locations_filtered: ReplaySubject<any> = new ReplaySubject(1);

    public boxes: string[] = [];

    public locationSearchInput: FormControl = new FormControl(null, [Validators.required]);

    public partners: any[] = [];

    public partnerSelect: FormControl = new FormControl();

    public transferForm: FormGroup = new FormGroup({
        boxes: new FormControl([], [Validators.required]),
        partner_id: new FormControl(null, [Validators.required]),
        sub_inventory: new FormControl(null, [Validators.required]),
        warehouse_location_id: new FormControl(null, [Validators.required])
    });

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get all related sub-warehouses
     */
    private async getPartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "partners"]);
        if (data) {
            this.partners = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Get all related sub-warehouses
     */
    private async getSubWarehouses(partner: string): Promise<any> {
        this.spinnerService.show();

        this.apiService.setHeaders({Partner: partner});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "sub"]);
        if (data) {
            this.subs = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }


    /**
     * Get all related locations
     */
    private async getWarehousesLocations(search?: string): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse_location"], {}, {
            search_by: search ? search : null,
            search_in: ["location"],
            no_relations: true
        });
        if (data) {
            this.locations = data;
            this.locations_filtered.next(this.locations);
            if (this.locations.length === 1) {
                this.locations_filtered.subscribe((item) => {
                    if (item[0]) {
                        this.transferForm.get("warehouse_location_id").setValue(item[0].id);
                        this.locationSearchInput.setValue(item[0]);
                        this.autocompleteTrigger.closePanel();
                    }
                });
            }

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Add boxes to chips
     * @param event
     */
    public async addBoxes(event: MatChipInputEvent): Promise<any> {
        this.spinnerService.show();
        const value: string = event.value.trim();

        if (event.input) {
            event.input.value = "";
            this.changeDetectorRef.markForCheck();
        }

        if (value) {
            const boxes: string[] = value.split(/[\s|\t|;|,]/);
            const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Post,
                ["inventory", "boxes"], {
                    boxes,
                    has_location: 1,
                    has_allocations: 0
                });

            if (data) {
                data.forEach((box: { ref: string }): void => {
                    this.boxes.push(box.ref);
                });
                this.transferForm.get("boxes").setValue(this.boxes);
            }
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Remove box chip
     * @param box
     */
    public removeBox(box: string): void {
        const index: number = this.boxes.indexOf(box);
        if (index >= 0) {
            this.boxes.splice(index, 1);
            this.transferForm.get("boxes").setValue(this.boxes);
        }
        this.changeDetectorRef.markForCheck();
    }


    /**
     * Submit form
     */
    public async submit(): Promise<any> {
        if (this.locationSearchInput.value) {
            this.transferForm.get("warehouse_location_id").setValue(this.locationSearchInput.value.id);
        }
        this.spinnerService.show();
        const {type, message}: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
            ["inventory", "boxes"], this.transferForm.value);
        if (type as string === "success") {
            this.toastService.show(message, "success");
            this.boxes = [];
            this.subs = [];
            this.partnerSelect.reset();
            this.locationSearchInput.reset();
            this.transferForm.reset();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Add new location and select it
     */
    public async addLocation(value: string): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value,
            state: this.state
        });
        if (response) {
            await this.getWarehousesLocations();
            this.locationSearchInput.setValue(response.value);
            this.transferForm.get("warehouse_location_id").setValue(response.value.id);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {

        this.state = data.state;
        this.warehouse = data.warehouse;

        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartners();
        this.getWarehousesLocations();

        this.partnerSelect.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: User.IPartner): void => {
                if (value) {
                    this.transferForm.get("partner_id").setValue(value.id);
                    if (["box-hub", "box-location"].includes(data.scanType)) {
                        this.getSubWarehouses(value.slug);
                    }
                }
            });

        this.locationSearchInput.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: any): void => {
                if (value && typeof (value) === "string") {
                    this.getWarehousesLocations(value);
                } else {
                    if (value !== undefined) {
                        this.transferForm.get("warehouse_location_id").setValue(value?.id);
                    }
                }
            });
    }

    displayFn(option: any): string {
        let displayOption: string;
        if (option && option.location) {
            displayOption = option.location;
        } else {
            displayOption = "";
        }
        return displayOption;
    }

    /**
     * Redirect to order on dropdown selected option
     * @param {MatAutocompleteSelectedEvent} $event
     */
    public onOptionSelected($event: MatAutocompleteSelectedEvent): void {
        if ($event.option.value) {
            this.transferForm.get("warehouse_location_id").setValue($event.option.value.id);
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
