import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {Router} from "@angular/router";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {Contact} from "../../../../../../common/interfaces/contact.interface";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {debounceTime, map, startWith, takeUntil} from "rxjs/operators";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {PackageFormComponent} from "../../../../threepl/warehouse/package";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {SoundsService} from "../../../../../../common/services/sounds.service";
import {AppStateService} from "../../../../../../common/services/app-state.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Observable, ReplaySubject} from "rxjs";
import {ENTER, TAB} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import IAddress = Contact.IAddress;
import IContact = Contact.IContact;
import {AlertComponent} from "../../../../../../common/components/alert/alert.component";
import ISelectOption = Form.ISelectOption;


@Component({
    selector: "section-order-label-wizard",
    templateUrl: "wizard.component.html",
    styleUrls: [
        "wizard.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class OrderLabelWizardComponent implements Base.IComponent, OnInit, OnDestroy {

    protected destroy$: EventEmitter<boolean> = new EventEmitter(false);

    @ViewChild(ConfirmComponent, {static: true})
    public confirmRef: ConfirmComponent;

    @ViewChild(AlertComponent, {static: true})
    public alertRef: AlertComponent;

    public data: any;

    public state: Base.IState;

    public shipment: Warehouse.IShipment;

    public parcels: Warehouse.IParcel[] = [];

    public packages: Warehouse.IPackage[];

    public selected_packages: number[] = [];

    public needs_customs_info: boolean = false;

    public countries: Contact.ICountry[] = [];

    public get customs_info(): FormArray {
        return this.formGroup.controls["customs_info"] as FormArray<FormGroup>;
    }

    public get fromAddress(): FormGroup {
        return this.formGroup.controls["from_address"] as FormGroup;
    }

    public get toAddress(): FormGroup {
        return this.formGroup.controls["to_address"] as FormGroup;
    }

    private floatRegEx = /([0-9]+\.[0-9]{2})/;

    private carriers: string[] = [];

    private customer_account: string = null;

    private couriers_icons: { [slug: string]: string } = {};

    private raw_rates: any;

    private rates: {
        carrier: string,
        carrier_label: string,
        rate: string,
        service: string,
        currency: string,
        delivery_days: number,
        icon_url: string,
        // has_return_label: boolean,
        carrier_id: string,
        rate_id: string,
    }[] = [];

    private rates_with_errors: {
        message: string,
        icon_url: string,
        carrier: string,
        carrier_label: string
    }[] = [];


    public can_get_rates: boolean = false;

    public get_rates_in_progress: boolean = false;

    public formGroup: FormGroup = new FormGroup({
        from_address: new FormGroup({
            address_id: new FormControl(null),
            name: new FormControl(null, [Validators.required, Validators.maxLength(40)]),
            company: new FormControl(null, [Validators.required, Validators.maxLength(35)]),
            street1: new FormControl(null, [Validators.required, Validators.maxLength(35)]),
            street2: new FormControl(null, [Validators.maxLength(35)]),
            city: new FormControl(null, [Validators.required]),
            state: new FormControl(null),
            zip: new FormControl(null, [Validators.required]),
            country: new FormControl(null, [Validators.required, Validators.maxLength(2)]),
            phone: new FormControl(null, [
                Validators.required,
                Validators.pattern("\\+?[\-0-9\\s\(\)]+"),
                Validators.maxLength(20)
            ]),
            email: new FormControl(null, [
                Validators.required,
                Validators.pattern("[\\w\\-\\.]+@([\\w\\-]+\\.)+[\\w\\-]{2,}"),
            ]),
            in_eu: new FormControl(null)
        }),
        to_address: new FormGroup({
            address_id: new FormControl(null),
            name: new FormControl(null, [Validators.required, Validators.maxLength(40)]),
            company: new FormControl(null, [Validators.required, Validators.maxLength(35)]),
            street1: new FormControl(null, [Validators.required, Validators.maxLength(35)]),
            street2: new FormControl(null, [Validators.maxLength(35)]),
            city: new FormControl(null, [Validators.required]),
            state: new FormControl(null),
            zip: new FormControl(null, [Validators.required]),
            country: new FormControl(null, [Validators.required, Validators.maxLength(2)]),
            phone: new FormControl(null, [
                Validators.required,
                Validators.pattern("\\+?[\-0-9\\s\(\)]+"),
                Validators.maxLength(20)
            ]),
            email: new FormControl(null, [
                Validators.required,
                Validators.pattern("[\\w\\-\\.]+@([\\w\\-]+\\.)+[\\w\\-]{2,}"),
            ]),
            in_eu: new FormControl(null)
        }),

        packages: new FormControl([], [Validators.required]),
        shipment_id: new FormControl(null, [Validators.required]),

        rate_id: new FormControl(null, [Validators.required]),
        carrier_id: new FormControl(null, [Validators.required]),
        carrier_name: new FormControl(null, [Validators.required]),
        carrier_service: new FormControl(null, [Validators.required]),
        delivery_days: new FormControl(null),

        incoterm: new FormControl("DAP", [Validators.required]),
        bill_account_number: new FormControl({value: null, disabled: true}),

        total_value: new FormControl(null, [
            Validators.required, Validators.min(0), Validators.pattern(this.floatRegEx)
        ]),

        total_value_currency: new FormControl("USD", [Validators.required]),
        content_description: new FormControl("Electronic parts", [Validators.required]),
        note: new FormControl(null),
        is_return: new FormControl(false),
        send_to: new FormControl([]),
        customs_info: new FormArray([]),
        add_insurance: new FormControl(false),
        add_as_remark: new FormControl(false),
        carrier_options: new FormControl([]),
        ref: new FormControl(null, [Validators.required])
    });

    public service_level: FormControl = new FormControl(null);

    public print_return_label: FormControl = new FormControl(false);

    // public rates_to_list: ReplaySubject<any> = new ReplaySubject<any>(1);

    public grouped_rates_to_list: ReplaySubject<any> = new ReplaySubject<any>(1);

    public no_rates_to_list: boolean = true;

    public courier_service_name: string = null;

    public customer_account_courier_company: string = null;

    public service_level_name: string = null;

    public showHiddenRates: FormControl = new FormControl(false);

    @Output()
    public result: EventEmitter<any> = new EventEmitter();

    @ViewChild("userInput")
    public userInput: ElementRef<HTMLInputElement>;

    public users: User.IData[] = [];

    public separatorKeysCodes: number[] = [ENTER, TAB];
    public userCtrl = new FormControl("");
    public filteredUsers: Observable<User.IData[]>;

    public carrier_options_fields_list: Form.IField[] = null;

    @ViewChild("inputFile", {static: false})
    public inputFile: ElementRef;

    public uploading: boolean = false;

    public selected_file_name: string = null;

    public selected_file_data: string = null;

    public allow_upload_document: boolean = true;

    public upload_document_type: FormControl = new FormControl(5, [Validators.required]);

    public currencies: ISelectOption[] = [];

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private api3Service: Api3Service,
        private soundsService: SoundsService,
        private apiService: ApiService,
        private modalService: ModalService,
        private helpers: HelpersService,
    ) {
    }

    private getAddressForm(
        address: any,
        fill_gaps_address: IAddress = null,
        fill_gaps_contact: IContact = null,
        company_fill: string = null
    ): any {

        let company: string = null;
        if (address.customer) {
            company = address.customer.name;
        } else if (company_fill) {
            company = company_fill;
        }


        let country: string = null;
        let in_eu: boolean = false;

        if (address.country_iso_2) {
            country = address.country_iso_2;
        } else if (address.country) {
            country = address.country;
        } else if (fill_gaps_address.country_iso_2) {
            country = fill_gaps_address.country_iso_2;
        } else if (fill_gaps_address.country) {
            country = fill_gaps_address.country;
        }

        if (address.country_detailed) {
            in_eu = !!address.country_detailed.in_eu;
        } else if (fill_gaps_address.country_detailed) {
            in_eu = !!fill_gaps_address.country_detailed.in_eu;
        }


        let name: string = null;
        let phone: string = null;
        let email: string = null;

        if (address.contacts && address.contacts.length) {

            const last_contact: Contact.IContact = address.contacts[address.contacts.length - 1];

            name = last_contact.first_name + (last_contact.last_name ? " " + last_contact.last_name : "");
            phone = last_contact.phone;
            email = last_contact.email;

        } else if (fill_gaps_contact) {
            name = fill_gaps_contact.first_name
                + (fill_gaps_contact.last_name ? " " + fill_gaps_contact.last_name : "");
            phone = fill_gaps_contact.phone;
            email = fill_gaps_contact.email;
        }

        return {
            address_id: address.id,
            name,
            company,
            street1: address.address,
            street2: address.address2,
            city: address.city,
            state: address.state,
            zip: address.zip,
            country,
            phone,
            email,
            in_eu
        };
    }

    /**
     * Get order shipments
     * @returns {Promise<any>}
     */
    private async getShipment(): Promise<any> {
        this.spinnerService.show();

        let url = `${this.state.section}/shipments/`;

        if (this.state.params?.sibling) {
            url += `${this.state.params.sibling}/sibling/inbound/`;
        }
        url += this.state.params.shipment;

        const {data}: Api.IResponse = await this.api3Service.get(url,
            {
                relations: [
                    "FromAddress.contacts.customer",
                    "FromAddress.CountryDetailed",
                    "FromAddress.customer",
                    "ToAddress.contacts.customer",
                    "ToAddress.CountryDetailed",
                    "ToAddress.customer",
                    "Warehouse.address",
                    "Warehouse.address.CountryDetailed",
                    "Warehouse.contact",
                    "Warehouse.Threepl",
                    "Order.CustomerAccount:id,name",
                    "Order.MainContact",
                    "Order.MainAddress.customer",
                    "Order.ServiceLevel.orderType",
                    "OrderItems.PartMaster.CreateLabelContentNotes",
                    "Partner",
                    "SiblingShipments.Warehouse.address",
                    "SiblingShipments.Warehouse.address.CountryDetailed",
                    "SiblingShipments.Warehouse.contact",
                    "SiblingShipments.Warehouse.Threepl"
                ],

            });
        if (data) {
            this.shipment = data;

            this.formGroup.get("shipment_id").setValue(this.shipment.id);
            this.formGroup.get("ref").setValue(this.shipment.order.ref);

            const sibling: Warehouse.IShipment = data.sibling_shipments.find((sibling): boolean => {
                return sibling.type !== data.type
                    && data.address_to === sibling.address_to
                    && data.address_from === sibling.address_from;
            });

            if (data.from_address) {

                let fill_from_gaps_address: any = null;
                let fill_from_gaps_contact: any = null;
                let fill_from_company: string = null;

                if (this.shipment.type === "outbound") {
                    fill_from_gaps_address = this.shipment.warehouse?.address;
                    fill_from_gaps_contact = this.shipment.warehouse?.contact;
                    fill_from_company = this.shipment.warehouse?.threepl?.display_name;
                } else {
                    if (this.shipment.order.service_level.order_type.slug === "transfer" && sibling) {
                        fill_from_gaps_address = sibling.warehouse?.address;
                        fill_from_gaps_contact = sibling.warehouse?.contact;
                        fill_from_company = sibling.warehouse?.threepl?.display_name;
                    } else {
                        fill_from_gaps_address = this.shipment.order?.main_address;
                        fill_from_gaps_contact = this.shipment.order?.main_contact;
                        fill_from_company = this.shipment.order?.main_address?.customer?.name;
                    }
                }

                this.formGroup.get("from_address").patchValue(this.getAddressForm(this.shipment.from_address,
                    fill_from_gaps_address, fill_from_gaps_contact, fill_from_company));

            }

            if (data.to_address) {

                let fill_to_gaps_address: any = null;
                let fill_to_gaps_contact: any = null;
                let fill_to_company: string = null;

                if (this.shipment.type === "inbound") {
                    fill_to_gaps_address = this.shipment.warehouse?.address;
                    fill_to_gaps_contact = this.shipment.warehouse?.contact;
                    fill_to_company = this.shipment.warehouse?.threepl?.display_name;
                } else {
                    if (this.shipment.order.service_level.order_type.slug === "transfer" && sibling) {
                        fill_to_gaps_address = sibling.warehouse?.address;
                        fill_to_gaps_contact = sibling.warehouse?.contact;
                        fill_to_company = sibling.warehouse?.threepl?.display_name;
                    } else {
                        fill_to_gaps_address = this.shipment.order?.main_address;
                        fill_to_gaps_contact = this.shipment.order?.main_contact;
                        fill_to_company = this.shipment.order?.main_address?.customer?.name;
                    }
                }

                this.formGroup.get("to_address").patchValue(this.getAddressForm(this.shipment.to_address,
                    fill_to_gaps_address, fill_to_gaps_contact, fill_to_company));
            }

            await this.overrideAddress(data);

            let total_value = 0;
            for (const item of this.shipment.order_items) {
                total_value += (item.price ?? item.part_master.customs_value ?? 0) * item.quantity;
            }

            this.formGroup.get("total_value").setValue(total_value);

            if (data.order.customer_account_id) {
                this.customer_account = data.order.customer_account.name;
            }

            if (this.shipment.partner?.properties?.default_label_incoterm) {
                this.formGroup.get("incoterm").setValue(this.shipment.partner?.properties?.default_label_incoterm);
            }

            if (this.shipment.order_items.length > 0
                && this.shipment.order_items[0].part_master?.create_label_content_notes.length > 0) {
                this.formGroup.get("content_description").setValue(
                    this.shipment.order_items[0].part_master?.create_label_content_notes[0].content);
            }

            this.checkIfCanUploadDocument();
            this.checkIfCanGetRates();
        }
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    private checkIfCanUploadDocument(): void {
        switch (true) {
            case this.formGroup.value.from_address?.country.toLowerCase() == "us"
            && this.formGroup.value.to_address?.country.toLowerCase() == "br":
                this.allow_upload_document = false;
                break;
            default:
                this.allow_upload_document = true;
                break;
        }

        this.changeDetectorRef.markForCheck();
    }

    private async overrideAddress(shipment_data: any): Promise<any> {
        if (shipment_data.from_address.id) {
            const {data}: Api.IResponse = await this.api3Service.post(
                `${this.state.section}/shipping/address-overrides`, {
                    condition_address_id: shipment_data.from_address.id,
                    condition_partner_id: shipment_data.partner_id
                });

            if (data) {
                this.formGroup.get("from_address").patchValue(data);
            }
        }

        if (shipment_data.to_address.id) {
            const {data}: Api.IResponse = await this.api3Service.post(
                `${this.state.section}/shipping/address-overrides`, {
                    condition_address_id: shipment_data.to_address.id,
                    condition_partner_id: shipment_data.partner_id
                });

            if (data) {
                this.formGroup.get("to_address").patchValue(data);
            }
        }


        if (shipment_data.warehouse
            && shipment_data.partner_id === 18
            && shipment_data.warehouse["3pl_id"] === 35
            && shipment_data.from_address.country_iso_2 === "NL"
            && shipment_data.type === "inbound") {
            const {data}: Api.IResponse = await this.api3Service.post(
                `${this.state.section}/shipping/address-overrides`, {
                    condition_partner_id: 18,
                    condition_3pl_id: 35,
                    condition_country_iso_2: "NL"
                });

            if (data) {
                this.formGroup.get("from_address").patchValue(data);
            }
        }
    }

    private async getPackages(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_package", "shipment", "" + this.shipment.id]);
        this.spinnerService.hide();
        if (data) {
            this.packages = data;
            if (this.packages.length === 0) {
                this.newPackage();
                this.soundsService.textToSpeech("Please create package");
            }

            this.selected_packages = this.packages.map((p: Warehouse.IPackage) => p.id);

            this.formGroup.get("packages").setValue(this.selected_packages);
        }

        this.checkIfCanGetRates();
        this.changeDetectorRef.markForCheck();
    }

    private async getCountries(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["address", "country"]);
        this.spinnerService.hide();

        if (data) {
            this.countries = data;
        }
    }

    private async getCurrencies(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/currencies`, {
            data_structure: "select",
            shipment_id: this.shipment.id
        });
        this.spinnerService.hide();

        if (data) {
            this.currencies = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private findCountry(code: string): Contact.ICountry {
        return this.countries.find((country: Contact.ICountry): boolean => {
            return country.country_iso_2.toLowerCase() === code.toLowerCase()
                || country.country_name.toLowerCase() === code.toLowerCase();
        });
    }

    private setCustomsData(): void {
        this.customs_info.clear();
        this.changeDetectorRef.markForCheck();

        const from: Contact.ICountry = this.findCountry(this.fromAddress.value.country);
        const to: Contact.ICountry = this.findCountry(this.toAddress.value.country);

        this.needs_customs_info =
            this.fromAddress.value.country?.toLowerCase() !== this.toAddress.value.country?.toLowerCase()
            && !(from?.in_eu && to?.in_eu);

        if (!this.needs_customs_info) {
            return;
        }

        const ids: number[] = [];

        this.shipment.order_items.map((item: Order.IItem) => {
            if (ids.includes(item.part_master_id)) {
                return;
            }
            ids.push(item.part_master_id);

            const qty_sum: number = this.shipment.order_items.reduce((accum, itm: Order.IItem) => {
                return accum + (itm.part_master_id === item.part_master_id ? itm.quantity : 0);
            }, 0);

            this.customs_info.push(new FormGroup({
                Description: new FormControl(item.part_master.description),
                Weight: new FormControl(item.part_master.weight),
                Quantity: new FormControl(qty_sum),
                UnitValue: new FormControl(item.part_master.customs_value),
                OriginCountry: new FormControl(item.part_master.country),
                Currency: new FormControl(item.part_master?.customs_values_currency?.toUpperCase()),
                HSCode: new FormControl(item.part_master.HSC)
            }));
        });
    }

    public deleteItem(index: number): void {
        this.customs_info.removeAt(index);
    }

    public addItem(): void {
        this.customs_info.push(new FormGroup({
            Description: new FormControl(null),
            Weight: new FormControl(null),
            Quantity: new FormControl(1),
            UnitValue: new FormControl(null),
            OriginCountry: new FormControl(null),
            Currency: new FormControl("USD"),
            HSCode: new FormControl(null)
        }));

        this.changeDetectorRef.markForCheck();
    }

    public async newPackage(): Promise<any> {

        let pack: any = null;
        if (this.shipment.order_items.length === 1) {
            pack = {};

            pack.length = this.shipment.order_items[0].part_master.length;
            pack.width = this.shipment.order_items[0].part_master.width;
            pack.height = this.shipment.order_items[0].part_master.height;
            pack.weight = this.shipment.order_items[0].part_master.weight;
        }

        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent,
            {
                package: pack,
                shipmentId: this.shipment.id,
                shipment: this.shipment,
                warehouse: this.shipment.warehouse,
                require_dimensions: true,
                warehouse_id: this.shipment.warehouse?.id,
                warehouse_slug: this.shipment.warehouse_slug
            });

        if (response) {
            this.getPackages();
        }
    }

    public async editPackage(pack: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PackageFormComponent, {
            package: pack,
            shipment: this.shipment,
            shipmentId: this.shipment.id,
            warehouse: this.shipment.warehouse,
            warehouse_id: this.shipment.warehouse?.id,
            warehouse_slug: this.shipment.warehouse_slug
        });

        if (response) {
            this.getPackages();
        }
    }

    public onCheckChange(event: MatCheckboxChange): void {
        const value: number = Number(event.source.value);
        const index = this.selected_packages.indexOf(value);

        if (event.checked && index === -1) {
            this.selected_packages.push(value);
        } else if (!event.checked && index > -1) {
            this.selected_packages.splice(index, 1);
        }

        this.formGroup.get("packages").setValue(this.selected_packages);

        this.checkIfCanGetRates();
    }

    private getCarriersData(): void {
        this.carriers = this.carriers.filter((value: string, index: number, self: string[]): boolean => {
            return self.indexOf(value) === index;
        });
        const hasNew: string[] = [];
        for (const carrier of this.carriers) {
            const slug: string = carrier.replace(/\s/g, "_").toLowerCase();
            if (!this.couriers_icons.hasOwnProperty(slug)) {
                hasNew.push(slug);
            }
        }

        this.fulfillRatesWithCourierInfo();

        if (!hasNew.length) {
            return;
        } else {
            for (const newbie of hasNew) {
                this.couriers_icons[newbie] = null;
            }
        }

        this.apiService.request(Api.EMethod.Get, ["courier", "find-by-slug"], {}, {
            couriers: this.carriers,
            slug_field: "shippy_pro_slug"
        })
            .then(({data}: Api.IResponse): void => {
                for (const c of data) {
                    this.couriers_icons[c.shippy_pro_slug] = c.icon_path;
                }
                this.fulfillRatesWithCourierInfo();
            });
    }

    private fulfillRatesWithCourierInfo(): void {
        for (const rate of this.rates) {
            if (this.couriers_icons.hasOwnProperty(rate.carrier)) {
                rate.icon_url = this.couriers_icons[rate.carrier];
            }
        }

        for (const e_rate of this.rates_with_errors) {
            if (this.couriers_icons.hasOwnProperty(e_rate.carrier)) {
                e_rate.icon_url = this.couriers_icons[e_rate.carrier];
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    private filterRates(): void {

        this.rates = [];
        this.rates_with_errors = [];

        this.carriers = [];

        for (const row of this.raw_rates.Rates) {

            const rate: any = {
                carrier: row.carrier,
                rate: row.rate,
                carrier_label: row.carrier_label,
                service: row.service,
                currency: row.currency,
                delivery_days: row.delivery_days,
                // has_return_label: false,
                icon_url: "/assets/images/logo-semi-transparent.png",
                carrier_id: row.carrier_id,
                rate_id: row.rate_id,
            };

            this.carriers.push(row.carrier);

            this.rates.push(rate);
        }

        this.rates_with_errors = [];

        for (const error of this.raw_rates.RatesErrors) {

            if (error.error_message.includes("Service is not allowed") && !this.showHiddenRates.value) {
                continue;
            }

            if (error.error_message === "Invalid Credentials" && !this.showHiddenRates.value) {
                continue;
            }

            this.rates_with_errors.push({
                message: error.error_message,
                icon_url: null,
                carrier: error.carrier,
                carrier_label: error.carrier_label
            });
        }

        this.no_rates_to_list = !this.rates.length && !this.rates_with_errors.length;

        this.changeDetectorRef.markForCheck();

        this.getCarriersData();
        this.groupRates();
    }

    private groupRates(): void {
        const rate_groups: any = {};

        for (const rate of this.rates) {
            if (!rate_groups.hasOwnProperty(rate.carrier_label)) {
                rate_groups[rate.carrier_label] = {
                    group_name: rate.carrier_label,
                    rates: []
                };
            }
            rate_groups[rate.carrier_label].rates.push(rate);
        }

        for (const rate of this.rates_with_errors) {
            if (!rate_groups.hasOwnProperty(rate.carrier_label)) {
                rate_groups[rate.carrier_label] = {
                    group_name: rate.carrier_label,
                    rates: []
                };
            }
            rate_groups[rate.carrier_label].rates.push(rate);
        }

        this.grouped_rates_to_list.next(Object.values(rate_groups));
    }

    private async getUsers(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.get(`${this.state.section}/users`);

        if (data) {
            this.users = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    private _filter(value: string): User.IData[] {
        const filterValue = value.toLowerCase();

        return this.users.filter(user => user.email.toLowerCase().includes(filterValue)
            || user.name.toLowerCase().includes(filterValue)
        );
    }

    private async checkAddress(): Promise<any> {
        // const {data, code}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
        //     `${this.state.section}/shipping/check-address`, this.formGroup.value);
        //
        //
        // if (code === 200) {
        //
        //     console.log(data);
        // }
    }

    public addEmail(event: MatChipInputEvent): void {
        const value = (event.value || "").trim();

        if (value && HelpersService.isEmail(value)) {
            const emails: string[] = this.formGroup.value.send_to;
            emails.push(value);
            this.formGroup.get("send_to").setValue(emails);
        }

        event.chipInput!.clear();
        this.userCtrl.setValue(null);
    }

    public removeEmail(email: string): void {
        const index = this.formGroup.value.send_to.indexOf(email);

        if (index >= 0) {
            const emails: string[] = this.formGroup.value.send_to;
            emails.splice(index, 1);
            this.formGroup.get("send_to").setValue(emails);
        }
    }

    public userSelected(event: MatAutocompleteSelectedEvent): void {
        const emails: string[] = this.formGroup.value.send_to;
        emails.push(event.option.value);
        this.formGroup.get("send_to").setValue(emails);
        this.userInput.nativeElement.value = "";
        this.userCtrl.setValue(null);
    }

    public async getRates(params: any): Promise<any> {

        if (!this.can_get_rates) {
            return;
        }

        this.can_get_rates = false;
        this.get_rates_in_progress = true;
        this.service_level.setValue(null);
        this.rates = [];
        this.rates_with_errors = [];
        this.carrier_options_fields_list = null;

        this.changeDetectorRef.markForCheck();

        if (this.state.params?.sibling) {
            params.sibling = this.state.params.sibling;
        }

        const {data, code}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.state.section}/shipping/get-rates`, params);

        this.get_rates_in_progress = false;
        this.checkIfCanGetRates();

        if (code === 200) {

            this.raw_rates = data;

            this.filterRates();
        }
    }

    public number(val: string): number {
        return parseInt(val);
    }

    private async getCarrierOptions(): Promise<any> {
        this.spinnerService.show();

        this.carrier_options_fields_list = null;
        this.changeDetectorRef.markForCheck();

        const {data}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/shipping/get-carrier-options`, this.formGroup.value);

        this.spinnerService.hide();

        if (data) {
            this.carrier_options_fields_list = data.form;
        } else {
            this.carrier_options_fields_list = [];
        }
        this.changeDetectorRef.markForCheck();
    }

    private removeDragData(event: any): void {
        if (event.dataTransfer.items) {
            event.dataTransfer.items.clear();
        } else {
            event.dataTransfer.clearData();
        }
    }

    private onFileSelect(file: File): void {

        this.selected_file_name = file.name;
        this.changeDetectorRef.markForCheck();

        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event: any): void => {
            this.selected_file_data = event.target.result.replace("data:application/pdf;base64,", "");
            this.changeDetectorRef.markForCheck();
        };

        reader.onerror = (event: any) => {
            console.warn("File could not be read: " + event.target.error.code);
        };
    }

    private checkIfCanGetRates(): void {
        this.can_get_rates = !!(this.fromAddress.valid && this.toAddress.valid
            && this.formGroup.value.total_value && this.formGroup.value.packages.length);
        this.changeDetectorRef.markForCheck();
    }

    public selectCarrier(value: any): void {
        if (value.message) {
            return;
        }

        this.formGroup.get("carrier_name").setValue(value ? value.carrier : null);
        this.formGroup.get("carrier_service").setValue(value ? value.service : null);
        this.formGroup.get("delivery_days").setValue(value ? value.delivery_days : null);
        this.formGroup.get("rate_id").setValue(value ? value.rate_id : null);
        this.formGroup.get("carrier_id").setValue(value ? value.carrier_id : null);

        this.getUsers();

        if (value) {
            this.checkAddress();
            this.getCarrierOptions();
        }
    }

    public setCarrierOptions(values: any): void {

        const result = [];

        for (const name of Object.keys(values)) {
            if (values[name] === true) {
                result.push({
                    name: name,
                    value: "1"
                });
            } else if (values[name] && typeof values[name] === "object") {
                for (const sub_name of Object.keys(values[name])) {
                    if (values[name][sub_name] === true) {
                        result.push({
                            name: sub_name,
                            value: "1"
                        });
                    } else if (values[name][sub_name]) {
                        result.push({
                            name: sub_name,
                            value: values[name][sub_name]
                        });
                    }
                }
            } else if (values[name] !== null && values[name] !== false) {
                result.push({
                    name: name,
                    value: values[name]
                });
            }
        }

        this.formGroup.get("carrier_options").setValue(result);
    }

    public chooseFile(): void {
        this.inputFile.nativeElement.click();
    }

    public fileChange(event: any): void {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.onFileSelect(fileList[0]);
        }
    }

    public dragOver(event: any): void {
        event.preventDefault();
    }

    public fileDrop(event: any): void {
        event.preventDefault();
        if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
            if (event.dataTransfer.items[0].kind === "file") {
                this.onFileSelect(event.dataTransfer.items[0].getAsFile());
            }
        } else if (event.dataTransfer.files.length > 0) {
            this.onFileSelect(event.dataTransfer.files[0]);
        }
        this.removeDragData(event);
    }

    public async submitFile(): Promise<any> {
        this.uploading = true;

        const {message, code}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/shipping/upload-document`, {
                document_b64: this.selected_file_data,
                document_type: this.upload_document_type.value,
                ...this.formGroup.value
            }
        );

        this.uploading = false;
        if (code === 200) {
            this.toastService.show(message, "success");
        }

        this.selected_file_name = null;
        this.selected_file_data = null;
        this.inputFile.nativeElement.value = null;
        this.changeDetectorRef.markForCheck();
    }

    public async saveAddressOverride(form_data: any): Promise<any> {
        const {code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/shipping/address-overrides/${form_data.address_id}`, {
                ...form_data
            });

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    /**
     * Finish wizart and redirect back to order
     */
    public async finish(): Promise<any> {
        this.spinnerService.show();

        const params: any = this.formGroup.value;

        if (this.state.params?.sibling) {
            params.sibling = this.state.params.sibling;
        }

        const {data, code}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.state.section}/shipping/create`, params);

        this.spinnerService.hide();
        if (code === 200) {

            if (data.make_return_label_shipment_id
                && await this.alertRef.show(
                    "Press continue for return label creation",
                    "Outbound label created",
                    "Continue"
                )) {

                const redirect: string[] = [
                    this.state.section,
                    "labels",
                    "add",
                    "order",
                    this.state.params.order,
                    "shipment",
                    data.make_return_label_shipment_id,
                    "type",
                    "inbound"
                ];

                if (data.sibling_shipment_id) {
                    // for warehouse only
                    redirect.push("sibling");
                    redirect.push(data.sibling_shipment_id);
                }

                if (this.state.params.back_to) {
                    redirect.push("back_to");
                    redirect.push(this.state.params.back_to);
                }

                if (this.state.params.partner) {
                    redirect.push("partner");
                    redirect.push(this.state.params.partner);
                }

                this.router.navigate(redirect);
                return;
            }

            if (this.state.section_type === "warehouse" && this.state.params.back_to) {
                this.toastService.show("Label was created. Please check your email", "success");
                this.router.navigate([
                    atob(this.state.params.back_to)
                ]);
            } else {
                this.router.navigate([
                    this.state.section, "orders", "view", "id", this.state.params.order
                ]);
            }
        }
    }

    public async ngOnInit(): Promise<any> {

        this.state = AppStateService.getState();

        this.showHiddenRates.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: boolean): void => {
                this.filterRates();
            });

        this.formGroup.get("incoterm").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value == "EXW") {
                    this.formGroup.get("bill_account_number").enable();
                    this.formGroup.get("bill_account_number").setValidators([Validators.required]);
                    this.formGroup.get("bill_account_number").setValue(this.customer_account);
                } else {
                    this.formGroup.get("bill_account_number").disable();
                    this.formGroup.get("bill_account_number").clearValidators();
                    this.formGroup.get("bill_account_number").setValue(null);
                }
                this.formGroup.get("bill_account_number").updateValueAndValidity();
                this.grouped_rates_to_list.next([]);
                this.checkIfCanGetRates();
            });

        this.formGroup.get("total_value").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
            .subscribe((value: number): void => {
                this.formGroup.get("total_value").setValue(value?.toFixed(2), {emitEvent: false});
                this.grouped_rates_to_list.next([]);
                this.checkIfCanGetRates();
            });

        this.fromAddress.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
            .subscribe((): void => {
                this.grouped_rates_to_list.next([]);
                this.checkIfCanGetRates();
                this.checkIfCanUploadDocument();
                this.setCustomsData();
            });
        this.toAddress.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(1000))
            .subscribe((): void => {
                this.grouped_rates_to_list.next([]);
                this.checkIfCanGetRates();
                this.checkIfCanUploadDocument();
                this.setCustomsData();
            });

        this.filteredUsers = this.userCtrl.valueChanges.pipe(
            startWith(null),
            takeUntil(this.destroy$),
            map((value: string | null): User.IData[] => {
                return value ? this._filter(value) : this.users.slice();
            }),
        );

        await this.getShipment();

        await this.getPackages();

        await this.getCountries();

        this.setCustomsData();

        this.getCurrencies();

        this.getRates(this.formGroup.value);
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "labels",
            actions: {
                "add": ["browse_shipments", "browse_parcels"],
                "edit": ["browse_shipments", "browse_parcels"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
