import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {Api} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {InventoryCountItemFormComponent} from "../../../index";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {Inventory} from "../../../../../../../../common/interfaces/Inventory.interface";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";

@Component({
    selector: "section-warehouse-procedures-inventory-count-scan",
    templateUrl: "scan.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "scan.component.scss"
    ]
})
export class WarehouseProceduresInventoryCountScanComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private prevKey: string = null;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    @ViewChild(Table2Component, {static: false})
    public scanFeedTableRef: Table2Component;

    public scannerKey: string;

    public searchInput: FormControl = new FormControl(null);

    public searchInputLabel: string = "Location";

    public location: Warehouse.ILocation;

    public usedLocations: any[] = [];

    public searchLocation: FormControl = new FormControl(null);

    public scanFeedTable: Table.ISettings;

    public inventoryCount: any;

    public itemsList: string[] = [];


    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private toastService: ToastService,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private modalService: ModalService,
    ) {
        super(changeDetectorRef);
    }

    private async getCountData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/inventory-counts/${this.state.params.count}`, {
                relations: [
                    "PartMasters:id,item"
                ]
            });
        this.spinnerService.hide();

        if (data) {
            this.inventoryCount = data;
            this.itemsList = this.inventoryCount.part_masters.map((p: Inventory.IPartMaster) => {
                return p.item;
            });
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getUsedLocations(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/inventory-counts/${this.state.params.count}/locations`, {
                relations: [
                    "WarehouseLocationType:id,name"
                ]
            });
        this.spinnerService.hide();

        if (data) {
            this.usedLocations = data;
            this.changeDetectorRef.markForCheck();
        }
    }


    /**
     * Add new item in specified location
     */
    private async addException(): Promise<any> {
        this.searchInput.setValue(null);

        const res: Modal.IResponse = await this.modalService.open(InventoryCountItemFormComponent, {
            data: {
                warehouse_location_id: this.location.id
            },
        });

        if (res && res.value) {
            this.spinnerService.show();

            const {code, message}: Api.IResponse = await this.api3Service.post(
                `${this.state.section}/inventory-counts/${this.state.params.count}/exceptions`,
                res.value
            );
            this.spinnerService.hide();

            if (code === 200) {
                this.scanFeedTableRef.reload();
            }
        }
    }

    private async addItem(item: any): Promise<any> {
        this.searchInput.setValue(null);

        const res: Modal.IResponse = await this.modalService.open(InventoryCountItemFormComponent, {
            data: {
                warehouse_location_id: this.location.id,
                type: "item",
                item: item.item
            },
            hide_notes: true,
            hide_type: true
        });

        if (res && res.value) {
            this.spinnerService.show();

            const {code, message}: Api.IResponse = await this.api3Service.post(
                `${this.state.section}/inventory-counts/${this.state.params.count}/scan-item`,
                res.value
            );
            this.spinnerService.hide();

            if (code === 200) {
                this.scanFeedTableRef.reload();
            }

            this.soundsService.textToSpeech(message);
        }
    }

    private setFocus(): void {
        setTimeout(() => {
            if (this.searchInputRef) {
                this.searchInputRef.nativeElement.focus();
            }
        }, 500);
    }

    public async findLocation(): Promise<any> {
        this.spinnerService.show();
        const {data, code, message}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/inventory-counts/${this.state.params.count}/locations`,
            {
                location: this.searchLocation.value,
                relations: [
                    "WarehouseLocationType:id,name"
                ]
            });
        this.spinnerService.hide();

        if (data) {
            this.location = data;
            this.toastService.show(message, "success");
            this.changeDetectorRef.markForCheck();

            this.setFocus();
        }
        this.soundsService.textToSpeech(message);
    }

    public setLocationFromList(location: Warehouse.ILocation): void {
        this.location = location;
        this.toastService.show("Locations set", "success");
        this.soundsService.textToSpeech("Location set");
        this.changeDetectorRef.markForCheck();
    }

    public async addLocation(value: string): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value,
            state: this.state
        });
        if (response && response.value) {
            this.location = response.value;

            this.soundsService.textToSpeech("Location set");
            this.toastService.show("Location set", "success");
            this.setFocus();
            this.searchInput.reset();
            this.changeDetectorRef.markForCheck();
        }
    }

    public async search(): Promise<any> {
        this.spinnerService.show();
        const {data, message, code}: Api.IResponse = await this.api3Service.post(
            `${this.state.section}/inventory-counts/${this.state.params.count}/scan`,
            {
                ref: this.searchInput.value,
                location_id: this.location.id,
            });
        this.spinnerService.hide();

        if (code === 200 && data) {
            this.soundsService.textToSpeech(message);

            if (data.exception) {
                this.addException();
            } else if (data.item) {
                this.addItem(data.item);
            } else {
                this.scanFeedTableRef.reload();
            }
            this.toastService.show(message, "success");
        }
        this.searchInput.reset();
        this.changeDetectorRef.markForCheck();
        this.setFocus();
    }

    private prepareFeedTable(): void {
        this.scanFeedTable = {
            api: {
                url: [
                    this.state.section,
                    "inventory-counts",
                    this.state.params.count,
                    "feed"
                ],
                version: 3
            },
            columns: [
                {
                    data: "location",
                    title: "Location"
                },
                {
                    data: "ref",
                    title: "Scanned value"
                },
                {
                    data: "type",
                    title: "Value type"
                },
                {
                    data: "quantity",
                    title: "Quantity",
                    searchable: false
                },
                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false
                },
            ],
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }


    public onKeydown(event: any): void {
        if (this.prevKey !== "Control" && this.prevKey !== "Shift") {
            if (event.key === "Enter" || event.key === "Tab") {
                this.search();
            }
        }

        if ((this.prevKey === "Control" || this.prevKey === "Shift" || this.prevKey === "Alt")
            && event.key === "Enter") {
            this.searchInput.setValue(this.searchInput.value + ",");
        }

        this.prevKey = event.key;
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.scannerKey = data.scannerKey;
        this.location = null;
        this.changeDetectorRef.markForCheck();

        this.soundsService.textToSpeech("please scan location");
        this.getUsedLocations();

        this.getCountData();
        this.prepareFeedTable();

        this.result.emit({
            action: "result",
            value: true
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
