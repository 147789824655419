import {Component} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";


@Component({
    selector: "section-package-type-list-all",
    template: `
        <section-package-type-list [state]="state"></section-package-type-list>`
})
export class PackageTypeAllComponent implements Base.IComponent {

    public readonly state: Base.IState;

    public constructor() {
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "package-type",
            actions: {
                "browse": ["browse_warehouse_package_types"]
            }
        };
    }

}
