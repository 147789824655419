<common-confirm></common-confirm>

<div id="selection" class="mat-elevation-z1" (window:resize)="handleSize($event?.target?.innerWidth)">


    <ng-template [ngIf]="progress">
        <mat-progress-bar [color]="'primary'" [mode]="'indeterminate'" class="progress"></mat-progress-bar>
    </ng-template>


    <div class=" logo">
        <img src="assets/images/logo.png" class="logo">
    </div>


    <mat-divider></mat-divider>


    <div *ngIf="data && ((!data.partners && !data.threepls && !data.warehouses)
            || (data.partners.length === 0 && data.threepls.length === 0 && data.warehouses.length === 0))">
        <h2 class="text-center blue">Your account awaiting confirmation</h2>
    </div>

    <ng-template [ngIf]="data && (data.partners || data.threepls || data.warehouses)
                && (data.partners.length > 0 || data.threepls.length > 0 || data.warehouses.length > 0)">


        <mat-divider></mat-divider>
        <mat-divider></mat-divider>
        <mat-divider></mat-divider>


        <div class="actions row flex vertical-middle">
            <div class="min">
                <button mat-raised-button color="warn" (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>Log out</span>
                </button>
            </div>

            <div class="min padding-right-20" *ngIf="admin">
                <button mat-raised-button color="accent" [routerLink]="['/admin']">
                    <mat-icon>fingerprint</mat-icon>
                    <span>Administrator</span>
                </button>
            </div>


        </div>

        <mat-tab-group>
            <mat-tab label="Partners" *ngIf="data.partners && data.partners.length">
                <div class="padding-20">
                    <div class="row flex">
                        <mat-form-field class="search half" [hidden]="showCount >= data.partners.length">
                            <mat-label>Search</mat-label>
                            <input matInput type="search"
                                   (input)="filterPartners($event.target.value)">
                        </mat-form-field>

                        <div class="half padding-top-20">
                            <mat-checkbox [formControl]="showHistory">Show history records</mat-checkbox>
                        </div>
                    </div>


                    <mat-grid-list [cols]="gridCols" rowHeight="120px" gutterSize="15px">
                        <mat-grid-tile *ngFor="let partner of filteredPartners" class="item">
                            <a matListItemTitle (click)="goToPartner(partner)" [title]="partner.display_name">
                                <span class="img">
                                <img *ngIf="partner.icon_path" [src]="partner.icon_path" alt="">
                                </span>

                                <span>{{ partner.display_name }}</span>
                            </a>
                        </mat-grid-tile>

                    </mat-grid-list>

                </div>

            </mat-tab>

            <mat-tab label="ThreePLs" *ngIf="data.threepls && data.threepls.length">
                <div class="padding-20">
                    <div class="row flex">
                        <mat-form-field class="search half" [hidden]="showCount >= data.threepls.length">
                            <mat-label>Search</mat-label>
                            <input matInput type="search"
                                   (input)="filterThreepls($event.target.value)">
                        </mat-form-field>
                    </div>

                    <mat-grid-list [cols]="gridCols" rowHeight="120px" gutterSize="15px">
                        <mat-grid-tile *ngFor="let threepl of filteredThreepls" class="item">
                            <a matListItemTitle (click)="goToThreepl(threepl)" [title]="threepl.display_name">
                                <span class="img">
                                    <img *ngIf="threepl.icon_path" [src]="threepl.icon_path" alt="">
                                    <ng-template [ngIf]="!threepl.icon_path">
                                        <mat-icon *ngIf="threepl.type === 'ThreePL'">home</mat-icon>
                                        <mat-icon *ngIf="threepl.type === 'OEM'">settings</mat-icon>
                                    </ng-template>
                                </span>
                                <span>{{ threepl.display_name }}</span>
                            </a>
                        </mat-grid-tile>

                    </mat-grid-list>

                </div>
            </mat-tab>

            <mat-tab label="Warehouses" *ngIf="data.warehouses && data.warehouses.length">
                <div class="padding-20">
                    <div class="row flex">
                        <mat-form-field class="search half" [hidden]="showCount >= data.warehouses.length">
                            <mat-label>Search</mat-label>
                            <input matInput type="search"
                                   (input)="filterWarehouses($event.target.value)">
                        </mat-form-field>
                    </div>

                    <mat-grid-list [cols]="gridCols" rowHeight="120px" gutterSize="15px">
                        <mat-grid-tile *ngFor="let warehouse of filteredWarehouses" class="item">
                            <a matListItemTitle (click)="goToWarehouse(warehouse)" [title]="warehouse.name">
                                <span class="img">
                                    <mat-icon *ngIf="warehouse.type.toLowerCase() === 'warehouse'">warehouse</mat-icon>
                                    <mat-icon *ngIf="warehouse.type.toLowerCase() === 'site'">home</mat-icon>
                                    <mat-icon *ngIf="warehouse.type.toLowerCase() === 'virtual'">view_in_ar</mat-icon>
                                    <mat-icon *ngIf="warehouse.type.toLowerCase() === 'oem'">factory</mat-icon>
                                    <mat-icon *ngIf="warehouse.type.toLowerCase() === 'person'">person</mat-icon>
                                </span>
                                <span>{{ warehouse.threepl.display_name }} - {{ warehouse.name }}</span>
                            </a>
                        </mat-grid-tile>

                    </mat-grid-list>

                </div>
            </mat-tab>


        </mat-tab-group>


    </ng-template>

</div>

<common-test></common-test>
