import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl} from "@angular/forms";
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {
    AvailableLocationsModalComponent,
    BoxItemsListComponent,
    FindLocationByItemModalComponent, PalletItemsListComponent,
    WarehouseBufferScanFormComponent,
    WarehouseProceduresWizardSelectLockerCellModalComponent,
    WarehouseProceduresWizardSelectOrderItemModalComponent
} from "../../..";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Table} from "../../../../../../../../common/interfaces/table.interface";
import {Table2Component} from "../../../../../../../../common/components/table2";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {Courier} from "../../../../../../../../common/interfaces/courier.interface";
import {SoundsService} from "../../../../../../../../common/services/sounds.service";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {
    CourierTransactionDeliveredFormComponent,
    CourierTransactionFormComponent
} from "../../../../courier-transaction";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {ConfirmComponent} from "../../../../../../../../common/components/confirm/confirm.component";
import {PusherService} from "../../../../../../../../common/services/pusher.service";
import {OrderItemsInfoComponent, OrderItemStickerComponent} from "../../../../../../partner/components/order";
import {Order} from "../../../../../../../../common/interfaces/order.interface";
import {takeUntil} from "rxjs";
import {ModalComponent} from "../../../../../../../../common/components/modal/modal.component";

@Component({
    selector: "section-warehouse-procedures-wizard-inbound-scan",
    templateUrl: "scan.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        "scan.component.scss"
    ]
})
export class WarehouseProceduresWizardInboundScanComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    private timeout: any;

    private orderRef: string;

    private warehouse_slug: string;

    private prevKey: string = null;

    public originalItemsData: any = null;

    public shipment: Warehouse.IShipment;

    public lastScanValue: string = null;

    @ViewChild("searchInputRef", {static: false})
    public searchInputRef: ElementRef;

    public warehouseOrderId: string | number;

    public scannerKey: string;

    public partner: User.IPartner;

    public warehouse: Warehouse.IWarehouse;

    public searchLocation: FormControl = new FormControl(null);

    public searchInput: FormControl = new FormControl(null);

    public location: {
        name: string,
        id: number,
        image?: string,
        type_id?: number,
        warehouse_transactions_count?: number;
        hub?: Warehouse.IHub;
    };

    public usedLocations: any[] = [];

    public listTable: Table.ISettings;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    @ViewChild(ConfirmComponent, {static: false})
    public confirm: ConfirmComponent;

    public courier_transactions: Courier.ITransaction[];

    public scannerOnly: boolean = false;

    public scanFeed: any[] = [];

    public scanFeedGrouped: any[] = [];

    public checkedFeedItems: string[] = [];

    public orderRows: any = {
        pallets: [],
        boxes: [],
        items: [],
    };

    public totalToScan: any = {
        pallets: 0,
        boxes: 0,
        items: 0
    };

    public totalScanned: any = {
        pallets: 0,
        boxes: 0,
        items: 0
    };

    public inboundItemsSearch: FormControl = new FormControl(null);

    public showMatchedInList: FormControl = new FormControl(false);

    public showOnlyFailedScans: FormControl = new FormControl(false);

    public selectAll: FormControl = new FormControl(false);

    public customsNumber: FormControl = new FormControl(null);

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        public soundsService: SoundsService,
        private spinnerService: SpinnerService,
        private pusherService: PusherService
    ) {
        super(changeDetectorRef);
    }

    private connectToSockets(): void {

        if (!this.shipment) {
            return;
        }

        this.pusherService.echo.private("scan-feed-" + this.shipment.id)
            .listen(".App\\Events\\WarehouseBufferScanEvent", (data: any): void => {
                console.warn("WarehouseBufferScanEvent", data);

                let update = false;
                for (const index in this.scanFeed) {
                    if (data.feed_id === this.scanFeed[index].feed_id) {
                        this.scanFeed[index] = data.data;
                        update = true;
                        break;
                    }
                }

                if (!update) {
                    this.addScan(data.data);
                }

                this.changeDetectorRef.markForCheck();

                this.mapData(this.originalItemsData);
            });
    }

    private async getLocationsByOrder(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "order", this.orderRef], {}, {
                type: "inbound"
            });
        if (data) {
            this.usedLocations = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get shipment
     */
    private async getShipment(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/shipments/${this.shipment.id}`, {}, {
                relations: [
                    "Partner",
                    "WarehouseOrder"
                ]
            });
        this.spinnerService.hide();

        if (data) {
            this.shipment = data;
            this.scannerOnly = this.shipment && this.shipment.partner
                ? this.shipment.partner.properties.disable_manual_scanning
                : false;

            this.customsNumber.setValue(this.shipment.customs_number);
            this.changeDetectorRef.markForCheck();

            this.connectToSockets();


        }
    }

    private async getItems(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.get(
            `${this.state.section}/scan/inbound/${this.shipment.id}/items`);
        this.spinnerService.hide();

        if (data) {
            this.originalItemsData = data;
            this.mapData(this.originalItemsData);
        }
    }

    private async getWarehouse(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "findBySlug", this.warehouse_slug], {}, {
                relations: [
                    "address",
                    "contact",
                    "contact.customer",
                    "Threepl",
                    "UlockitLockers.EmptyCells.WarehouseLocation"
                ]
            });
        if (data) {
            this.warehouse = data;
            this.showMatchedInList.setValue(
                this.warehouse.properties.default_show_fully_scanned_items ?? false
            );
        }
        this.spinnerService.hide();
    }

    private mapData(data: { pallets: any[], boxes: any[], items: any [] }): void {

        if (!data) {
            return;
        }

        this.orderRows = {
            pallets: [],
            boxes: [],
            items: [],
        };

        this.totalToScan = {
            pallets: 0,
            boxes: 0,
            items: 0
        };

        this.totalToScan.pallets = data.pallets.length;

        for (const pallet of data.pallets) {
            pallet.matched = false;
            this.orderRows.pallets.push(pallet);
        }

        this.totalToScan.boxes = data.boxes.length;

        for (const box of data.boxes) {
            box.matched = false;
            this.orderRows.boxes.push(box);
        }

        for (const item of data.items) {
            item.matched = false;
            item.matched_qty = 0;
            this.totalToScan.items += item.quantity;

            this.orderRows.items.push(item);
        }

        this.changeDetectorRef.markForCheck();

        this.checkMatchingScans();
    }

    private checkMatchingScans(): void {

        if (!Object.keys(this.orderRows).length || !this.scanFeed) {
            return;
        }

        this.totalScanned = {
            pallets: 0,
            boxes: 0,
            items: 0
        };

        feedLoop: for (const scan of this.scanFeed) {
            if (scan.status !== "success") {
                continue;
            }

            const listed_location: any = this.usedLocations.find((location: any) => {
                return location.id === scan.location_id;
            });
            if (!listed_location) {
                this.usedLocations.push({id: scan.location_id, location: scan.location});
            }


            if (scan.type === "pallet") {
                for (const pallet of this.orderRows.pallets) {
                    if (pallet.id === scan.pallet_id) {
                        pallet.matched = true;
                        this.totalToScan.pallets--;
                        this.totalScanned.pallets++;

                        continue feedLoop;
                    }
                }
            }

            if (scan.type === "box") {
                for (const box of this.orderRows.boxes) {
                    if (box.id === scan.box_id) {
                        box.matched = true;
                        this.totalToScan.boxes--;
                        this.totalScanned.boxes++;

                        continue feedLoop;
                    }
                }
            }

            if (scan.type === "serial") {
                for (const item of this.orderRows.items) {
                    if (item.serial === scan.serial) {
                        item.matched_qty = 1;
                        this.totalToScan.items--;
                        this.totalScanned.items++;
                        continue feedLoop;
                    }
                }
            }

            if (scan.type === "item") {
                for (const item of this.orderRows.items) {
                    if (item.serial) {
                        continue;
                    } else if (scan.part_master_id !== item.part_master_id) {
                        continue;
                    }

                    if (HelpersService.valOrNull(scan.configurations)
                        !== HelpersService.valOrNull(item.configurations)) {
                        continue;
                    }

                    if (item.batch) {
                        if (scan.batch && item.batch !== scan.batch) {
                            continue;
                        }
                    }

                    if (item.part_master.required_batch_inbound && !scan.batch) {
                        continue;
                    }


                    if (item.quantity < item.matched_qty + scan.quantity) {
                        continue;
                    }

                    item.matched_qty += scan.quantity;

                    this.totalToScan.items -= scan.quantity;
                    this.totalScanned.items += scan.quantity;

                    continue feedLoop;

                }
            }
        }

        for (const item of this.orderRows.items) {
            if (item.matched_qty === item.quantity) {
                item.matched = true;
            }
        }

        this.changeDetectorRef.markForCheck();
    }

    private async getScanBuffer(): Promise<any> {
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/scan/inbound/${this.shipment.id}`, {}, {});

        if (data) {
            this.scanFeed = data;
            this.changeDetectorRef.markForCheck();

            this.groupFeedData();

            this.mapData(this.originalItemsData);

            this.setFocus();
        }
    }

    private clearValue(original: string): string {
        return original.trim().replace(/^_+|_+$/g, "");
    }

    private groupFeedData(): void {

        const grouped: any = {};

        for (let scan of this.scanFeed) {
            if (scan.status !== "success") {
                continue;
            }

            if (!grouped[scan.location_id]) {
                grouped[scan.location_id] = {
                    location: scan.location,
                    pallets: [],
                    pallets_quantity: 0,
                    boxes: [],
                    boxes_quantity: 0,
                    items: [],
                    items_quantity: 0
                };
            }

            switch (scan.type) {
                case "pallet":
                    grouped[scan.location_id].pallets.push(scan);
                    grouped[scan.location_id].pallets_quantity += scan.quantity;
                    break;
                case "box":
                    grouped[scan.location_id].boxes.push(scan);
                    grouped[scan.location_id].boxes_quantity += scan.quantity;
                    break;
                case "serial":
                case "item":
                    grouped[scan.location_id].items.push(scan);
                    grouped[scan.location_id].items_quantity += scan.quantity;
                    break;
            }

        }

        this.scanFeedGrouped = Object.values(grouped);
    }

    /**
     * Find location or box by its ref
     */
    private async findItem(): Promise<any> {

        const value = this.clearValue(this.searchInput.value);
        if (!value) {
            this.toastService.show("Empty value scanned", "warning");
            return;
        }

        this.spinnerService.show();

        const feed_id: string = this.shipment.id + "-" + HelpersService.randomString();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `/${this.state.section}/scan/inbound`, {
                shipment_id: this.shipment.id,
                value,
                location_id: this.location.id,
                feed_id,

            }, {});

        this.spinnerService.hide();

        if (data) {

            switch (data.status) {
                case "error":
                    this.toastService.show(data.message, "error");
                    this.soundsService.textToSpeech(data.message);
                    break;

                case "pallet_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        pallet: this.searchInput.value,
                        status: "pending",
                        quantity: 0,
                        type: "pallet"
                    });
                    break;

                case "box_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        box: this.searchInput.value,
                        status: "pending",
                        location: this.location.name,
                        quantity: 0,
                        type: "box"
                    });
                    break;

                case "serial_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);

                    this.addScan({
                        feed_id,
                        serial: this.searchInput.value,
                        status: "pending",
                        location: this.location.name,
                        quantity: 1,
                        type: "serial"
                    });
                    break;

                case "order_item_found":
                    this.inboundByOrderItem(data.data, feed_id);
                    break;

                case "location_found":
                    this.toastService.show(data.message, "success");
                    this.soundsService.textToSpeech(data.message);
                    this.location = {
                        name: data.data.location,
                        id: data.data.id,
                        image: data.data.warehouse_location_type.icon,
                        type_id: data.data.warehouse_location_type_id,
                        warehouse_transactions_count: data.data.warehouse_transactions_by_inventory_count
                    };
                    break;
                default:
                    break;
            }

        }
        this.searchInput.reset();
        this.setFocus();
        this.changeDetectorRef.markForCheck();

    }

    private addScan(data: any): void {

        data.deletable = true;

        var newArray = this.scanFeed.slice();
        newArray.unshift(data);

        this.scanFeed = newArray;

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Get inventory list for specified location
     */
    private getInventoriesInLocation(): void {
        if (!this.location) {
            return;
        }

        const columns: Table.ICol[] = [
            {
                data: "item",
                title: "Item",
            },
            {
                data: "serial",
                title: "Serial number",
            },
            {
                data: "configurations",
                title: "Configurations",
            },
            {
                data: "quantity",
                title: "Quantity",
            },
            {
                data: "partner.icon_path",
                title: "Partner",
                render: (data: any): string => {
                    return "<img class='logo' src='" + data.partner.icon_path + "' alt=''>";
                },
                searchable: false,
                sortable: false
            },
            {
                data: "inventory_conversion.customers_inventory_name",
                name: "InventoryConversion.customers_inventory_name",
                title: "Hub"
            }
        ];

        const api: any = {
            url: ["warehouse_location", "" + this.location.id, "inventories"],
            query: {
                warehouse_order_id: this.warehouseOrderId
            }
        };

        if (this.listTable && this.tableRef) {
            this.tableRef.reload(api);
        } else {
            this.listTable = {
                table_id: "bjsd9ZH711F",
                api,
                columns: columns,
                export: {
                    file_name: "Invenories",
                    columns: columns
                }
            };
        }
        this.changeDetectorRef.markForCheck();
    }

    private async getLocationHub(): Promise<any> {
        if (this.location.hub) {
            return;
        }
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse_location", "" + this.location.id, "hub"]);
        if (data && data.customers_sub_inventory) {
            this.location.hub = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private async addTransactionFromItem(item: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseBufferScanFormComponent,
            {
                data: item,
                type: "inbound",
                modalWidth: 600,
                modalHeight: 560,
                state: this.state,
                scannerKey: this.scannerKey,
                partner: item.partner,
                warehouse_slug: this.warehouse_slug,
                location_name: this.location.name,
            });

        if (response && response.value) {

            this.getScanBuffer();

            this.toastService.show("Item scanned", "success");
            this.soundsService.textToSpeech("Item scanned");
        }

        this.searchInput.reset();
        this.setFocus();
    }

    private inboundByOrderItem(items: any[], feed_id: string): void {

        const scans: any[] = [...this.scanFeed];

        items = items.filter((item: any) => {
            let count: number = 0;

            for (const scan of scans) {
                if (scan.fulfilled || scan.status !== "success") {
                    continue;
                }

                const same_part = scan.part_master_id === item.part_master_id;

                if (!same_part) {
                    continue;
                }

                if (scan.serial && item.serial === scan.serial) {
                    scan.fulfilled = true;
                    return false;
                }

                if (item.batch) {
                    if (scan.batch !== item.batch) {
                        continue;
                    }

                    if (item.part_master.required_batch_inbound && !scan.batch) {
                        continue;
                    }
                }

                const same_batch = !item.part_master.required_batch_inbound
                    || HelpersService.valOrNull(scan.batch) === HelpersService.valOrNull(item.batch);


                const same_config = scan.configurations === item.configurations;

                if (same_part && same_batch && same_config) {
                    count += scan.quantity;
                }
            }

            if (item.quantity <= count) {
                return false;
            }

            item.not_scanned_quantity = item.quantity - count;

            return true;
        });

        items = items.map((item: any) => {
            return {
                id: `${item.id}`,
                order_item_id: item.id,
                part_master_id: item.part_master_id,
                item: item.item,
                serial_required: item.part_master.required_serial_wh_inbound,
                has_serial: item.part_master.serial,
                serial: item.serial,
                has_batch: item.part_master.batch,
                batch_required: item.part_master.required_batch_inbound,
                batch: item.partner.properties.inbound_scan_fill_batch === false ? null : item.batch,
                has_revision: item.part_master.revision,
                revision_required: item.part_master.required_revision_inbound,
                rev: item.rev,
                has_lot: item.part_master.lot,
                lot_required: item.part_master.required_lot_inbound,
                lot: item.lot,
                configurations: item.configurations,
                warehouse_location: {
                    location: this.location.name,
                    id: this.location.id
                },
                location_id: this.location.id,
                partner_id: item.partner_id,
                partner: item.partner,
                quantity: item.not_scanned_quantity,
                box_ref: item.box?.ref || null,
                pallet_ref: item.box?.pallet?.ref || null,
                order_id: item.order_id,
                type: "inbound",
                shipment_id: this.shipment.id,
                warehouse_order_id: this.shipment.warehouse_order.id,
                box_id: item.box?.id || null,
                feed_id,
            };
        });

        if (!items.length) {
            this.soundsService.textToSpeech("No items to scan");
            this.toastService.show("No items to scan", "warning");
            return;
        }
        if (items[0].serial_required || items.length === 1) {
            const item: any = items[0];
            if (item.serial_required) {
                item.serial = null;
            }

            this.addTransactionFromItem(item);
        } else if (items.length > 1) {
            this.selectOrderItems(items, items[0].partner);
        }
    }

    private setFocus(): void {
        setTimeout(() => {
            if (this.searchInputRef) {
                this.searchInputRef.nativeElement.focus();
            }
        }, 500);
    }

    private async selectOrderItems(items: any[], partner: User.IPartner): Promise<any> {
        const response: Modal.IResponse = await this.modalService
            .open(WarehouseProceduresWizardSelectOrderItemModalComponent, {
                items,
                partner,
                type: "inbound"
            });
        if (response && response.value) {
            const item: any = response.value;

            item.partner = partner;
            item.order_item_id = item.id;
            this.addTransactionFromItem(item);
        }
    }

    /**
     * Get courier transactions for selected shipment
     */
    private async getCourierTransactions(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["shipment", "" + this.shipment.id, "courier_transactions"]);
        this.spinnerService.hide();
        if (data) {
            this.courier_transactions = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Find location if none is specified
     */
    public async findLocation(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/${this.state.section}/locations`, {}, {
                search_by: this.searchLocation.value,
                search_in: ["location"],
                search_like: false,
                relations: [
                    "WarehouseLocationType"
                ],
                counts: [
                    "WarehouseTransactionsByInventory"
                ]
            });

        this.spinnerService.hide();

        if (data && data[0]) {
            this.location = {
                name: data[0].location,
                id: data[0].id,
                image: data[0].warehouse_location_type?.icon,
                type_id: data[0].warehouse_location_type_id,
                warehouse_transactions_count: data[0].warehouse_transactions_by_inventory_count
            };

            this.getLocationHub();

            this.soundsService.textToSpeech("Location set");
            this.toastService.show("Location set", "success");

        } else {
            this.soundsService.textToSpeech("Location not found");
            this.toastService.show("Location not found", "warning");
        }

        this.searchLocation.reset();
        this.changeDetectorRef.markForCheck();
        this.setFocus();
    }

    /**
     * Find location or box
     */
    public async find(): Promise<any> {
        if (!this.searchInput.value) {
            this.lastScanValue = null;
            return;
        }

        if (!this.location) {
            this.findLocation();
        } else {
            this.findItem();
        }
    }

    /**
     * Listen scanner activation key event
     * @param event
     */
    public onKeydown(event: any): void {
        if (this.prevKey !== "Control" && this.prevKey !== "Shift") {
            if (event.key === "Enter" || event.key === "Tab") {
                this.find();
            }
        }

        if ((this.prevKey === "Control" || this.prevKey === "Shift" || this.prevKey === "Alt")
            && event.key === "Enter") {
            this.searchInput.setValue(this.searchInput.value + ",");
        }

        this.prevKey = event.key;
    }

    public async addTracking(): Promise<any> {
        await this.modalService.open(CourierTransactionFormComponent, {
            action: "add",
            shipmentId: this.shipment.id,
            orderRef: this.orderRef,
            state: this.state,
            orderData: {id: this.shipment.order_id},
            type: "inbound",
            partnerSlug: this.shipment.partner.slug
        });

        this.getCourierTransactions();
    }

    public async markDelivered(id: number): Promise<any> {
        await this.modalService.open(CourierTransactionDeliveredFormComponent, {id});

        this.getCourierTransactions();
    }

    public async findLocationByItem(item: string = null): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(FindLocationByItemModalComponent, {
            item,
            orderRef: this.orderRef,
            modalWidth: 1200
        });

        if (response && response.name === "set") {
            response.value.hub = response.value.inventory_conversion;
            this.setLocationFromList(response.value);
        }
    }

    public async locationList(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(AvailableLocationsModalComponent, {
            orderRef: this.orderRef,
            modalWidth: 1200,
            state: this.state,
            partner_id: this.shipment.partner_id
        });

        if (response) {
            if (response.name === "set") {
                response.value.hub = response.value.inventory?.inventory_conversion;
                this.setLocationFromList(response.value);
            } else if (response.name === "add") {
                this.addLocation("");
            }
        }
    }

    /**
     * Set location from used list
     * @param data
     */
    public setLocationFromList(data: any): void {
        this.location = {
            name: data.location,
            id: data.id,
            image: data.warehouse_location_type?.icon,
            type_id: data.warehouse_location_type_id,
            warehouse_transactions_count: data.warehouse_transactions_by_inventory_count,
            hub: data.hub ? data.hub : null
        };
        this.getLocationHub();

        this.soundsService.textToSpeech("Location set");
        this.toastService.show("Location set", "success");
        this.searchInput.reset();
        this.changeDetectorRef.markForCheck();

        this.setFocus();
    }

    /**
     * Add new location and select it
     */
    public async addLocation(value: string): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value,
            state: this.state
        });
        if (response && response.value) {
            this.location = {
                name: response.value.location,
                id: response.value.id,
                image: response.value.warehouse_location_type.icon,
                type_id: response.value.warehouse_location_type_id,
                warehouse_transactions_count: response.value.warehouse_transactions_by_inventory_count
            };

            this.soundsService.textToSpeech("Location set");
            this.toastService.show("Location set", "success");

            this.searchInput.reset();
            this.setFocus();
            this.changeDetectorRef.markForCheck();
        }
    }

    public async deleteScans(ids: string[]): Promise<any> {
        if (!await this.confirm.confirm("Delete scanned item(s)?")) {
            return;
        }

        this.spinnerService.show();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Delete,
            `${this.state.section}/scan/inbound/${this.shipment.id}`, {ids});
        this.spinnerService.hide();

        this.checkedFeedItems = [];
        this.getScanBuffer();
    }

    public selectScan(id: string): void {
        if (this.checkedFeedItems.includes(id)) {
            return;
        }
        this.checkedFeedItems.push(id);
    }

    public deSelectScan(id: string): void {
        const index: number = this.checkedFeedItems.indexOf(id);
        this.checkedFeedItems.splice(index, 1);
        this.selectAll.setValue(false, {emitEvent: false});
    }

    public async completeScanning(): Promise<any> {
        const has_invalid = this.scanFeed.find((scan) => scan.status === "error");

        if (has_invalid) {
            if (!await this.confirm.confirm("Make transactions? All invalid scans will be ignored")) {
                return;
            }
        }

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.state.section}/scan/inbound/convert/${this.shipment.id}`, {}, {});
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.result.emit({
                action: "result",
                value: {
                    warehouseOrderId: this.warehouseOrderId,
                    processScans: true
                },
                goToNextStep: true,
            });
        }
    }

    public async showAllocations(item: Order.IItem): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(OrderItemsInfoComponent, {
            data: item,
            state: this.state,
            export: false,
            url: {
                url: [this.state.section, "order-items", "" + item.id, "allocations"],
                query: {
                    type: "inbound",
                    relations: [
                        "Box:id,ref,pallet_id",
                        "Box.Pallet:id,ref",
                        "InventoryConversion:id,customers_sub_inventory",
                        "relatedConfigurations"
                    ]
                },
                version: 3
            },
            partner: this.partner
        });
    }

    public async openPallet(pallet: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(PalletItemsListComponent,
            {
                pallet,
                modalWidth: 1000,
                itemsType: "boxes"
            });
    }

    /**
     * Show items in the box
     * @param box
     */
    public async openBox(box: any): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(BoxItemsListComponent,
            {
                box,
                modalWidth: 1000,
                itemsType: "allocations"
            });
    }

    public async printSticker(item: any): Promise<void> {
        this.modalService.open(OrderItemStickerComponent, {
            item: {...item},
            serials: [item.serial]
        });
    }

    public async showLockers(): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(
            WarehouseProceduresWizardSelectLockerCellModalComponent, {
                lockers: this.warehouse.ulockit_lockers
            });
        if (response && response.value) {
            this.setLocationFromList(response.value);
        }

    }

    public async updateCustomsNumber(): Promise<any> {
        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Post,
            `${this.state.section}/shipments/${this.shipment.id}/customs-number`, {
                customs_number: this.customsNumber.value
            });
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
        }
    }

    public showScansFromLocation(scans: any[]): void {
        let template: string = ``;
        for (const scan of scans) {
            template += `<div class="margin-bottom-5">`;
            switch (scan.type) {
                case "pallet":
                    template += `<b>Pallet:</b> ${scan.pallet}. <b>Qty:</b> ${scan.quantity}.`;
                    break;
                case "box":
                    template += `<b>Box:</b> ${scan.box}. <b>Qty:</b> ${scan.quantity}.`;
                    break;
                default:
                    template += `<b>Item:</b> ${scan.item}. <b>Qty:</b> ${scan.quantity}.`;
                    if (scan.serial) {
                        template += ` <b>Serial:</b> ${scan.serial}.`;
                    }
            }
            template += `</div>`;
        }

        this.modalService.open(ModalComponent, {
            title: "Scans from location",
            template,
        });
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = data.state;
        this.warehouseOrderId = data.warehouseOrderId;
        this.orderRef = data.orderRef;
        this.scannerKey = data.scannerKey;
        this.location = null;
        this.shipment = data.shipment;

        this.warehouse_slug = this.state.section_slug;

        this.changeDetectorRef.markForCheck();

        this.getShipment();
        this.getItems();
        this.getScanBuffer();
        this.getWarehouse();

        this.timeout = setInterval(() => {
            this.getScanBuffer();
        }, 30000);

        this.getLocationsByOrder();

        this.getCourierTransactions();

        this.result.emit({
            action: "result",
            value: this.warehouseOrderId
        });

        if (this.state.params.location) {
            this.searchLocation.setValue(this.state.params.location);
            this.findLocation();
        } else {
            this.soundsService.textToSpeech("Please scan location");
        }

        this.selectAll.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean) => {
            for (const feed_item of this.scanFeed) {
                if (value) {
                    if (this.showOnlyFailedScans.value && feed_item.status !== "error") {
                        continue;
                    }
                    this.selectScan(feed_item.feed_id);
                } else {
                    this.deSelectScan(feed_item.feed_id);
                }
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.pusherService.echo.leave("scan-feed-" + this.shipment.id);
        clearInterval(this.timeout);
    }

}
