<common-confirm></common-confirm>
<h1 class="container-heading">Request</h1>
<div class="heading-info row flex align-center" *ngIf="data">
    <div class="margin-right-10"><b>Created at:</b></div>
    <div *userNotAllowed="'edit_request_creation_date'" class="margin-right-10">
        {{data.created_at}}
    </div>
    <div class="margin-right-10">
        <common-form-date-time *userAllowed="'edit_request_creation_date'"
                               [required]="true"
                               class="no-label"
                               [value]="data.created_at"
                               (valueChange)="creationDate.setValue($event)"></common-form-date-time>
    </div>

    <ng-container *ngIf="data.closed_on">
        <div class="margin-right-10"><b>Closed at:</b></div>

        <div *userNotAllowed="'edit_request_close_date'">
            {{data.closed_on}}
        </div>
        <div>
            <common-form-date-time *userAllowed="'edit_request_close_date'"
                                   [required]="true"
                                   class="no-label"
                                   [value]="data.closed_on"
                                   (valueChange)="closeDate.setValue($event)"></common-form-date-time>
        </div>
    </ng-container>
</div>
<div class="heading-info row flex align-center" *ngIf="data">
    <div class="margin-right-10">
        <b>Customer's local time:</b> {{currentTime}} <b>in TimeZone:</b> {{data?.order?.main_address?.time_zone}}
    </div>
</div>

<mat-card>
    <mat-card-content>
        <ng-template [ngIf]="data">

            <div class="pull-right flex row top-info">
                <div class="order-link" *ngIf="data.order">
                    <div class="title"><b>Order</b></div>
                    <div class="color-link pointer" matTooltip="Go to order">
                        <a [routerLink]="data.url" target="_blank">
                            {{data.order.ref}}
                        </a>
                    </div>
                </div>
                <div class="lticket" *ngIf="data.lticket">
                    <div class="title"><b>ITicket#</b></div>
                    <div>
                        {{data.lticket}}
                        <mat-icon (click)="copyITicket()"
                                  matTooltip="Copy to clipboard" class="pointer">
                            content_copy
                        </mat-icon>
                        <a [href]="'mailto:system@logivice.net?subject='
                        +data.title+'%20lTicket%3A'+data.lticket" matTooltip="Email">
                            <mat-icon>
                                mail_outline
                            </mat-icon>
                        </a>


                    </div>
                    <input type="text" [value]="'lTicket:'+data.lticket"
                           #iTickerRef style="position: absolute; left: -9999px;">
                </div>
                <div class="messages-count">
                    <div class="title"><b>Messages</b></div>
                    <div>{{messagesCount}}</div>
                </div>
                <div class="messages-count">
                    <div class="title">
                        <b>Est. billable hours</b>
                    </div>
                    <ng-template [ngIf]="requestEstHoursEdit">
                        <mat-form-field class="billable_hours">
                            <mat-label>Est. billable hours</mat-label>
                            <input matInput [formControl]="requestEstHours" required>
                        </mat-form-field>
                        <button mat-icon-button color="primary" matTooltip="Save"
                                (click)="requestEstHoursEdit = !update('estimated_billable_hours',requestEstHours.value)">
                            <mat-icon color="success">check</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Cancel"
                                (click)="requestEstHours.setValue(data.title);requestEstHoursEdit = false">
                            <mat-icon color="warn">cancel</mat-icon>
                        </button>
                    </ng-template>
                    <ng-template [ngIf]="!requestEstHoursEdit">
                        <div>
                            {{ data.estimated_billable_hours  }}
                            <mat-icon class="pointer edit-btn" (click)="requestEstHoursEdit = true">
                                edit
                            </mat-icon>
                        </div>
                    </ng-template>
                </div>

                <div class="messages-count">
                    <div class="title">
                        <b>Billable hours</b>
                    </div>
                    <ng-template [ngIf]="requestHoursEdit">
                        <mat-form-field class="billable_hours">
                            <mat-label>Billable hours</mat-label>
                            <input matInput [formControl]="requestHours" required>
                        </mat-form-field>
                        <button mat-icon-button color="primary" matTooltip="Save"
                                (click)="requestHoursEdit = !update('billable_hours',requestHours.value)">
                            <mat-icon color="success">check</mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Cancel"
                                (click)="requestHours.setValue(data.title);requestHoursEdit = false">
                            <mat-icon color="warn">cancel</mat-icon>
                        </button>
                    </ng-template>
                    <ng-template [ngIf]="!requestHoursEdit">
                        <div>
                            {{data.billable_hours }}
                            <mat-icon class="pointer edit-btn" (click)="requestHoursEdit = true">
                                edit
                            </mat-icon>
                        </div>
                    </ng-template>
                </div>
            </div>


            <div>
                <h1 *ngIf="!requestTitleEdit">
                    {{data.title}}
                    <mat-icon class="pointer edit-btn" (click)="requestTitleEdit = true">
                        edit
                    </mat-icon>
                </h1>
                <ng-template [ngIf]="requestTitleEdit">
                    <mat-form-field>
                        <mat-label>Title</mat-label>
                        <input matInput [formControl]="requestTitle" required>
                    </mat-form-field>
                    <button mat-icon-button color="primary" matTooltip="Save"
                            (click)="requestTitleEdit = !update('title',requestTitle.value)">
                        <mat-icon color="success">check</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Cancel"
                            (click)="requestTitle.setValue(data.title);requestTitleEdit = false">
                        <mat-icon color="warn">cancel</mat-icon>
                    </button>
                </ng-template>
            </div>


            <div class="status">
                <div *ngIf="!requestStatusEdit">
                    <span class="status-chip {{data.status}}">{{data.status}}</span>
                    <mat-icon class="pointer edit-btn" (click)="requestStatusEdit = true">
                        edit
                    </mat-icon>
                </div>
                <ng-template [ngIf]="requestStatusEdit">
                    <mat-form-field>
                        <mat-label>Status</mat-label>
                        <mat-select [formControl]="requestStatus" required>
                            <mat-option value="New">New</mat-option>
                            <mat-option value="Open">Open</mat-option>
                            <mat-option value="Pending">Pending</mat-option>
                            <mat-option value="In-progress">In-progress</mat-option>
                            <mat-option value="On-hold">On-hold</mat-option>
                            <mat-option value="Solved">Solved</mat-option>
                            <mat-option value="Closed">Closed</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button color="primary" matTooltip="Save"
                            (click)="requestStatusEdit = !update('status',requestStatus.value)">
                        <mat-icon color="success">check</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Cancel"
                            (click)="requestStatus.setValue(data.status);requestStatusEdit = false">
                        <mat-icon color="warn">cancel</mat-icon>
                    </button>
                </ng-template>
            </div>

            <div class="priority" [style.color]="getPriorityColor">
                <b>Priority: </b> <span *ngIf="!requestPriorityEdit">{{data.priority}}</span>

                <mat-icon *ngIf="!requestPriorityEdit" class="pointer edit-btn"
                    (click)="requestPriorityEdit = true">
                    edit
                </mat-icon>

                <ng-template [ngIf]="requestPriorityEdit">
                    <mat-form-field>
                        <mat-label>Priority</mat-label>
                        <mat-select [formControl]="requestPriority" required>
                            <mat-option value="Low">Low</mat-option>
                            <mat-option value="Normal">Normal</mat-option>
                            <mat-option value="High">High</mat-option>
                            <mat-option value="Critical">Critical</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <button mat-icon-button color="primary" matTooltip="Save"
                            (click)="requestPriorityEdit = !update('priority',requestPriority.value)">
                        <mat-icon color="success">check</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="Cancel"
                            (click)="requestPriority.setValue(data.priority);requestPriorityEdit = false">
                        <mat-icon color="warn">cancel</mat-icon>
                    </button>
                </ng-template>
            </div>

            <div class="url">
                <b>URL:</b>&nbsp;
                <a *ngIf="data.url && data.url.includes('http')"
                   [href]="data.url" target="_blank">
                    {{data.url}}
                </a>
                <a *ngIf="data.url && !data.url.includes('http')" [routerLink]="data.url">
                    {{data.url}}
                </a>

                <div *ngIf="data.partner && state.section.includes('warehouse')" class="padding-top-10">
                    Related partner:
                    <b>{{data.partner.display_name}}</b>
                </div>

                <div *ngIf="data.warehouse && state.section.includes('partner')" class="padding-top-10">
                    Related warehouse:
                    <b>{{data.warehouse.name}}</b>
                </div>

            </div>


            <div class="actions flex row align-center">
                <ng-template [ngIf]="data.status  !== 'Solved' && data.status  !== 'Closed'">
                    <div class="button-group">
                        <button mat-raised-button color="success" (click)="update('status','Solved')">
                            <mat-icon class="vertical-middle">done</mat-icon>
                            Resolve
                        </button>
                        <button mat-raised-button color="success"
                                [matMenuTriggerFor]="resolveMenu" type="button"
                                class="icon">
                            <mat-icon class="vertical-middle">expand_more</mat-icon>
                        </button>
                    </div>
                    <mat-menu #resolveMenu="matMenu">
                        <a mat-menu-item (click)="resolveMessage()">Resolve with message</a>
                        <a mat-menu-item (click)="update('status_quietly', 'Solved')">Resolve quietly</a>
                    </mat-menu>
                </ng-template>


                <button *ngIf="data.status === 'Solved'"
                        mat-raised-button color="success" (click)="update('status','Closed')">
                    <mat-icon class="vertical-middle">done</mat-icon>
                    Close request
                </button>

                <ng-template [ngIf]="data.status !== 'Closed'">
                    <div class="button-group">
                        <button mat-raised-button (click)="update('status','On-hold')">
                            <mat-icon class="vertical-middle">pan_tool</mat-icon>
                            Set on hold
                        </button>
                        <button mat-raised-button
                                [matMenuTriggerFor]="holdMenu" type="button"
                                class="icon">
                            <mat-icon class="vertical-middle">expand_more</mat-icon>
                        </button>
                    </div>

                    <mat-menu #holdMenu="matMenu">
                        <a mat-menu-item (click)="holdMessage()">Hold with message</a>
                        <a mat-menu-item (click)="reminder('On-hold')">Hold with reminder</a>
                    </mat-menu>

                    <input type="hidden" [ngxMatDatetimePicker]="remindPicker" [formControl]="remindDate"
                           [min]="remindDateMin">
                    <ngx-mat-datetime-picker #remindPicker [touchUi]="true">
                        <ng-template>
                            <span>Apply</span>
                        </ng-template>
                    </ngx-mat-datetime-picker>
                </ng-template>


                <button mat-mini-fab class="white" matTooltip="Favorite" (click)="favorite()">
                    <mat-icon color="{{ data.favorite.length > 0 ? 'accent' : ''}}">star</mat-icon>
                </button>
                <button mat-mini-fab class="white" matTooltip="Remind" (click)="reminder()">
                    <mat-icon>alarm</mat-icon>
                </button>
                <button mat-mini-fab class="white" matTooltip="Refresh" (click)="getData()">
                    <mat-icon>autorenew</mat-icon>
                </button>
                <button mat-mini-fab class="white" matTooltip="Delete" (click)="delete()">
                    <mat-icon color="warn">delete</mat-icon>
                </button>
                <a *ngIf="data.file_url" [href]="data.file_url" target="_blank">
                    <button mat-raised-button color="accent" matTooltip="Download attachment" type="button">
                        Download attachment
                    </button>
                </a>

                <div class="max"></div>

                <div>
                    <div>
                        <b>Last update:</b> {{data.updated_at}}
                    </div>
                    <span>
                    <b>Category:</b> {{remarkTypes["" + data.order_remark_type_id]}}
                    </span>

                    <div>
                        <common-timeline [from]="data.timeline_from ?? data.created_at" [to]="data.timeline_to"
                                         (onChange)="setTimeline($event)"></common-timeline>
                    </div>

                </div>
            </div>


            <div class="clear">

                <div class="columns">
                    <div class="left">
                        <div class="info-row">
                            <div>
                                <div class="avatar"
                                     [ngStyle]="{'background-image': 'url(' + (data.creator.avatar || '/assets/images/default-avatar.png') + ')'}">
                                </div>
                                <div class="title">
                                    <b>Created by</b>
                                    <mat-icon *ngIf="!requestCreatorEdit"
                                              class="pointer edit-btn"
                                              (click)="requestCreatorEdit = true">
                                        edit
                                    </mat-icon>
                                    <ng-template [ngIf]="requestCreatorEdit">
                                        <button mat-icon-button color="primary" matTooltip="Save"
                                                (click)="requestCreatorEdit = !update('creator',requestCreator.value)">
                                            <mat-icon color="success">check</mat-icon>
                                        </button>
                                        <button mat-icon-button matTooltip="Cancel"
                                                (click)="requestStatus.setValue(data.status);
                                                requestCreatorEdit = false">
                                            <mat-icon color="warn">cancel</mat-icon>
                                        </button>
                                    </ng-template>
                                </div>
                                <div *ngIf="!requestCreatorEdit">
                                    {{data.creator.name}}
                                </div>
                                <div *ngIf="requestCreatorEdit">
                                    <mat-form-field>
                                        <mat-label>Creator</mat-label>
                                        <mat-select [formControl]="requestCreator"
                                                    required>
                                            <mat-option>
                                                <ngx-mat-select-search
                                                        [formControl]="requestUserSearch"
                                                        [placeholderLabel]="'Search'"
                                                        [clearSearchInput]="true"
                                                        [noEntriesFoundLabel]="'No items found'">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let user of usersFiltered | async"
                                                        [value]="user.value">
                                                {{user.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div>
                                <div class="avatar"
                                     [ngStyle]="{'background-image': 'url('
                                         + (data.assignee ? data.assignee.avatar : '/assets/images/default-avatar.png')
                                         + ')'}">
                                </div>
                                <div class="title">
                                    <b>Assignee</b>
                                    <mat-icon *ngIf="!requestAssigneeEdit"
                                              class="pointer edit-btn"
                                              (click)="requestAssigneeEdit = true">
                                        edit
                                    </mat-icon>
                                    <ng-template [ngIf]="requestAssigneeEdit">
                                        <button mat-icon-button color="primary" matTooltip="Save"
                                                (click)="requestAssigneeEdit = !update('assignee',requestAssignee.value)">
                                            <mat-icon color="success">check</mat-icon>
                                        </button>
                                        <button mat-icon-button matTooltip="Cancel"
                                                (click)="requestStatus.setValue(data.status);
                                                requestAssigneeEdit = false">
                                            <mat-icon color="warn">cancel</mat-icon>
                                        </button>
                                    </ng-template>
                                </div>
                                <div *ngIf="!requestAssigneeEdit">
                                    {{data.assignee ? data.assignee.name : ''}}
                                </div>

                                <div *ngIf="requestAssigneeEdit">
                                    <mat-form-field>
                                        <mat-label>Assignee</mat-label>
                                        <mat-select [formControl]="requestAssignee"
                                                    required>
                                            <mat-option>
                                                <ngx-mat-select-search
                                                        [formControl]="requestUserSearch"
                                                        [placeholderLabel]="'Search'"
                                                        [clearSearchInput]="true"
                                                        [noEntriesFoundLabel]="'No items found'">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let user of usersFiltered | async"
                                                        [value]="user.value">
                                                {{user.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div *ngIf="data.team">
                                <div class="avatar">
                                    {{data.team.name.substr(0, 3)}}
                                </div>
                                <div class="title">
                                    <b>Team</b>
                                    <mat-icon *ngIf="!requestTeamEdit"
                                              class="pointer edit-btn"
                                              (click)="requestTeamEdit = true">
                                        edit
                                    </mat-icon>
                                    <ng-template [ngIf]="requestTeamEdit">
                                        <button mat-icon-button color="primary" matTooltip="Save"
                                                (click)="requestTeamEdit = !update('team',requestTeam.value)">
                                            <mat-icon color="success">check</mat-icon>
                                        </button>
                                        <button mat-icon-button matTooltip="Cancel"
                                                (click)="requestTeam.setValue(data.team.name);
                                                requestTeamEdit = false">
                                            <mat-icon color="warn">cancel</mat-icon>
                                        </button>
                                    </ng-template>
                                </div>
                                <div *ngIf="!requestTeamEdit">
                                    {{data.team.name}}
                                </div>
                                <div *ngIf="requestTeamEdit">
                                    <mat-form-field>
                                        <mat-select [formControl]="requestTeam" required>
                                            <mat-option *ngFor="let team of teams" [value]="team.value">
                                                {{team.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div *ngIf="data.request_kind">
                                <div class="avatar">
                                    {{ data.request_kind.name.substr(0, 3) }}
                                </div>
                                <div class="title">
                                    <b>Kind</b>
                                    <mat-icon *ngIf="!requestKindEdit"
                                              class="pointer edit-btn"
                                              (click)="requestKindEdit = true">
                                        edit
                                    </mat-icon>
                                    <ng-template [ngIf]="requestKindEdit">
                                        <button mat-icon-button color="primary" matTooltip="Save"
                                                (click)="requestKindEdit = !update('kind',requestKind.value)">
                                            <mat-icon color="success">check</mat-icon>
                                        </button>
                                        <button mat-icon-button matTooltip="Cancel"
                                                (click)="requestKind.setValue(data.request_kind.name);
                                                requestKindEdit = false">
                                            <mat-icon color="warn">cancel</mat-icon>
                                        </button>
                                    </ng-template>
                                </div>
                                <div *ngIf="!requestKindEdit">
                                    {{ data.request_kind.name }}
                                </div>
                                <div *ngIf="requestKindEdit">
                                    <mat-form-field>
                                        <mat-select [formControl]="requestKind" required>
                                            <mat-option *ngFor="let kind of kinds" [value]="kind.value">
                                                {{ kind.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div>
                                <div class="avatar">
                                    {{data.participants.length}}
                                </div>
                                <div class="title">
                                    <b>Participants</b>
                                    <mat-icon *ngIf="!requestParticipantsEdit"
                                              class="pointer edit-btn"
                                              (click)="requestParticipantsEdit = true">
                                        edit
                                    </mat-icon>
                                    <ng-template [ngIf]="requestParticipantsEdit">
                                        <button mat-icon-button color="primary" matTooltip="Save"
                                                (click)="requestParticipantsEdit = !update('participants',requestParticipants.value)">
                                            <mat-icon color="success">check</mat-icon>
                                        </button>
                                        <button mat-icon-button matTooltip="Cancel"
                                                (click)="cancelRequestParticipantsEdit();
                                                requestParticipantsEdit = false">
                                            <mat-icon color="warn">cancel</mat-icon>
                                        </button>
                                    </ng-template>
                                </div>
                                <div *ngIf="requestParticipantsEdit">
                                    <mat-form-field>
                                        <mat-label>Participants</mat-label>
                                        <mat-select [formControl]="requestParticipants" [multiple]="true">
                                            <mat-option>
                                                <ngx-mat-select-search
                                                        [formControl]="requestParticipantsSearch"
                                                        [placeholderLabel]="'Search'"
                                                        [clearSearchInput]="true"
                                                        [noEntriesFoundLabel]="'No items found'">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let user of sortedParticipants"
                                                        [value]="user.id">
                                                {{user.name}} - {{user.email}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <mat-divider class="relative"></mat-divider>

                        <div class="tags">
                            <h2 class="border-bottom-none">Tasks</h2>
                            <section-request-tasks [request_id]="data.id"
                                                   [mentionUsers]="mentionUsers"
                                                   (onChanges)="getData()">
                            </section-request-tasks>
                        </div>

                        <mat-divider class="relative"></mat-divider>

                        <div class="description">
                            <h2 class="border-bottom-none">Description
                                <mat-icon *ngIf="!requestDescriptionEdit" class="pointer edit-btn"
                                          (click)="requestDescriptionEdit = true">
                                    edit
                                </mat-icon>

                                <ng-template [ngIf]="requestDescriptionEdit">
                                    <button mat-icon-button color="primary" matTooltip="Save"
                                            (click)="requestDescriptionEdit = !update('message',requestDescription.value)">
                                        <mat-icon color="success">check</mat-icon>
                                    </button>
                                    <button mat-icon-button matTooltip="Cancel"
                                            (click)="requestDescription.setValue(data.description);requestDescriptionEdit = false">
                                        <mat-icon color="warn">cancel</mat-icon>
                                    </button>
                                </ng-template>
                            </h2>

                            <div class="description" *ngIf="!requestDescriptionEdit"
                                 [innerHTML]="data.description | linebreaks"></div>

                            <div *ngIf="requestDescriptionEdit">
                                <common-form-editor label="Description"
                                                    [value]="editorInitialValue"
                                                    (valueChange)="requestDescription.setValue($event)"
                                ></common-form-editor>
                            </div>
                        </div>

                        <mat-divider class="relative"></mat-divider>

                        <div class="margin-bottom-20">
                            <div class="pull-right">
                                <button type="button" mat-raised-button color="primary" (click)="newAttachment()">
                                    <mat-icon>add</mat-icon>
                                    New attachment
                                </button>
                            </div>
                            <h2 class="border-bottom-none">Attachments</h2>
                            <div class="clear"></div>
                            <button *ngIf="data.attachments?.length > 1" mat-raised-button color="accent"
                                    class="margin-bottom-10"
                                    (click)="downloadAllAttachments()"
                                    matTooltip="Download all" type="button">
                                Download all
                            </button>
                            &nbsp;
                            &nbsp;
                            <mat-card *ngFor="let att of data.attachments" class="margin-bottom-10">
                                <div>
                                    <div class="row flex">
                                        <div class="max">
                                            <div class="margin-bottom-10"><b>{{ att.name }}</b></div>
                                            <div>Uploaded at: {{ att.created_at }}</div>

                                        </div>
                                        <div class="margin-right-10">
                                            <a [href]="att.url" target="_blank">
                                                <button mat-raised-button color="accent"
                                                        matTooltip="Download attachment"
                                                        type="button">
                                                    <mat-icon>download</mat-icon>
                                                    Download
                                                </button>
                                            </a>
                                        </div>
                                        <div>
                                            <button mat-raised-button color="warn" (click)="deleteAttachment(att.id)"
                                                    matTooltip="Download attachment"
                                                    type="button">
                                                <mat-icon>delete</mat-icon>
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </mat-card>
                        </div>

                        <mat-divider class="relative"></mat-divider>

                        <div class="messages">
                            <mat-form-field class="pull-right">
                                <mat-label>Search</mat-label>
                                <input matInput type="search"
                                       [formControl]="messageSearch">
                            </mat-form-field>
                            <h2 class="border-bottom-none">Messages</h2>

                            <div class="text-right clear">
                                <button type="button" mat-raised-button color="primary"
                                        (click)="newMessage()">
                                    New message
                                </button>
                            </div>
                            <br>
                            <div *ngIf="messages">
                                <mat-card *ngFor="let msg of messages.data"
                                          class="message"
                                          [class.solution]="msg.solution">
                                    <div class="owner pull-left">
                                        <div class="avatar" *ngIf="msg.creator"
                                             [ngStyle]="{'background-image': 'url(' + (msg.creator.avatar || 'assets/images/default-avatar.png') + ')'}">
                                        </div>
                                        <div class="name">{{msg.creator ? msg.creator.name : msg.sender_name}}</div>
                                        <div class="date">{{msg.created_at}}</div>
                                    </div>
                                    <div class="pull-right" *ngIf="msg.order_remark_type">
                                        <div>
                                            <mat-icon class="vertical-middle">remove_red_eye</mat-icon>
                                            {{msg.order_remark_type.name}}
                                        </div>
                                        <br>
                                        <div *ngIf="!msg.solution" class="text-right">
                                            <button mat-mini-fab color="success"
                                                    (click)="markAsSolution(msg.id)"
                                                    matTooltip="Mark as solution">
                                                <mat-icon>check</mat-icon>
                                            </button>
                                        </div>
                                        <div *ngIf="msg.solution" class="solution-mark">
                                            Marked as solution
                                        </div>
                                    </div>
                                    <div class="clear message-text" [innerHTML]="msg.message | linebreaks"></div>

                                    <br>
                                    <button type="button" mat-raised-button (click)="editMessage(msg)"
                                            *ngIf="msg.creator && msg.creator.id === me.id">
                                        Edit
                                    </button>
                                </mat-card>

                                <common-pagination [data]="messages" [hidePerPage]="true"
                                                   pid="8Xv40fBL5c"
                                                   (goToPage)="getMessages($event.page,messageSearch.value)">
                                </common-pagination>

                                <div class="text-right clear">
                                    <button type="button" mat-raised-button color="primary"
                                            (click)="newMessage()">
                                        New message
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="right">
                        <mat-tab-group>
                            <mat-tab label="Activities">
                                <div *ngIf="activities; else elseBlock">
                                    <h2 matSubheader class="activities-header">Activities</h2>
                                    <div>
                                        <ng-template ngFor let-activity [ngForOf]="activities.data">
                                            <section-activity-view [activity]="activity"></section-activity-view>
                                            <mat-divider></mat-divider>
                                        </ng-template>
                                    </div>
                                    <common-pagination [data]="activities" [hidePerPage]="true"
                                                       pid="nd76K77dUH"
                                                       (goToPage)="getActivities($event.page)"></common-pagination>
                                </div>
                                <ng-template #elseBlock>
                                    <p>There is no any activity yet</p>
                                </ng-template>
                            </mat-tab>

                            <mat-tab label="Order remarks">
                                <h2 class="border-bottom-none">Order remarks:</h2>
                                <div class="remarks" *ngIf="remarks">
                                    <mat-form-field class="full">
                                        <mat-label>Search</mat-label>
                                        <input matInput type="text"
                                               [formControl]="remarkSearch"
                                        >
                                    </mat-form-field>
                                    <ng-template ngFor let-remark [ngForOf]="remarks.data">
                                        <common-section-remark [data]="remark"
                                                               [hideActions]="true"
                                                               [hideRequests]="true"
                                                               [orderRef]="data.order.ref"
                                                               (reloadEvent)="getRemarks()">
                                        </common-section-remark>
                                    </ng-template>
                                    <common-pagination [data]="remarks"
                                                       pid="D4BjoyT63"
                                                       (goToPage)="getRemarks($event.page, remarkSearch.value, $event.per_page)">

                                    </common-pagination>
                                </div>
                                <div *ngIf="!remarks">
                                    <p>No remarks available</p>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>
            </div>


        </ng-template>
    </mat-card-content>
</mat-card>
