import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from "@angular/core";
import {Api} from "../../../../../../common/services/api.service";
import {Modal} from "../../../../../services/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Api3Service} from "../../../../../../common/services/api3.service";

@Component({
    selector: "section-package-type-view",
    templateUrl: "form.component.html",
    styleUrls: [
        "form.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class PackageTypeFormComponent implements OnInit {

    public modal: Modal.IModal;

    public formGroup: FormGroup = new FormGroup({
        name: new FormControl(null, [Validators.required]),
        weight: new FormControl(null, [Validators.required, Validators.min(0)]),
        is_active: new FormControl(true)
    });

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    private prepareForm(): void {
        if (!this.modal.params.data) {
            return;
        }
        this.formGroup.patchValue(this.modal.params.data);
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Handler for form submit
     */
    public async submitForm(): Promise<any> {
        this.spinnerService.show();

        let res: Api.IResponse;

        if (this.modal.params.data) {
            res = await this.api3Service.put(
                `${this.modal.params.state.section}/warehouse-package-types/${this.modal.params.data.id}`,
                this.formGroup.value
            );
        } else {
            res = await this.api3Service.post(
                `${this.modal.params.state.section}/warehouse-package-types/`,
                this.formGroup.value
            );
        }

        this.spinnerService.hide();

        if (res.code === 200) {
            this.toastService.show(res.message, "success");
            this.close();
        }
    }

    public close(): void {
        this.modal.response.next({
            value: true,
            name: "res"
        });
    }

    public ngOnInit(): void {
        this.prepareForm();
    }
}
