import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AbstractWizardStepComponent, Wizard} from "../../../../../../../../common/interfaces/wizard.interface";
import {User} from "../../../../../../../../common/interfaces/user.interface";
import {Api, ApiService} from "../../../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../../../common/services/toast.service";
import {HelpersService} from "../../../../../../../../common/services/helpers.service";
import {Warehouse} from "../../../../../../../../common/interfaces/warehouse.interface";
import {Modal, ModalService} from "../../../../../../../services/modal.service";
import {WarehouseLocationFormComponent} from "../../../../locations";
import {COMMA, ENTER, SEMICOLON, TAB} from "@angular/cdk/keycodes";
import {ReplaySubject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../../../common/services/spinner.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Api3Service} from "../../../../../../../../common/services/api3.service";
import {Form} from "../../../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;
import {Base} from "../../../../../../../../common/interfaces/base.interfaces";
import {AppStateService} from "../../../../../../../../common/services/app-state.service";

@Component({
    selector: "section-warehouse-procedures-wizard-transfer-update-pallet",
    templateUrl: "update-pallet.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class WarehouseProceduresWizardTransferUpdatePalletComponent extends AbstractWizardStepComponent
    implements OnDestroy {

    private state: Base.IState;

    @ViewChild("newLocationName", {static: false})
    public newLocationNameInput: ElementRef;

    @ViewChild(MatAutocompleteTrigger, {static: false})
    public autocompleteTrigger: MatAutocompleteTrigger;

    public warehouse: Warehouse.IWarehouse;

    public subs: any[] = [];

    public locations: ISelectOption[] = [];

    public pallets: ISelectOption[] = [];

    public showLocations: boolean = false;

    public locationSearchInput: FormControl = new FormControl(null, [Validators.required]);

    public partners: ISelectOption[] = [];

    public partnerSelect: FormControl = new FormControl();

    public transferForm: FormGroup = new FormGroup({
        pallet_id: new FormControl(null, [Validators.required]),
        partner_id: new FormControl(null, [Validators.required]),
    });

    public constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        public helperService: HelpersService,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
        private spinnerService: SpinnerService
    ) {
        super(changeDetectorRef);
    }

    /**
     * Get all related sub-warehouses
     */
    private async getPartners(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "partners"], {}, {
                data_structure: "select"
            });

        this.spinnerService.hide();


        if (data) {
            this.partners = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get all related sub-warehouses
     */
    private async getSubWarehouses(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/sub-warehouses`, {}, {
                data_structure: "select"
            });
        if (data) {
            this.subs = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get all related locations
     */
    private async getWarehousesLocations(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/warehouse-locations`, {}, {
                type: "pallet",
                only_empty: true,
                data_structure: "select"
            });
        this.spinnerService.hide();

        if (data) {
            this.locations = data;

            if (this.locations.length === 1) {
                this.transferForm.get("warehouse_location_id").setValue(this.locations[0].value);
            }
            this.changeDetectorRef.markForCheck();
        }
    }

    private async getPallets(): Promise<any> {
        this.spinnerService.show();

        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `${this.state.section}/pallets`, {}, {
                partner_id: this.transferForm.value.partner_id,
                data_structure: "select",
                available_only: true
            });

        this.spinnerService.hide();

        if (data) {
            this.pallets = data;
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Submit form
     */
    public async submit(): Promise<any> {

        this.spinnerService.show();

        const {code, message}: Api.IResponse = await this.api3Service.request(Api.EMethod.Put,
            `${this.state.section}/pallets/${this.transferForm.value.pallet_id}`, this.transferForm.value);
        this.spinnerService.hide();

        if (code === 200) {
            this.toastService.show(message, "success");
            this.transferForm.reset();
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Add new location and select it
     */
    public async addLocation(value: string): Promise<any> {
        const response: Modal.IResponse = await this.modalService.open(WarehouseLocationFormComponent, {
            location_name: value,
            state: this.state
        });
        if (response) {
            await this.getWarehousesLocations();
            this.locationSearchInput.setValue(response.value);
            this.transferForm.get("warehouse_location_id").setValue(response.value.id);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Initialize step
     * @param data
     * @returns {Promise<any>}
     */
    public async init(data: Wizard.IData): Promise<any> {
        this.state = AppStateService.getState();

        this.warehouse = data.warehouse;

        if (data.scanType === "pallet-hub") {
            this.transferForm.addControl("sub_inventory_id",
                new FormControl(null, [Validators.required]));
            this.getSubWarehouses();
        } else if (data.scanType === "pallet-location") {
            this.transferForm.addControl("warehouse_location_id",
                new FormControl(null, [Validators.required]));

            this.getWarehousesLocations();
            this.showLocations = true;
        }
        this.transferForm.updateValueAndValidity();

        this.result.emit({
            action: "result",
            value: true
        });

        this.getPartners();

        this.transferForm.get("partner_id").valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe((value: number): void => {
                if (value) {
                    this.getPallets();
                }
            });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

}
