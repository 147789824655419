import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {Router} from "@angular/router";
import {ConfirmComponent} from "../../../../../../common/components/confirm/confirm.component";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {FileUploadComponent} from "../../../../../../common/components/file-upload/file-upload.component";
import {Modal, ModalService} from "../../../../../services/modal.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {FormControl} from "@angular/forms";
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";

import {CommonFormComponent} from "../../../../../../common/components/form";
import {StockFormComponent} from "../index";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {Table2Component} from "../../../../../../common/components/table2";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {User} from "../../../../../../common/interfaces/user.interface";
import {OrderItemStickerComponent} from "../../../../partner/components/order";
import {Form} from "../../../../../../common/interfaces/form.interface";
import {CommonPartMasterViewComponent} from "../../part-master";
import ISelectOption = Form.ISelectOption;
import {Api3Service} from "../../../../../../common/services/api3.service";
import {AmplitudeService} from "../../../../../../common/services/amplitude.service";
import {UserService} from "../../../../../../common/services/user.service";
import * as moment from "moment";


@Component({
    selector: "section-stock-detailed",
    templateUrl: "stock.component.html",
    styleUrls: ["stock.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockDetailedComponent implements Base.IComponent, OnInit, OnDestroy {

    private destroy$: EventEmitter<boolean> = new EventEmitter(false);

    private totalAllocations: string = "";

    private totalQuantity: string = "";

    private totalAvailable: string = "";

    private inventoryColumns: Table.ICol[] = [];

    public stockType: string = "detailed";

    public hubList: { name: string, value: any, name2: string }[];

    public subWarehouses: ISelectOption[];

    public hubSelect: FormControl = new FormControl([]);
    public subWarehouseSelect: FormControl = new FormControl([]);

    public availableOnly: FormControl = new FormControl(true);

    public partnerSelect: FormControl = new FormControl([]);

    public countries: any[] = [];

    public countrySelect: FormControl = new FormControl(null);

    public state: Base.IState;

    public partners: User.IPartner[] = [];

    @ViewChild(ConfirmComponent, {static: false})
    public confirmRef: ConfirmComponent;

    @ViewChild(Table2Component, {static: false})
    public tableRef: Table2Component;

    /**
     * Table / list options (api url, columns data & title etc)
     * @type {Table.ISettings}
     */
    public listTable: Table.ISettings;

    public searchParams: { search_by: string, search_in: string[] } = {
        search_by: null,
        search_in: []
    };
    public canAddInventory: boolean = true;

    public constructor(
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private modalService: ModalService,
        private changeDetectorRef: ChangeDetectorRef,
        private spinnerService: SpinnerService,
        private userService: UserService,
        private helpers: HelpersService,
    ) {
    }

    private get url(): { url: string[], query?: { [key: string]: any } } {
        return {
            url: ["inventory"],
            query: {
                hubs: this.hubSelect.value,
                warehouse_sub_inventories: this.subWarehouseSelect.value,
                partners: this.partnerSelect.value,
                country: this.countrySelect.value,
                use_transformer: true,
                available_only: this.availableOnly.value,
                relations: [
                    "PartMaster:id,description",
                    "BatchDetails:expiry_date,batch",
                    "WarehouseLocation:id,location,warehouse_location_type_id",
                    "WarehouseLocation.WarehouseLocationType:id,name",
                    "InventoryConversion:id,customers_inventory_name,customers_sub_inventory,properties,gb",
                    "Partner:id,display_name",
                    "Box:id,ref,pallet_id",
                    "Box.Pallet:id,ref"
                ],
                appends: ["quantity_free"],
            }
        };
    }

    private async getColumns(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["inventory"], {}, {
            data_structure: "datatables_columns"
        });
        if (data) {
            this.inventoryColumns = data;
        }
        this.prepareList();
        this.spinnerService.hide();
    }

    private prepareColumns(): Table.ICol[] {
        let columns: Table.ICol[];

        if (this.inventoryColumns.length > 0) {
            columns = this.inventoryColumns;
        } else {
            columns = [
                {
                    data: "item",
                    title: "Item"
                },

                {
                    data: "rev",
                    title: "Rev"
                },

                {
                    data: "part_master.description",
                    name: "PartMaster.description",
                    title: "Description",
                    sortable: false
                },

                {
                    data: "serial",
                    title: "Serial",
                },

                {
                    data: "lot",
                    title: "Lot"
                },

                {
                    data: "configurations",
                    title: "Configurations"
                },

                {
                    data: "batch",
                    title: "Batch"
                },
                {
                    data: "batch_details.expiry_date",
                    name: "BatchDetails.expiry_date",
                    title: "Batch expiry date",
                    searchable: false,
                    footer: (): string => {
                        return "Total:";
                    }
                },

                {
                    data: "quantity_free",
                    title: "Available",
                    sortable: false,
                    searchable: false,
                    footer: (): string => {
                        return this.totalAvailable;
                    }
                },

                {
                    data: "quantity",
                    title: "On hand",
                    footer: (): string => {
                        return this.totalQuantity;
                    },
                    searchable: false
                },

                {
                    data: "inventory_allocations_sum_quantity",
                    title: "Allocated",
                    render: (row: any): string => {
                        return row.inventory_allocations_sum_quantity ?? 0;
                    },
                    footer: (): string => {
                        return this.totalAllocations;
                    },
                    searchable: false
                },
                {
                    data: "part_master.declared_value",
                    title: "Declared value",
                    searchable: false,
                    sortable: false
                },
                {
                    data: "part_master.declared_value_currency",
                    title: "Currency",
                    searchable: false,
                    sortable: false
                },
                {
                    data: "warehouse_location.location",
                    name: "WarehouseLocation.location",
                    title: "Box Location",
                    sortable: false
                },

                {
                    data: "warehouse_location.warehouse_location_type.name",
                    name: "WarehouseLocation.WarehouseLocationType.name",
                    title: "Location Type",
                    sortable: false
                },

                {
                    data: "box.pallet.ref",
                    name: "Box.Pallet.ref",
                    title: "Pallet ref",
                    sortable: false
                },

                {
                    data: "box.ref",
                    name: "Box.ref",
                    title: "Box ref",
                    sortable: false
                },

                {
                    data: "inventory_conversion.customers_inventory_name",
                    name: "InventoryConversion.customers_inventory_name",
                    title: this.state.section_type === "partner" ? "Hub" : "Customers inventory name",
                    sortable: false
                },

                {
                    data: "inventory_conversion.customers_sub_inventory",
                    name: "InventoryConversion.customers_sub_inventory",
                    title: this.state.section_type === "partner" ? "Hub sub-inventory" : "Customers sub-inventory",
                    sortable: false
                },

                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false
                },

                {
                    data: "created_at",
                    title: "Age, days",
                    searchable: false,
                    render: (row: any): string => {
                        return "" + moment().diff(moment(row.created_at), "days");
                    },
                    exportable: false,
                    sortable: false
                }
            ];

            this.addColumnsBySectionSlug(this.state.section_slug, columns);

            if (this.state.section_type === "threepl" || this.state.section_type === "warehouse") {
                const ins: Table.ICol[] = [
                    {
                        data: "partner.display_name",
                        name: "Partner.display_name",
                        title: "Partner",
                        sortable: false
                    },

                    {
                        data: "inventory_conversion.warehouse_slug",
                        name: "InventoryConversion.warehouse_slug",
                        title: "Warehouse",
                        sortable: false
                    },

                    {
                        data: "inventory_conversion.sub_inventory",
                        name: "InventoryConversion.sub_inventory",
                        title: "Sub-inventory",
                        sortable: false
                    }
                ];

                const insert_after: number = columns.findIndex((col: Table.ICol): boolean => {
                    return col.data === "box.ref";
                });

                columns.splice(insert_after, 0, ...ins);
            }
        }

        if (this.state.section_type === "partner") {
            const serial_column: Table.ICol = {
                data: "serial",
                title: "Serial",
                render: (row: any): string => {
                    return row.serial
                        ? `<a class="tableAction">${row.serial}</a>`
                        : "";
                },
                click: async (row: any): Promise<any> => {
                    await this.modalService.open(CommonPartMasterViewComponent, {
                        item: row.item,
                        add: false,
                        serial: row.serial,
                        part_master_id: row.part_master_id,
                        modalWidth: 800,
                        modalHeight: 450,
                        state: this.state
                    });
                }

            };

            const serial_index: number = columns.findIndex((col: Table.ICol): boolean => {
                return col.data === "serial";
            });

            columns.splice(serial_index, 1, serial_column);
        }

        return columns;
    }

    /**
     * Prepare list/table
     * @returns {void}
     */
    private prepareList(): void {
        const actions: Table.IAction[] = [
            {
                title: "Print sticker",
                name: "print",
                click: (row: any): void => {
                    if (!row.part_master) {
                        row.part_master = {id: row.part_master_id, description: row.description};
                    }

                    this.modalService.open(OrderItemStickerComponent, {
                        item: row
                    });
                }
            }
        ];

        this.prepareColumns();

        this.listTable = {
            table_id: "e97pJrxT28U",
            actions,
            api: this.url,
            columns: this.prepareColumns(),
            export: {
                file_name: "Stock detailed"
            },
            search_default: ["item", "serial", "WarehouseLocation.location"],
            infinity_scroll: true
        };

        this.changeDetectorRef.markForCheck();
    }

    private async getHubList(country = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["hub"], {}, {country});
        if (data) {
            this.hubList = data.map((hub: Warehouse.IHub): any => {
                return {
                    name: `${hub.customers_inventory_name} - ${hub.customers_sub_inventory}`,
                    name2: `<b>${hub.customers_inventory_name}</b>/${hub.customers_sub_inventory}`,
                    value: hub.id
                };
            });
        }
        this.spinnerService.hide();
    }

    /**
     * Get related sub-warehouses
     * @param warehouse_slug
     */
    private async getSubWarehouses(warehouse_slug: string): Promise<any> {
        this.spinnerService.show();
        this.apiService.setHeaders({warehouse: warehouse_slug});
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["warehouse", "sub"]);
        if (data) {
            this.subWarehouses = data.map((sub: any): any => {
                return {
                    name: `${sub.warehouse.name} - ${sub.sub_inventory}`,
                    value: sub.sub_inventory
                };
            });
        }
        this.spinnerService.hide();
    }

    /**
     * Get warehouse partners
     */
    private async getWarehousePartners(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["warehouse", "partners"]);
        if (data) {
            this.partners = data.map((partner: User.IPartner): any => {
                return {name: partner.display_name, value: partner.id};
            });
        }
        this.spinnerService.hide();
    }

    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    private async getTotalQuantity(): Promise<any> {
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["inventory"], {}, {
            data_structure: "sum",
            column: "quantity",
            hubs: this.hubSelect.value,
            warehouse_sub_inventories: this.subWarehouseSelect.value,
            partners: this.partnerSelect.value,
            available_only: this.availableOnly.value,
            ...this.searchParams
        });

        if (data) {
            this.totalQuantity = "" + data[0];
            if (this.totalAllocations !== "Loading...") {
                this.totalAvailable = "" + (data[0] - parseInt(this.totalAllocations));
            }
            this.tableRef.detectChanges();
        }
    }

    private async getTotalAllocations(): Promise<any> {
        if (this.availableOnly.value) {
            this.totalAllocations = "0";
            this.totalAvailable = this.totalQuantity;

            this.tableRef.detectChanges();
            return;
        }
        this.tableRef.detectChanges();
        const {data}: Api.IResponse = await this.api3Service.request(Api.EMethod.Get,
            `/${this.state.section}/inventories/allocations`,
            {}, {
                data_structure: "sum",
                column: "quantity",
                type: "outbound",
                hubs: this.hubSelect.value,
                ...this.searchParams
            });

        if (data) {
            this.totalAllocations = "" + data[0];
            if (this.totalQuantity !== "Loading...") {
                this.totalAvailable = "" + (parseInt(this.totalQuantity) - data[0]);
            }
            this.tableRef.detectChanges();
        }
    }

    private addColumnsBySectionSlug(slug: string, columns: Table.ICol[]): void {
        let ins: Table.ICol[] = [];
        let insertBeforeIndex = columns.length;

        switch (slug) {
            case "highcon": {
                ins = [
                    {
                        data: "part_master.family",
                        name: "PartMaster.family",
                        title: "Family",
                        sortable: false
                    },

                    {
                        data: "part_master.group",
                        name: "PartMaster.group",
                        title: "Group",
                        sortable: false
                    },
                ];

                insertBeforeIndex = columns.findIndex((col: Table.ICol): boolean => {
                    return col.data === "serial";
                });
                break;
            }
        }

        if (ins.length > 0) {
            columns.splice(insertBeforeIndex, 0, ...ins);
        }
    }

    public updateTotals($event): void {
        if ($event.page > 1) {
            return;
        }
        this.totalAllocations = "Loading...";
        this.totalQuantity = "Loading...";
        this.totalAvailable = "Loading...";
        this.tableRef.detectChanges();

        this.getTotalAllocations();
        this.getTotalQuantity();
    }

    public async addPart(): Promise<any> {
        const [type, slug]: string[] = this.state.section.split("/");
        const response: Modal.IResponse = await this.modalService.open(StockFormComponent, {
            add: true,
            partner: {type: type, slug: slug},
            state: this.state,
            action: "add"
        });
        if (response) {
            this.tableRef.reload();
        }
        AmplitudeService.eventClick("Add new " + this.stockType);
    }

    public async editPart(data: any): Promise<any> {
        const [type, slug]: string[] = this.state.section.split("/");
        const response: Modal.IResponse = await this.modalService.open(StockFormComponent, {
            add: false,
            partner: {type: type, slug: slug},
            state: this.state,
            action: "edit",
            data: data
        });
        if (response) {
            this.tableRef.reload();
        }
    }

    public async report(): Promise<any> {
        await this.modalService.open(CommonFormComponent, {
            configUrl: ["report", "inventories-list"],
            submitUrl: ["report", "inventories-list"],
            asyncKey: HelpersService.randomString()
        });
    }

    public async upload(): Promise<any> {
        this.getHubList().then(async (): Promise<any> => {
            let footer: string = "<h3>Available hubs <small>(<b>Hub</b>/Hub sub-inventory)</small></h3>" +
                "<div class='mat-nav-list'> ";
            for (const hub of this.hubList) {
                footer += "<div class='mat-list-item'>" + hub.name2 + "</div>";
            }
            footer += "</div>";

            const response: Modal.IResponse = await this.modalService.open(FileUploadComponent, {
                templatePath: "assets/images/stock_list.csv",
                accept: ".csv",
                url: ["inventory"],
                async: true,
                footer
            });
            if (response && response.value) {
                this.toastService.show(response.value.message, response.value.type as string);
                this.tableRef.reload();
            }
        });
        AmplitudeService.eventClick("Upload CSV " + this.stockType);
    }

    public ngOnInit(): void {
        const [sectionType, sectionSlug]: string[] = this.state.section.split("/", 2);

        this.canAddInventory = !!this.userService.data.roles.find((r) => r.name === "superadmin");

        this.getHubList();
        this.prepareCountriesList();

        if (sectionType === "warehouse") {
            this.getSubWarehouses(sectionSlug);
            this.getWarehousePartners();
        }

        this.getColumns();

        this.subWarehouseSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.availableOnly.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.partnerSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.hubSelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe((value: any[]): void => {
                this.tableRef.reload(this.url);
            });

        this.countrySelect.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(500))
            .subscribe(async (value: any): Promise<void> => {
                if (this.countries.findIndex((country: any) => {
                    return country === value;
                })) {
                    await this.getHubList(value);
                    this.tableRef.reload(this.url);
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "stock-detailed",
            actions: {
                "browse": ["browse_inventories"]
            }
        };
    }
}
