import {ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {Modal} from "../../../../../services/modal.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";
import {HelpersService} from "../../../../../../common/services/helpers.service";


@Component({
    selector: "section-pallet-sticker",
    template: `
        <div class="pull-right">
            <button mat-raised-button color="accent" (click)="print()">
                <mat-icon>print</mat-icon>
          2      Print
            </button>
        </div>
        <h1 class="container-heading">Pallet sticker</h1>
        <mat-card>
            <mat-card-content #sticker>
                <div class="sticker" *ngFor="let pallet of pallets; let i = index">
                    <table>
                        <tr *ngIf="pallet.partner && pallet.partner.logo_path">
                            <td colspan="3" class="text-center">
                                <img [src]="pallet.partner.logo_path" alt="" class="logo">
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center">
                                <div>Outerbox/Pallet#:<br><b>{{ pallet.ref }}</b></div>
                                <common-barcode [bcValue]="pallet.ref" [bcDisplayValue]="false"
                                                bcWidth="1"
                                                [bcMarginTop]="1"
                                                [bcMarginLeft]="1"
                                                [bcMarginRight]="1"
                                                [bcMarginBottom]="1"
                                                bcHeight="30"></common-barcode>
                            </td>
                            <td class="text-center">
                                <div>Items QTY (pieces):<br><b>{{ pallet.inventories_count }}</b></div>
                                <common-barcode [bcValue]="pallet.inventories_count" [bcDisplayValue]="false"
                                                bcWidth="1"
                                                [bcMarginTop]="1"
                                                [bcMarginLeft]="1"
                                                [bcMarginRight]="1"
                                                [bcMarginBottom]="1"
                                                bcHeight="30"></common-barcode>
                            </td>
                        </tr>
                        <tr *ngIf="pallet.inventory">
                            <td class="text-center" colspan="2">
                                <div>Item#:<br><b>{{ pallet.inventory.item }}</b></div>
                                <common-barcode [bcValue]="pallet.inventory.item" [bcDisplayValue]="false"
                                                bcWidth="1"
                                                [bcMarginTop]="1"
                                                [bcMarginLeft]="1"
                                                [bcMarginRight]="1"
                                                [bcMarginBottom]="1"
                                                bcHeight="30"></common-barcode>
                            </td>
                        </tr>
                    </table>
                    <table *ngIf="pallet.boxes.length">
                        <tr>
                            <td colspan="2">
                                Boxes:
                            </td>
                        </tr>
                        <ng-template ngFor let-box
                                     [ngForOf]="pallet.boxes | filter:'warehouse_location_id':'!==':null">
                            <tr>
                                <td style="width:80%;" class="text-center">
                                    <common-barcode [bcValue]="box.ref" [bcDisplayValue]="false"
                                                    [bcMarginTop]="1"
                                                    [bcMarginLeft]="1"
                                                    [bcMarginRight]="1"
                                                    [bcMarginBottom]="1"
                                                    bcWidth="1"
                                                    bcHeight="20"></common-barcode>
                                    <div style="font-size: 7pt; margin-top: 1px;">{{ box.ref }}</div>
                                </td>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    styleUrls: [
        "sticker.component.scss"
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PalletStickerComponent implements OnInit {

    public modal: Modal.IModal;

    public pallets: Warehouse.IPallet[] = [];

    public qty: number = 0;

    @ViewChild("sticker", {static: false})
    public sticker: ElementRef;

    public print(): void {
        const printContents: any = this.sticker.nativeElement.innerHTML;
        HelpersService.stickerPopUp(printContents);
    }


    public ngOnInit(): void {
        this.pallets = this.modal.params.pallets;
    }

}
