import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {UserService} from "./user.service";
import {Observable, Subject} from "rxjs";
import Echo from "laravel-echo";
import {StorageService} from "./storage.service";
import {ToastService} from "./toast.service";
import {Push} from "../interfaces/push.interface";
import {NotificationService} from "./notification.service";

// @ts-ignore
window.Pusher = require("pusher-js");

@Injectable()
export class PusherService {
    private subject: Subject<any> = new Subject();

    public event: Observable<any> = this.subject.asObservable();

    public echo: any;

    public constructor(
        private userService: UserService,
        private storageService: StorageService,
        private toastService: ToastService,
        private notificationService: NotificationService
    ) {

        let url: string = environment.apiUrl;

        if (!url) {
            switch (window.location.host) {
                case "scaas.logivice.com":
                    url = "https://v2.logivice.com";
                    break;
                case "app.logivice.net":
                    url = "https://logivice.net";
                    break;
                case "qa.logivice.net":
                    url = "https://qa-serv.logivice.net";
                    break;
                default:
                    url = "localhost";
            }
        }

        let options: any = {
            broadcaster: "pusher",
            authEndpoint: url + "/broadcasting/auth",
            auth: {
                headers: {
                    "Authorization": this.storageService.get("token")
                }
            },
        };

        if (!environment.broadcast) {
            environment.broadcast = "pusher";
        }

        options = {...options, ...environment[environment.broadcast][environment.server]};

        this.echo = new Echo(options);
    }

    private addNotification(data: Push.IData): void {
        this.subject.next(data);

        if (data.job_type !== "action") {
            this.notificationService.addNotification(data);
            if (data.job_type !== "progressbar") {
                this.toastService.show({message: data.message, url: data.url}, data.message_type);
            }
        }
    }

    public joinUserChannel(): void {

        this.echo.private("app.user." + this.userService.data.id)
            .listen(".App\\Events\\FrontJobResponseEvent", (data: Push.IData): void => {
                // console.warn("user channel", data);
                this.addNotification(data);
            })
            .listen(".App\\Events\\FrontActivityEvent", (data: Push.IData): void => {
                // console.warn("user channel", data);
                this.addNotification(data);
            })
            .pusher.connection
            .bind("state_change", (states: any): void => {
                const currState: string = states.current;
                if (currState === "connected") {
                    // console.warn("connected to user channel");
                } else if (currState === "disconnected") {
                    this.toastService.show("Websocket disconnected from user channel", "error");
                }
            });
    }

    public joinBroadcastChannel(): void {

        this.echo.private("broadcast-channel")
            .listen(".App\\Events\\FrontJobResponseEvent", (data: Push.IData): void => {
                // console.warn("broadcast-channel", data);
                this.addNotification(data);
            })
            .pusher.connection
            .bind("state_change", (states: any): void => {
                const currState: string = states.current;
                if (currState === "connected") {
                    // console.warn("connected to broadcast channel");
                } else if (currState === "disconnected") {
                    this.toastService.show("Websocket disconnected from broadcast channel", "error");
                }
            });
    }

    public joinPartnerChannel(partner_slug: string): void {

        this.echo.private("broadcast-partner-" + partner_slug)
            .listen(".App\\Events\\FrontJobResponseEvent", (data: Push.IData): void => {
                // console.warn("partner-channel", data);
                this.addNotification(data);
            })
            .pusher.connection
            .bind("state_change", (states: any): void => {
                const currState: string = states.current;
                if (currState === "connected") {
                    // console.warn("connected to partner channel " + partner_slug);
                } else if (currState === "disconnected") {
                    this.toastService.show("Websocket disconnected from broadcast channel", "error");
                }
            });
    }

    public leaveUserChannel(): void {
        this.echo.leave("app.user." + this.userService.data.id);
    }

    public leaveBroadcastChannel(): void {
        this.echo.leave("broadcast-channel");
    }

    public leavePartnerChannel(partner_slug: string): void {
        this.echo.leave("broadcast-partner-" + partner_slug);
    }

}
