import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {Modal} from "../../../../../services/modal.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {HelpersService} from "../../../../../../common/services/helpers.service";
import {ToastService} from "../../../../../../common/services/toast.service";
import {takeUntil} from "rxjs/operators";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {TIME_ZONES} from "../../../../../../common/common.module";
import {Form} from "../../../../../../common/interfaces/form.interface";
import ISelectOption = Form.ISelectOption;

@Component({
    selector: "section-tracking-status",
    templateUrl: "status.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TrackingStatusComponent implements OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public modal: Modal.IModal;

    public formGroup: FormGroup;

    public countries: any[];

    public statuses: string[] = [
        "Info Received",
        "In Transit",
        "Out for Delivery",
        "Failed Attempt",
        "Delivered",
        "Exception",
        "Expired",
        "Pending",
        "Canceled",
    ];

    public timezones: ISelectOption[] = [];

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private apiService: ApiService,
        public helpers: HelpersService,
        private toastService: ToastService,
        private spinnerService: SpinnerService
    ) {
    }

    /**
     * Prepare form group (create)
     * @returns {void}
     */
    private prepareForm(): void {
        const data: any = this.modal.params.data ? this.modal.params.data : {};

        const timezone: string = this.modal.params.timezone ?? "Asia/Jerusalem";

        this.formGroup = new FormGroup<any>({
            status: new FormControl(data.status, [Validators.required]),
            messages: new FormControl(data.messages),
            location: new FormControl(data.location, [Validators.required]),
            pickup_date: new FormControl((new Date()).toISOString(), [Validators.required]),
            pickup_date_tz: new FormControl(timezone, [Validators.required]),
            expected_delivery: new FormControl(data.expected_delivery),
            expected_delivery_tz: new FormControl(timezone, [Validators.required]),
            attachment: new FormControl(null),
        });

        if (data.status === "delivered") {
            this.formGroup.addControl("pod",
                new FormControl(data.pod, [Validators.required]));
            this.formGroup.addControl("delivered_at",
                new FormControl(data.delivered_at, [Validators.required]));
        }

        this.changeDetectorRef.markForCheck();
    }

    /**
     * Prepare countries (as control options)
     * @returns {Promise<any>}
     */
    private async prepareCountriesList(): Promise<any> {
        this.spinnerService.show();
        this.countries = await this.helpers.prepareCountriesList();
        this.spinnerService.hide();
        this.changeDetectorRef.markForCheck();
    }

    /**
     * Prepare form body data
     * @returns {any}
     */
    private prepareBody(): any {
        const body: any = {...this.formGroup.value};
        if (body.expected_delivery) {
            body.expected_delivery = body.expected_delivery.format("YYYY-MM-DD HH:mm:ss");
        }
        if (body.delivered_at) {
            body.delivered_at = body.delivered_at.format("YYYY-MM-DD HH:mm:ss");
        }
        if (body.attachment) {
            body.attachment = body.attachment.id;
        }
        return body;
    }

    /**
     * Handle autocomplete selected value event
     * @param $event
     */
    public onCountrySelected($event: any): void {
        this.formGroup.get("location").setValue($event.value);
    }

    public onResult(value: any): void {
        if (value.code === 200) {
            this.formGroup.get("attachment").setValue(value.data);
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Submit form
     * @returns {Promise<any>}
     */
    public async handleFormSubmit(): Promise<any> {
        if (this.formGroup && this.formGroup.valid) {
            this.spinnerService.show();
            const response: Api.IResponse = await this.apiService.request(Api.EMethod.Put,
                ["courier", "transactions", this.modal.params.data.id], this.prepareBody());
            this.spinnerService.hide();
            if (response) {
                this.toastService.show(response.message, response.type);

                this.modal.response.next({
                    name: "value",
                    value: {}
                });
            }

        }
    }


    public ngOnInit(): void {
        if (this.modal.params.statuses) {
            this.statuses = this.modal.params.statuses;
        }

        for (const tz of Object.keys(TIME_ZONES)) {
            this.timezones.push({
                value: tz,
                name: TIME_ZONES[tz]
            });
        }

        this.prepareCountriesList();
        this.prepareForm();

        this.formGroup.get("status").valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((value: string): void => {
                if (value === "delivered") {
                    this.formGroup.addControl("pod",
                        new FormControl(this.modal.params.data.pod, [Validators.required]));
                    this.formGroup.addControl("delivered_at",
                        new FormControl(this.modal.params.data.delivered_at || (new Date()).toDateString(),
                            [Validators.required]));
                } else {
                    this.formGroup.removeControl("pod");
                    this.formGroup.removeControl("delivered_at");
                }
                this.changeDetectorRef.markForCheck();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

}
