<h1 class="container-heading">Stock list summarized</h1>
<mat-card>
    <mat-card-content>
        <common-table2 *ngIf="listTable" [settings]="listTable" >
            <div class="flex row" row1>
                <common-form-select *ngIf="hubList && hubList.length"
                                    [options]="hubList"
                                    [multiple]="true"
                                    [selectAll]="true"
                                    [search]="true"
                                    [value]="hubSelect.value"
                                    (onClose)="hubSelect.setValue($event)"
                                    label="Hubs"></common-form-select>
                &nbsp;
                &nbsp;
                <div class="padding-top-10">
                    <mat-checkbox [formControl]="availableOnly">Show available only</mat-checkbox>
                </div>
            </div>
        </common-table2>
    </mat-card-content>
</mat-card>
