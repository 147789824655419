import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {Base} from "../../../../../../common/interfaces/base.interfaces";
import {Router} from "@angular/router";
import {Api, ApiService} from "../../../../../../common/services/api.service";
import {User} from "../../../../../../common/interfaces/user.interface";
import {Order} from "../../../../../../common/interfaces/order.interface";
import {IPagination} from "../../../../../../common/components/pagination/pagination.component";
import {FormControl} from "@angular/forms";
import {debounceTime, takeUntil} from "rxjs/operators";
import {ToastService} from "../../../../../../common/services/toast.service";
import {SpinnerService} from "../../../../../../common/services/spinner.service";
import {UserService} from "../../../../../../common/services/user.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {Table} from "../../../../../../common/interfaces/table.interface";
import {Api3Service} from "../../../../../../common/services/api3.service";
import {Warehouse} from "../../../../../../common/interfaces/warehouse.interface";

interface UIPartner extends User.IPartner {
    isActive: boolean;
}

interface UIThreePl extends User.IThreepl {
    isActive: boolean;
    activeWarehousesCount: number;
}

interface UIRole extends User.IRole {
    isActive: boolean;
}

@Component({
    selector: "section-user-view",
    templateUrl: "view.component.html",
    styleUrls: [
        "view.component.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class UserViewComponent implements Base.IComponent, OnInit, OnDestroy {

    /**
     * Component destroy event emitter
     * @type {EventEmitter<boolean>}
     */
    private destroy$: EventEmitter<boolean> = new EventEmitter<boolean>();

    public user: User.IData;

    public readonly state: Base.IState;

    /**
     * Selector for user info section
     * @type {number}
     */
    public showSection: number = 1;

    public userOrders: IPagination<Order.IOrderData>;

    public remarks: IPagination<Order.IRemark>;

    public orderSearch: FormControl = new FormControl(null);

    public remarkSearch: FormControl = new FormControl(null);
    public roleSearch: FormControl = new FormControl(null);

    public threeplsList: UIThreePl[] = [];

    public partners: UIPartner[] = [];
    public rolesList: UIRole[] = [];
    public filteredRoles: UIRole[] = [];

    public logsTableSettings: Table.ISettings;

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private apiService: ApiService,
        private api3Service: Api3Service,
        private toastService: ToastService,
        private spinnerService: SpinnerService,
        private userService: UserService
    ) {
    }

    /**
     * Get user data
     * @returns {Promise<any>}
     */
    private async getData(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["user", this.state.params.id]);

        if (data) {
            this.user = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private prepareLogsTable(): void {
        this.logsTableSettings = {
            table_id: "userslogstable",
            api: {
                url: ["/admin", "users", this.state.params.id, "logs"],
                query: {
                    relations: [
                        "Author"
                    ],
                },
                version: 3
            },
            columns: [
                {
                    data: "subject",
                    title: "Subject"
                },
                {
                    data: "log",
                    title: "Log"
                },
                {
                    data: "author.name",
                    name: "Author.name",
                    title: "Performed by",
                    sortable: false
                },
                {
                    data: "created_at",
                    title: "Created at",
                    searchable: false
                },
            ],
            sort_default: {
                data: "created_at",
                dir: "desc"
            }
        };
    }

    /**
     * Get threepls list
     * @returns {Promise<any>}
     */
    private async getThreePLsList(search: string = null): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["threepl", "all"], {}, {
            relations: [
                "warehouses:id,name,3pl_id"
            ]
        });

        if (data) {
            this.threeplsList = data;

            this.countActiveThreepls();
            this.countActiveWhs();

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    private countActiveThreepls(): void {
        const exIds: number[] = this.user.threepls.map((threepl: User.IThreepl): number => {
            return threepl.id;
        });

        this.threeplsList = this.threeplsList.map((threePl: UIThreePl) => {
            return Object.assign(threePl, {
                isActive: exIds.indexOf(threePl.id) >= 0
            });
        });
    }

    /**
     * Get partners list
     * @returns {Promise<any>}
     */
    private async getPartnersList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["partner"]);

        if (data) {
            const exIds: number[] = this.user.partners.map((partner: User.IPartner): number => {
                return partner.id;
            });
            this.partners = data.map((partner: User.IPartner) => {
                return Object.assign(partner, {
                    isActive: exIds.indexOf(partner.id) >= 0
                });
            });

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Get roles list
     * @returns {Promise<any>}
     */
    private async getRolesList(): Promise<any> {
        this.spinnerService.show();
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get, ["role"]);

        if (data) {
            const exIds: number[] = this.user.roles.map((role: User.IRole): number => {
                return role.id;
            });
            this.filteredRoles = this.rolesList = data.map((role: User.IRole) => {
                return Object.assign(role, {
                    isActive: exIds.indexOf(role.id) >= 0
                });
            });

            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Update roles list linked to user
     * @returns {Promise<any>}
     */
    private async updateRole(action: string, role: User.IRole): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.api3Service[action](
            `/admin/users/${this.user.id}/roles/${role.id}`);
        if (data) {
            this.toastService.show(message, "success");
            this.user.roles = data.roles;
            this.getRolesList();
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();
    }

    /**
     * Update threepls list linked to user
     * @returns {Promise<any>}
     */
    private async updateThreePL(action: string, threepl: User.IThreepl): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.api3Service[action](
            `/admin/users/${this.user.id}/threepls/${threepl.id}`, {
                relations: [
                    "threepls:id"
                ]
            });

        this.spinnerService.hide();

        if (data) {
            this.user.threepls = data.threepls;
            this.changeDetectorRef.markForCheck();
        }
    }

    private async updateWarehouse(
        action: string,
        warehouse: Warehouse.IWarehouse,
        relations: string[] = []
    ): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.api3Service[action](
            `/admin/users/${this.user.id}/warehouses/${warehouse.id}`, {
                relations
            });

        this.spinnerService.hide();

        if (data) {
            this.user.warehouses = data.warehouses;

            this.countActiveWhs();

            this.changeDetectorRef.markForCheck();
        }

    }

    private countActiveWhs(): void {
        const exIds: number[] = this.user.warehouses.map((wh: Warehouse.IWarehouse): number => {
            return wh.id;
        });

        for (const threepl of this.threeplsList) {
            threepl.activeWarehousesCount = 0;

            for (const wh of threepl.warehouses) {
                if (exIds.indexOf(wh.id) >= 0) {
                    threepl.activeWarehousesCount++;
                }
            }
        }
    }

    /**
     * Update partners list linked to user
     * @returns {Promise<any>}
     */
    private async updatePartner(action: string, partner: User.IPartner): Promise<any> {
        this.spinnerService.show();
        const {data, message}: Api.IResponse = await this.api3Service[action](
            `/admin/users/${this.user.id}/partners/${partner.id}`);

        this.spinnerService.hide();

        if (data) {
            this.user.partners = data.partners;
            this.getPartnersList();
            this.changeDetectorRef.markForCheck();
        }
    }

    /**
     * Get user orders list
     * @param {number} page
     * @param per_page
     * @param {string} search
     * @returns {Promise<any>}
     */
    public async getUserOrders(page: number = 1, search: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["order"], {}, {
                filter_by_user: this.user.id,
                data_structure: "paginated",
                page,
                per_page,
                search_by: search
            });

        if (data) {
            this.userOrders = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();

    }

    /**
     * Get user remarks
     * @param {number} page
     * @param per_page
     * @param {string} search
     * @returns {Promise<any>}
     */
    public async getUserRemarks(page: number = 1, search: string = null, per_page: number = null): Promise<any> {
        this.spinnerService.show();
        if (per_page === null) {
            per_page = this.userService.data.settings.default_per_page;
        }
        const {data}: Api.IResponse = await this.apiService.request(Api.EMethod.Get,
            ["remark", "order"], {}, {
                filter_by_user: this.user.id,
                data_structure: "paginated",
                page,
                per_page,
                search_by: search
            });

        if (data) {
            this.remarks = data;
            this.changeDetectorRef.markForCheck();
        }
        this.spinnerService.hide();

    }

    public init(): void {
        this.getData().then((): void => {
            this.getUserOrders();
            this.getUserRemarks();
            this.getThreePLsList();
            this.getRolesList();
            this.getPartnersList();
            this.prepareLogsTable();
        });
    }

    public userHasWh(wh_id: number): boolean {
        return !!this.user.warehouses.find((wh: Warehouse.IWarehouse): boolean => {
            return wh.id === wh_id;
        });
    }

    public async ngOnInit(): Promise<any> {
        this.init();


        this.orderSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (this.user) {
                    this.getUserOrders(1, value);
                }
            });

        this.remarkSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (this.user) {
                    this.getUserRemarks(1, value);
                }
            });


        this.roleSearch.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(500))
            .subscribe((value: string): void => {
                if (this.user) {
                    this.filteredRoles = this.rolesList.filter(r => {
                        return r.display_name.toLowerCase().includes(value.toLowerCase());
                    });
                    this.changeDetectorRef.markForCheck();
                }
            });
    }

    public togglePartner(partner: UIPartner, value: MatSlideToggleChange): void {
        this.updatePartner(value.checked ? "post" : "delete", partner);
    }

    public toggleThreePl(threepl: UIThreePl, value: MatSlideToggleChange): void {
        this.updateThreePL(value.checked ? "post" : "delete", threepl);
    }

    public toggleWarehouse(warehouse: Warehouse.IWarehouse, value: { checked: boolean }): void {
        this.updateWarehouse(value.checked ? "post" : "delete", warehouse, ["warehouses:id"]);
    }

    public async toggleAllWhs(threepl: UIThreePl, checked: boolean): Promise<any> {
        for (const wh of threepl.warehouses) {
            await this.updateWarehouse(checked ? "post" : "delete", wh, ["warehouses:id"]);
        }
        this.getData();
    }

    public toggleRole(role: UIRole, value: MatSlideToggleChange): void {
        this.updateRole(value.checked ? "post" : "delete", role);
    }

    public ngConfig(): Base.IConfig {
        return {
            name: "user",
            actions: {
                "view": ["read_users"]
            }
        };
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }


}
