<common-confirm></common-confirm>
<common-alert></common-alert>

<div class="flex row content-stretch">
    <mat-card class="max">
        <div [formGroup]="formGroup" class="flex col">
            <div *ngIf="order" class="addresses margin-bottom-20 max">
                <mat-card *ngFor="let address of orderAaddresses; let addrIndex = index" class="address">
                    <div>
                        <div class="item" [ngSwitch]="address.address_type">
                            <span class="label">Type: </span>
                            <div *ngSwitchCase="'main'">Ship To</div>
                            <div *ngSwitchDefault>{{ address.address_type }}</div>
                        </div>
                        <div class="item" *ngIf="address.address_name">
                            <span class="label">Site name: </span>
                            {{ address.address_name }}
                        </div>
                        <div class="type" *ngIf="address.ref">
                            <span class="label">Ref: </span>
                            {{ address.ref }}
                        </div>
                        <div class="item">
                            <span class="label">Address: </span>
                            <div>
                            <span *ngIf="address.country_iso_2" class="flag">
                                <img height="33px"
                                     [src]="'assets/images/flags/' + address.country_iso_2.toLowerCase() + '.svg'">
                            </span>
                                <ng-template [ngIf]="address.address">
                                    <ng-template [ngIf]="address.address">
                                        {{ address.address }}
                                    </ng-template>
                                    <ng-template [ngIf]="address.address2">
                                        {{ address.address2 }}
                                    </ng-template>
                                    ,
                                    <ng-template [ngIf]="address.city">
                                        {{ address.city }},
                                    </ng-template>
                                    <ng-template [ngIf]="address.zip">
                                        {{ address.zip }}
                                    </ng-template>
                                    <ng-template [ngIf]="address.state">
                                        {{ address.state }}
                                    </ng-template>
                                    <ng-template [ngIf]="address.country">
                                        {{ address.country }}
                                    </ng-template>
                                </ng-template>
                                <div class="validation">
                                    <mat-chip-listbox *ngIf="address.valid">
                                        <mat-chip-option matChipRemove color="success" selected="true">
                                            Valid
                                        </mat-chip-option>
                                    </mat-chip-listbox>

                                    <ng-template [ngIf]="!address.valid">
                                        <mat-chip-listbox>
                                            <mat-chip-option matChipRemove color="warn" selected="true">
                                                Invalid
                                            </mat-chip-option>
                                        </mat-chip-listbox>

                                        <button mat-raised-button type="button"
                                                (click)="validateAddress(address.id)">
                                            Validate
                                        </button>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="address.address2" class="item clear">
                            <span class="label">Address 2: </span>
                            <div>{{ address.address2 }}</div>
                        </div>
                        <div *ngIf="address.address3" class="item">
                            <span class="label">Address 3: </span>
                            <div>{{ address.address3 }}</div>
                        </div>
                        <div *ngIf="address.customer" class="item">
                            <span class="label">Customer: </span>
                            <div>{{ address.customer.name }}</div>
                        </div>

                        <div class="item contacts"
                             *ngIf="address.contacts && address.contacts.length > 0">
                            <span class="label">{{ address.address_type === 'main' ? 'Main contacts' : 'Contacts' }}
                                :</span>
                            <div *ngFor="let contact of address.contacts || []; let contIndex = index">
                            <span *ngIf="contact.last_name">
                                                    {{ contact.last_name }},
                                                </span>
                                <span *ngIf="contact.first_name">
                                                    {{ contact.first_name }},
                                                </span>
                                <div *ngIf="contact.phone">
                                    <i>tel.:</i> {{ contact.phone }},
                                </div>
                                <div *ngIf="contact.mobile_phone">
                                    <i>mob.:</i> {{ contact.mobile_phone }},
                                </div>
                                <div *ngIf="contact.email">
                                    <i>email:</i> {{ contact.email }}
                                </div>
                            </div>
                        </div>

                        <div class="item padding-top-20">
                            <mat-form-field style="width: 300px">
                                <mat-label>Package list remark</mat-label>
                                <textarea matInput formControlName="pl_remark" rows="3"></textarea>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="map" *ngIf="address.lat && address.lng">
                        <common-static-map
                                [center]="{lat: number(address.lat), lng: number(address.lng)}"
                                [markers]="[{lat: number(address.lat), lng: number(address.lng)}]"
                                [zoom]="12"
                                width="400"
                                height="300"
                        ></common-static-map>
                    </div>

                </mat-card>
            </div>

            <div>
                <p *ngIf="warehouseOrder?.confirmed" class="color-warn">
                    Document release complete.
                </p>
                <button mat-raised-button color="accent" (click)="releaseDocs()">
                    {{ warehouseOrder?.confirmed ? 'Resend' : 'Release' }} documents
                </button>
                &nbsp;
                &nbsp;
                &nbsp;
                <mat-checkbox formControlName="sendTo">
                    Inform end customer
                </mat-checkbox>
            </div>
        </div>
    </mat-card>

    <mat-card class="anpa-edi-log margin-left-20 max padding-10 flex col"
              *ngIf="warehouse && order && order.partner.id === 14 && order.custom_fields['ANA_AWS_BP_ORD'] === 'Y'">
        <div class="max">
            <common-table2 #amznTableRef *ngIf="amznBookingTableSettings" [settings]="amznBookingTableSettings"
                           [search]="false">
                <div row0 class="max flex row space-between align-center">
                    <h2>AwS Booking log</h2>
                    <button mat-mini-fab (click)="amznTableRef.reload()" color="warn" matTooltip="Refresh">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </common-table2>
        </div>

        <div class="padding-top-20">
            <button mat-raised-button color="accent" (click)="amznBookingSend('update')">
                Send update
            </button>
            &nbsp;
            <button mat-raised-button color="warn" (click)="amznBookingSend('cancel')">
                Send cancellation
            </button>
        </div>
    </mat-card>
</div>